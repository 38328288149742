import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import maskPlus from "../../image/Mask group.svg";

import "./assets.css";
import AssignedAsset from "./AssignedAsset";
import AssetCategory from "./AssetCategory";
import AddAssetCategoryModal from "./AddAssetCategory";

function AssetManagement() {
  const history = useNavigate();
  const [value, setValue] = useState<string>("1");
  const [addOpen, setAddOpen] = useState<boolean>(false)

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/assigned-asset") {
      setValue("1");
    } else if (location.pathname === "/asset-category") {
      setValue("2");
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "1") {
      history("/assigned-asset");
    } else if (newValue === "2") {
      history("/asset-category");
    }
  };

  const getIndicatorClass = () => {
    switch (localStorage.getItem("tabValue") || "1") {
      case "1":
        return "indicator-assigned-asset";
      case "2":
        return "indicator-asset-category";
      default:
        return "indicator-default";
    }
  };

  const handleSave = () => {

  }

  return (
    <>
      <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    TabIndicatorProps={{ className: getIndicatorClass() }}
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .MuiTab-root": {
                        color: "#89888E",
                        fontFamily: "poppins",
                      },
                      "& .Mui-selected": {
                        color: "#000000 !important",
                        fontFamily: "poppins",
                      },
                    }}
                  >
                    <Tab
                      className="text-[#89888E]"
                      label="Assigned Asset"
                      value="1"
                    />
                    <Tab label="Asset Category" value="2" />
                  </TabList>
                </Box>

                {value === "1" ? (
                  <div>
                    <button
                      onClick={() => setAddOpen(true)}
                      className="flex items-center font-[Poppins] font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Assigned Asset
                    </button>
                  </div>
                ) : value === "2" ? (
                  <div>
                    <button
                      onClick={() => setAddOpen(true)}
                      className="flex items-center font-[600] font-[Poppins] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Asset Category
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <TabPanel className="tabpanel" value="1">
                <AssignedAsset />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <AssetCategory />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        {addOpen ? <AddAssetCategoryModal onCancel={() => setAddOpen(false)} onSave={handleSave} />: <></>}
      </div>
    </>
  );
}

export default AssetManagement;
