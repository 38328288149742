import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDSRDetails } from "../common/services/EmployeeDSR";
import Receipt from "../ExpenseManagement/ImageView";
import ModalViewer from "../ExpenseManagement/ModalViewer";

interface DSRData {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  employee_id: string;
  project_id: string;
  project_name: string;
  task_id: string;
  task_name: string;
  task_document: string[];
  date: string;
  duration: string;
  status: string;
  notes: string;
}

export const EmployeeDSRDetails = () => {
  const navigate = useNavigate();
  const [DSRData, setDSRData] = useState<DSRData>({
    firstName: "",
    middleName: "",
    lastName: "",
    employee_id: "",
    profile_image: "",
    project_id: "",
    project_name: "",
    task_id: "",
    task_name: "",
    task_document: [],
    date: "",
    duration: "",
    status: "",
    notes: "",
  });
  const location = useLocation();
  const id = location.state?.id;

  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };
  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  useEffect(() => {
    fetchDSRDetails(id)
      .then((data) => {
        if (data.result) {
          setDSRData({
            firstName: data.result.User.firstName,
            middleName: data.result.User.middleName,
            lastName: data.result.User.lastName,
            employee_id: data.result.User.employee_id,
            profile_image: data.result.User.profile_image,
            project_id: data.result.Project.id,
            project_name: data.result.Project.project_name,
            task_id: data.result.Task.id,
            task_name: data.result.Task.task_name,
            task_document: data.result.Task.task_document,
            date: data.result.date,
            duration: data.result.duration,
            status: data.result.status,
            notes: data.result?.notes,
          });
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="px-4 pt-6 pb-12 bg-[#FBFBFB]">
      <div className="flex flex-col gap-5">
        <div className="flex flex-row gap-[13px] items-center">
          <button onClick={() => navigate("/employee-dsr")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Employee DSR Details
          </span>
        </div>
        <div
          className="flex flex-col gap-5 p-5 rounded-lg bg-white"
          style={{ boxShadow: "0px 2px 15px 0px rgba(72, 71, 71, 0.1)" }}
        >
          <div className="h-6">
            <span className="font-[Inter] font-semibold text-[20x] leading-[24.2px] text-black">
              Details
            </span>
          </div>
          <div
            className="flex flex-col gap-[15px] px-2.5 py-5 rounded-md bg-gradient-to-r"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
            }}
          >
            <div className="grid grid-cols-3 gap-x-6">
              <div className="flex flex-col gap-[7px]">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Employee ID
                </span>
                <div className="flex flex-row gap-[7px]">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={DSRData.profile_image || profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {DSRData.firstName} {DSRData.middleName}{" "}
                      {DSRData.lastName}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {DSRData.employee_id}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Project ID
                </span>
                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                  {DSRData.project_id}
                </span>
              </div>
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Task ID
                </span>
                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                  {DSRData.task_id}
                </span>
              </div>
            </div>
            <hr className="px-2.5 h-[2px]" />
            <div className="grid grid-cols-3 gap-x-6">
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Project Name
                </span>
                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                  {DSRData.project_name}
                </span>
              </div>
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Task Name
                </span>
                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                  {DSRData.task_name}
                </span>
              </div>
            </div>
            <hr className="px-2.5 h-[2px]" />
            <div className="grid grid-cols-3 gap-x-6">
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Date
                </span>
                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                  {DSRData.date}
                </span>
              </div>
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Efforts
                </span>
                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                  {DSRData.duration} Hrs.
                </span>
              </div>
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Status
                </span>
                <div className="flex flex-row justify-center items-center w-[88px] h-[26px] rounded-sm bg-[#D4F8C7]">
                  <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                    {DSRData.status}
                  </span>
                </div>
              </div>
            </div>
            <hr className="px-2.5 h-[2px]" />
            <div className="grid grid-cols-1 gap-x-6">
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  DSR
                </span>
                <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                  {DSRData.notes}
                </span>
              </div>
            </div>
            <hr className="px-2.5 h-[2px]" />
            <div className="grid grid-cols-1 gap-x-6">
              <div className="flex flex-col gap-[7px] h-full">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Attachment
                </span>
                <div className="grid grid-cols-2">
                  {DSRData?.task_document?.map((task, index) => (
                    <Receipt
                      key={index}
                      fileName={
                        task?.split("/")?.pop() || `Receipt ${index + 1}`
                      }
                      fileSize={`${(Math?.random() * 2 + 1)?.toFixed(2)} MB`}
                      onView={() => handleView(task)}
                    />
                  ))}
                </div>
              </div>
              {selectedFileUrl ? (
                <ModalViewer
                  isOpen={!!selectedFileUrl}
                  onRequestClose={closeModal}
                  fileUrl={selectedFileUrl}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
