import React, { useState, useCallback, ChangeEvent } from "react";

import { debounce } from "lodash";

import edit from "../../assets/Crud_Icons/edit.svg";
import del from "../../assets/Crud_Icons/delete.svg";

import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import EditAssetCategoryModal from "./EditAssetCategory";
import DeleteConfirmationModal from "./DeleteAssetCategory";

function AssetCategory() {
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const details = {
    total: "50",
    category: "Laptop",
    id: "12",
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      // setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const handleUpdate = () => {};

  const handleDelete = () => {}

  return (
    <div className="pt-12">
      <div className="flex flex-col gap-5">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with Announcement"
            />
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-auto shadow-sm">
          <table className="w-full min-w-max text-left table-auto bg-white">
            <thead className="rounded-t-[3px] border border-[#E0E0E0]">
              <tr className="h-11 w-full">
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-8 w-1/3">
                  Assets Category
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] text-center py-4 w-1/3">
                  Total Assets
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-8 flex justify-end">
                  Action
                </th>
              </tr>
            </thead>
            {/* {tableData?.map((item) => ( */}
            <tbody className="border border-[#EBEBEB]">
              <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-8">
                  Laptop
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                  50
                </td>
                <td className="pr-5">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setEditOpen(true)}
                      src={edit}
                      alt="Edit"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setDeleteOpen(true)}
                      src={del}
                      alt="Delete"
                    />
                  </div>
                </td>
              </tr>
              <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-8">
                  Laptop
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                  50
                </td>
                <td className="pr-5">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setEditOpen(true)}
                      src={edit}
                      alt="Edit"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setDeleteOpen(true)}
                      src={del}
                      alt="Delete"
                    />
                  </div>
                </td>
              </tr>
              <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-8">
                  Laptop
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                  50
                </td>
                <td className="pr-5">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setEditOpen(true)}
                      src={edit}
                      alt="Edit"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setDeleteOpen(true)}
                      src={del}
                      alt="Delete"
                    />
                  </div>
                </td>
              </tr>
              <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-8">
                  Laptop
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] text-center">
                  50
                </td>
                <td className="pr-5">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setEditOpen(true)}
                      src={edit}
                      alt="Edit"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      onClick={() => setDeleteOpen(true)}
                      src={del}
                      alt="Delete"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
            {/* ))} */}
          </table>
          {/* {tableData.length === 0 ? (
            <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
              Data not Found!
            </div>
          ) : (
            <></>
          )} */}
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
      {editOpen ? (
        <EditAssetCategoryModal
          onCancel={() => setEditOpen(false)}
          onUpdate={handleUpdate}
          assetCategoryDetails={details}
        />
      ) : (
        <></>
      )}
      {deleteOpen ? <DeleteConfirmationModal onCancelDelete={() => setDeleteOpen(false)} onDelete={handleDelete}/> : <></>}
    </div>
  );
}

export default AssetCategory;
