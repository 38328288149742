import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import moment from "moment";

import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";

import {
  approveRejectApi,
  fetchEditExitEmployee,
} from "../common/services/ExitEmployee";

// !MODAL STYLES
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

// !INTERFACES
interface Approvers {
  approval_id: number;
  approval_status: string;
  role: string;
}

interface EmployeeData {
  empCode: string;
  empId: string;
  empName: string;
  designation: string;
  DOJ: string;
  DOB: string;
  LWD: string;
  reason: string;
  status: string;
  reporting_manager_status: string;
  hr_status: string;
  team_manager_status: string;
}

const ViewExitProcedure = () => {
  // !REDUX DATA
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const userId = loginData?.userId;
  const customer_id = loginData?.customer_id;

  // !VARIABLES
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [status, setStatus] = useState<string>("Pending");
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    empCode: "",
    empId: "",
    empName: "",
    designation: "",
    DOJ: "",
    DOB: "",
    LWD: "",
    reason: "",
    status: "",
    reporting_manager_status: "",
    hr_status: "",
    team_manager_status: "",
  });

  // !FUNCTIONS
  const handleClose = () => {
    setOpen(false);
    navigate("/exit-procedure");
  };

  const handleApprove = () => {
    approveData();
  };
  const handleReject = () => {
    rejectData();
  };

  // !API CALLS
  useEffect(() => {
    console.log("edit check");
    fetchEditExitEmployee(id)
      .then((data) => {
        if (data.result) {
          setEmployeeData({
            empCode: data.result?.User?.employee_code,
            empId: data.result?.User?.employee_id,
            empName: data.result?.User?.firstName,
            designation: data.result?.User?.Designation?.name,
            DOJ: data.result?.User?.date_of_joining || null,
            DOB: data.result?.User?.date_of_exit_initiation,
            LWD: data.result?.User?.date_of_leaving,
            reason: data.result?.reason,
            status: data.result?.status,
            reporting_manager_status: data.result?.reporting_manager_status,
            hr_status: data.result?.hr_status,
            team_manager_status: data.result?.team_manager_status,
          });
          setApproverData(data.result?.UserExitRequestApprovals);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const approveData = async () => {
    const status = "Approved";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response.data?.message);
      setTimeout(() => {
        navigate("/exit-procedure");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const rejectData = async () => {
    const status = "Rejected";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response.data?.message);
      setTimeout(() => {
        navigate("/exit-procedure");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  // !APPROVERS
  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.approval_id === userId) {
        setShowButtons(true);
        setStatus(approver.approval_status);
      }
    });
  }, [approverData]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {status === "approved"
              ? "Your Request has been Approved"
              : "Your Request has been Rejected"}
          </Typography>
        </Box>
      </Modal>
      <div className="bg-[#FBFBFB] pt-6 pb-8 px-4">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/exit-procedure")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
              Exit Details
            </span>
          </div>
          {showButtons ? (
            <div className="flex flex-start gap-5">
              {status !== "Rejected" ? (
                <button
                  className="flex w-[148px] p-[13.18px,50px,14.82px,51px] bg-[#B3261E] justify-center items-center self-stretch rounded-lg border border-solid h-12 hover:bg-[#96150e]"
                  onClick={handleReject}
                >
                  <div className="text-[#FFF] text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
                    Reject
                  </div>
                </button>
              ) : (
                <></>
              )}
              {status !== "Approved" ? (
                <button
                  className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg bg-[#359349] text-white h-12 hover:bg-[#1f8735]"
                  onClick={handleApprove}
                >
                  <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Approve
                  </div>
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-semibold mb-4">Details</h2>
          <div
            className="bg-gradient-to-r p-4 rounded-md"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
            }}
          >
            <div className="grid grid-cols-3 p-1 gap-4 border-b-[2px] border-gray-200">
              <div className="flex flex-col gap-[7px]">
                <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                  Employee Name & ID
                </span>
                <div className="flex flex-row gap-[7px]">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {employeeData?.empName || "--"}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {employeeData?.empId || "--"}
                    </span>
                  </div>
                </div>
              </div>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Employee Department
                </strong>
                <br />
                <span className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {employeeData?.empName || "--"}
                </span>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Designation
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {/* Appvin , Noida */}
                  {employeeData.designation || "--"}
                </strong>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b-2 border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Date Of Joining
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.DOJ
                    ? moment(employeeData.DOJ).format("YYYY-MM-DD")
                    : "--"}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Date Of Exit Iniation
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {employeeData.DOB
                    ? moment(employeeData.DOB).format("YYYY-MM-DD")
                    : "--"}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Last Working Day
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.LWD
                    ? moment(employeeData.LWD).format("YYYY-MM-DD")
                    : "--"}
                </strong>
              </p>
            </div>
            <div className="grid grid-cols-1 p-2 gap-4 border-b-2 border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Reason
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.reason || "--"}
                </strong>
              </p>
            </div>

            <div className="grid col-span-3 p-2 gap-4">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Approval Status
                </strong>
                <br />{" "}
                <div className="grid grid-cols-3">
                  {approverData?.map((approver) => (
                    <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                      {approver.role} -{" "}
                      <span
                        className={`p-1 rounded-sm ${
                          approver.approval_status === "Approved"
                            ? "bg-[#D4F8C7]"
                            : approver.approval_status === "Pending"
                            ? "bg-[#FFC0767A]"
                            : approver.approval_status === "Rejected"
                            ? "bg-[#F8716A7A]"
                            : ""
                        }`}
                      >
                        {approver.approval_status}
                      </span>
                    </span>
                  ))}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewExitProcedure;
