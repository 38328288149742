import { api_key_work as api_key } from "../../../utils/helperData";
import { GET_CUSTOMER_DETAILS, GET_CUSTOMER_MANAGEMENT_LIST } from "../api";
import moment from "moment";

export const Customer_Management_List_Api = (
  customer_id: number,
  numOfItems: number,
  page: number,
  sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?page=${page}&size=${numOfItems}&customer_id=${customer_id}`;
  if (sortType !== undefined) {
    query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    query += `&start_date=${start}&end_date=${end}`;
  }

  return fetch(`${GET_CUSTOMER_MANAGEMENT_LIST}${query}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Customer_Details_Api = (id: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${GET_CUSTOMER_DETAILS}/${id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Add_Customer = (customerDetails: {}) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${GET_CUSTOMER_DETAILS}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": api_key,
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(customerDetails),
  });
};

export const Send_Updated_Customer_Data = (
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  country_code: string,
  customer_name: string,
  status: string,
  resource_list: { id: number; type: string }[],
  user_id: number,
  customer_id: number
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${GET_CUSTOMER_DETAILS}/${customer_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": api_key,
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      phone,
      firstName,
      lastName,
      country_code,
      resource_list,
      address,
      status,
      customer_name,
      user_id,
    }),
  });
};
