import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_HOLIDAY_LIST } from "../api";
import { Dayjs } from "dayjs";
import moment from 'moment'

export const Fetch_Holiday_List = (
  numOfItems: number,
  page: number,
  // group_id:number,
  // department_id:number,
  //sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(`${FETCH_HOLIDAY_LIST}${query}&session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createHoliday = async (
  //customerId: number,
  description: string | null,
  holiday_name: string,
  holiday_date: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    //customerId: customerId,
    // description: description,
    holiday_name: holiday_name,
    holiday_date: holiday_date,
  };

  if(description !== "") data.description = description

  try {
    const response = await axios.post(`${base_api}/api/v1/holiday`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditHoliday = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/holiday/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateHoliday = async (
  id: string,
  holiday_name: string | null,
  description: string,
  holiday_date: string | null | Dayjs,
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    holiday_name: holiday_name,
    description: description,
    holiday_date: holiday_date,
  };

  try {
    const response = await axios.put(`${base_api}/api/v1/holiday/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
