import React, { useState } from "react";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import check from "../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";
import toast from "react-hot-toast";
import { createWFH } from "../common/services/WFH";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormSelectValues {
  leave_period_type: string;
  leave_session: string;
  reason: string;
}

const AddWFH = () => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [dateAll, setDateAll] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );
  const handleOpen = () => {
    !isStart && setIsStartDisplay(true);
    !isEnd && setIsEndDisplay(true);
    validate();
    postData();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/work-from-home");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/work-from-home");
  };

  const [errors, setErrors] = useState<Partial<FormSelectValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormSelectValues> = {};
    if (!formValues.reason.trim()) tempErrors.reason = "Reason is required";
    if (formValues.leave_session === "")
      tempErrors.leave_session = "WFH Period is required";
    if (formValues.leave_period_type === "")
      tempErrors.leave_period_type = "Leave Period Type is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const [formValues, setFormValues] = useState<FormSelectValues>({
    leave_period_type: "",
    leave_session: "",
    reason: "",
  });

  const LeavePeriodType = [
    { value: "1", label: "Full Day" },
    { value: "0.5", label: "Half Day" },
  ];

  const LeaveSessionOptions = [
    { value: "First Half", label: "First Half" },
    { value: "Second Half", label: "Second Half" },
  ];

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    if(value == "1") setFormValues({
      ...formValues,
      [name]: value,
      leave_session: "Full Day"
    })
  };

  const [isStart, setIsStart] = useState<boolean | null>(false);
  const [isStartDisplay, setIsStartDisplay] = useState<boolean | null>(false);
  const [isEnd, setIsEnd] = useState<boolean | null>(false);
  const [isEndDisplay, setIsEndDisplay] = useState<boolean | null>(false);

  const postData = async () => {
    setLoading(true);
    try {
      const formatDate = (date: any) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        return dayjs(date).isValid() ? formattedDate : null;
      };

      const response = await createWFH(
        Number(formValues.leave_period_type),
        formValues.reason,
        formatDate(endDate) || formatDate(dateAll),
        formatDate(startDate) || formatDate(dateAll),
        selectedEmployeeId,
        formValues.leave_session
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/work-from-home");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };

  console.log(selectedEmployeeId, "selectedEmployeeId wfh--->>>");
  console.log(
    dayjs(startDate)?.format("YYYY-MM-DD"),
    "start",
    dayjs(endDate)?.format("YYYY-MM-DD"),
    "end"
  );

  return (
    <div className="px-4 py-6 min-h-[90vh] bg-[#F9F9F9]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Work From Home leave added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/work-from-home")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Employee Work From Home
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="rounded-md overflow-hidden shadow-custom bg-white mt-10">
        <div className="container mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                select
                label="WFH Period"
                name="leave_period_type"
                defaultValue="Hello World"
                value={formValues.leave_period_type}
                onChange={handleFrormChange}
                error={!!errors.leave_period_type}
                helperText={errors.leave_period_type}
              >
                {LeavePeriodType?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {formValues.leave_period_type === "0.5" && (
                <TextField
                  required
                  id="outlined-required"
                  select
                  label="Leave Session"
                  name="leave_session"
                  value={formValues.leave_session}
                  onChange={handleFrormChange}
                  error={!!errors.leave_session}
                  helperText={errors.leave_session}
                >
                  {LeaveSessionOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <EmployeeSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
              />
              {formValues.leave_period_type === "0.5" ? (
                <div className="flex flex-col items-start w-full pr-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={dateAll}
                      onChange={(newValue) => {
                        setDateAll(newValue);
                        // setIsStart(true);
                        // setIsStartDisplay(false);
                      }}
                      className="w-full"
                      minDate={dayjs()}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: isStartDisplay ? "#d32f2f" : "",
                          },
                          "&:hover fieldset": {
                            borderColor: isStartDisplay ? "#d32f2f" : "",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: isStartDisplay ? "#d32f2f" : "",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: isStartDisplay ? "#d32f2f" : "",
                        },
                        "& .MuiSvgIcon-root": {
                          color: isStartDisplay ? "#d32f2f" : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {/* {isStartDisplay && (
                    <span className="px-4 text-#d32f2f-600 text-xs font-medium">
                      Date is requi#d32f2f
                    </span>
                  )} */}
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          setIsStart(true);
                          setIsStartDisplay(false);
                        }}
                        className="w-full"
                        minDate={dayjs()}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isStartDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isStartDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isStartDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isStartDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isStartDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {isStartDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        Start Date is required
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                          setIsEnd(true);
                          setIsEndDisplay(false);
                        }}
                        className="w-full"
                        minDate={dayjs()}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isEndDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isEndDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isEndDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isEndDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isEndDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {isEndDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        End Date is required
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="grid grid-cols-1 pt-4">
              <TextField
                required
                id="outlined-required"
                label="Reason"
                multiline
                rows="3"
                defaultValue=" "
                name="reason"
                value={formValues.reason}
                onChange={handleFrormChange}
                error={!!errors.reason}
                helperText={errors.reason}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddWFH;
