import React from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import right from "../../image/chevron-right.png";
import FileUpload from "../ExpenseManagement/FileUpload";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

function AddAsset() {
  const navigate = useNavigate();

  const handleFilesChange = (files: UploadedFile[]) => {
    // setUploadedFiles(files);
    // console.log("Files updated:", files);
    // if (files.length !== 0) {
    //   setErrorDisplay(false);
    // }
  };

  const handleEmployeeSelect = (employeeId: string) => {};

  const handleEmployeeSelectId = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-5">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/asset-management")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Add Asset Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/asset-management")}
              className="flex w-[148px] rounded-lg p-[13.18px_50px_14.82px_51px] justify-center items-center  borderButton h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
              // onClick={handleClick}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Save
              </div>
            </button>
          </div>
        </div>
        <div className="rounded-lg shadow-custom p-4 bg-white">
          <div className="flex flex-col gap-8">
            <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626]">
              Assigned Assset Details
            </span>
            <Box component="form" noValidate autoComplete="off">
              <div className="grid grid-cols-3 gap-8">
                <TextField
                  required
                  id="outlined-required"
                  label="Asset Id"
                  name="asset_id"
                  //   value={addData.title}
                  //   onChange={handleFormChange}
                />
                <EmployeeSearch
                  onEmployeeSelect={handleEmployeeSelect}
                  onEmployeeSelectId={handleEmployeeSelectId}
                />
                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Asset Category"
                  name="category"
                  // value={addData.status}
                  // onChange={handleFormChange}
                >
                  {/* <MenuItem ></MenuItem> */}
                </TextField>
                <TextField
                  required
                  id="outlined-required"
                  label="Asset Name"
                  name="asset_name"
                  //   value={addData.title}
                  //   onChange={handleFormChange}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Serial Number"
                  name="serial_no"
                  //   value={addData.title}
                  //   onChange={handleFormChange}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Assigned Date"
                    //   value={date}
                    minDate={dayjs()}
                    //   onChange={(newValue) => setDate(newValue)}
                  />
                </LocalizationProvider>
                <div className="col-span-3 relative">
                  <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626] absolute px-2">
                    Attachment
                  </span>
                  <FileUpload onFilesChange={handleFilesChange} />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAsset;
