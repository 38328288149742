import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface LeaveData {
  count: number;
  LeaveData: any[];
  LeaveTypeData: any[];
  currentPage: number;
  itemsPerPage: number;

}

const initialState: LeaveData = {
  count: 0,
  LeaveData: [],
  LeaveTypeData: [],
  currentPage: 1,
  itemsPerPage: 10,
};

const LeaveListSlice = createSlice({
  name: "LeaveData",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setLeaveData(state, action: PayloadAction<any>) {
      state.LeaveData = action.payload;
      state.count = action.payload.count;
      console.log(action.payload,"message ------>>>>");
    },
    setLeaveTypeData(state, action: PayloadAction<any>) {
      state.LeaveTypeData = action.payload;
      state.count = action.payload.count;
      console.log(action.payload,"message leave-type ------>>>>");
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload;
    },
    setItemsPerPage(state, action: PayloadAction<any>) {
      state.itemsPerPage = action.payload;
    },
  },
});

export const LeaveListActions = LeaveListSlice.actions;
export default LeaveListSlice;
