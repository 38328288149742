import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Suspense,
} from "react";
import { debounce } from "lodash";
import Footer from "../Layout/footer";
import Header from "../Layout/header";
import rightArrow from "../../assets/chevron-right.svg";
import addNew from "../../assets/addNew.png";
import search from "../../assets/Search.svg";
import { useLocation, useNavigate } from "react-router-dom";
import left from "../../image/icon16arrow-left.svg";
import right from "../../image/icon16arrow-right.svg";
import arr from "../../image/icon16chevron-down.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchRoleManagementList } from "../../redux/actions/RoleManagement/RoleManagementActions";
import TablePaginationDemo from "../common/Pagenation";
import { RoleManagementActions } from "../../redux/reducers/roleManagement/roleManagementSlice";
import { Fetch_Role_Management_List } from "../common/services/roleManagement";
import { start } from "repl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import ascending from "../../assets/ascending.svg";
import descending from "../../assets/descending.svg";
import maskPlus from "../../image/Mask group.svg";
import filter from "../../assets/filter.svg";
import edit_new from "../../assets/images/edit_new.png";
import edit from "../../assets/Crud_Icons/edit.svg";
import upload from "../../assets/plus 1.png";
import date from "../../assets/date.svg";
import {
  ROLE_MANAGEMENT_ADD_ROLE,
  ROLE_MANAGEMENT_EDIT_ROLE,
  ROLE_MANAGEMENT_EDIT_ROLE_DETAILS,
  ROLE_MANAGEMENT_ROLE_DETAILS,
} from "../../utils/routeConstant";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";
import moment from "moment";
import "./roleManagement.css";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  gap: "8px",
});

const Button = styled("button")(({ selected }: { selected: boolean }) => ({
  padding: "6px 8px",
  borderRadius: "8px", // Adds border radius
  border: selected ? "2px solid #3f51b5" : "1px solid #ccc", // Adds border
  fontWeight: selected ? "bold" : "normal",
  backgroundColor: selected ? "#e3f2fd" : "#f1f1f1", // Optional: background color for selected item
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f1f1f1", // Optional: hover effect
  },
}));

const IconButton = styled(Button)({
  padding: "8px", // Adjust padding to fit the icon
});

const Sidebar = React.lazy(() => import("../Layout/Sidebar"));
const crmType = "Role";
const addType = "Role";
const tableHeadings = [
  { id: "role_name", label: "Role Name", sort: true, width: "w-[28%]" },
  { id: "created_by", label: "Created By", sort: true, width: "w-[17%]" },
  { id: "total_user", label: "No. of Users", sort: false, width: "w-[10%]" },
  { id: "createdAt", label: "Added Date", sort: true, width: "w-[18%] ml-6" },
  { id: "updatedAt", label: "Last Updated", sort: true, width: "w-[18%]" },
  { id: "action", label: "Action", sort: false, width: "w-[9%]" },
];

const RoleManagement = () => {
  interface createdByUser {
    firstName: string;
    lastName: string;
  }

  interface RowData {
    role_name: string;
    created_by: string;
    total_user: number;
    createdAt: string;
    updatedAt: string;
    status: string;
    id: number;
    createdByUser: createdByUser;
  }

  const { items } = usePagination({
    count: 10,
  });
  const [tableData, setTableData] = useState<RowData[]>([]);
  console.log("table data", tableData);
  const [role_name, setRoleName] = useState<string>("");
  const [created_by, setCreatedBy] = useState<string>("");
  const [total_user, setTotalUser] = useState<number>(0);
  const [createdAt, setCreatedAt] = useState<string>("");
  const [updatedAt, setUpdatedAt] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [id, setId] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState(false);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const loginData = useSelector((state: any) => state.auth.login.login_details);

  /////////////pagination////////////////

  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);

  console.log("count is", count);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  ///////////////////sorting/////////////////////
  // const [sortType, setSortType] = useState<any>();
  const [sortType, setSortType] = useState<any>({
    key: "createdAt",
    order: "DESC",
  });
  let itemId = "";
  function handleSortType(item: any) {
    setLoading(true);
    itemId = item.id;
    if (item.sort) {
      if (sortType && sortType.key === item.id) {
        const newOrder = sortType.order === "ASC" ? "DESC" : "ASC";
        setSortType({ key: item.id, order: newOrder });
      } else {
        setSortType({ key: item.id, order: "DESC" });
      }
    }
    setLoading(false);
  }

  ////////////////////////////////////

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }
  console.log("access_token: ", access_token);
  console.log("customer_id:", customer_id);

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  console.log("sortType sort by:", sortType.key);

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Role_Management_List(
        customer_id,
        numOfItems,
        page,
        sortType,
        searchTerm,
        startDate,
        endDate
      );
      if (response.status === 200) {
        console.log("Data:", response.data);
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    console.log("after fetch page", page);
  }, [
    searchTerm,
    role_name,
    created_by,
    total_user,
    createdAt,
    updatedAt,
    status,
    id,
    page,
    sortType,
    startDate,
    endDate,
    numOfItems,
  ]);

  console.log("outside fetch page", page);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.refresh) {
      fetchData();
    }
  }, [location]);

  //////////////////////////////searching///////////////////////////

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  const filteredTableData = useMemo(() => {
    if (!tableData) {
      return [];
    }

    return tableData.filter((row: any) =>
      row.role_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [tableData, searchTerm]);

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  return (
    <div className="flex flex-col h-[calc(100% - 100px)] px-4 py-6 bg-[#F9F9F9]">
      <div className="flex-grow">
        {/* Your main content goes here */}
        <div className="h-full">
          <div className="">
            <div className="flex flex-row items-center h-12 justify-between">
              <div className="flex flex-row gap-[10px] justify-center items-center">
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  {crmType}
                </span>
              </div>

              {/* <button
                onClick={() => navigate(`/add-role`)}
                className="flex w-[166px] py-[11px] px-[15px] justify-center items-center gap-2.5 rounded-lg buttoncss h-12"
              >
                <div className="flex justify-center items-center gap-[7px]">
                  <div className="flex justify-center items-center relative">
                  <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
                  </div>
                  <span className="text-white font-[Poppins] text-sm font-medium leading-[29px]">
                    Add {addType}
                  </span>
                </div>
              </button> */}

              <div>
                <button
                  onClick={() => navigate(`/add-role`)}
                  className="flex items-center font-['Poppins'] py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src={maskPlus}
                    alt="plus icon"
                  />
                  Add Role
                </button>
              </div>
            </div>

            <div className="w-full mt-[17px]">
              <div className="flex flex-col items-flex-end gap-[17px] self-stretch rounded-md ">
                <div className="h-[40px] w-full">
                  <div className="h-full flex justify-between items-center w-full">
                    <Search
                      onChange={updateSearchTerm}
                      label="Search with Role Name"
                    />
                    <div className="h-full flex items-center gap-5">
                      <div className="h-full flex items-center gap-2">
                        {dateRange && (
                          <Chip
                            label={`${moment(selectedDates[0]).format(
                              "YYYY-MM-DD"
                            )} - ${moment(selectedDates[1]).format(
                              "YYYY-MM-DD"
                            )}`}
                            onDelete={handleDeleteDate}
                            size="small"
                          />
                        )}
                      </div>
                      <button
                        className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                        onClick={() => setIsOpen(true)}
                      >
                        <img
                          src={filter}
                          alt="filter"
                          className="h-3.5 w-3.5"
                        />
                        <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                          Filter
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row mb-4 justify-end gap-[31px]">
                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#00CBA0"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Active
                    </span>
                  </div>

                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#FF9950"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Inactive
                    </span>
                  </div>

                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#FA1464"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Archived
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-0 py-0 mt-0 w-full h-[19rem] mb-4 table_main_content">
                <table className="mt-0 font-[Poppins] border-[0.6px] bg-white border-[#E0E3E7] w-full min-w-max text-left table-auto">
                  <thead className="sticky top-0">
                    <tr className="flex flex-row w-full items-center pl-3 pr-2.5 py-2.5 rounded-[3px] gap-8 h-11">
                      {tableHeadings.map((item) => {
                        const cssClass =
                          "flex cursor-pointer transition-colors items-center " +
                          item.width;
                        return (
                          <th
                            className={cssClass}
                            onClick={() => handleSortType(item)}
                          >
                            <span className="shrink-0  text-[14px] text-[#1D1A22] font-[Poppins] font-medium leading-[11.185px] tracking-[0.72px] capitialize">
                              {item.label}
                            </span>
                            {item.sort &&
                              (sortType.key === item.id &&
                              sortType.order === "DESC" ? (
                                <img
                                  src={descending}
                                  alt="Descending order symbol"
                                  className="w-[13px] h-[13px]"
                                />
                              ) : (
                                <img
                                  src={ascending}
                                  alt="Ascending order symbol"
                                  className="w-[13px] h-[13px]"
                                />
                              ))}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="">
                    {loading && filteredTableData.length === 0 && (
                      <tr className="w-full flex px-3.5 py-1 justify-center items-center border-[0.6px] border-solid border-[#E0E3E7] h-8 hover:cursor-pointer">
                        <td className="w-full flex flex-row justify-center items-center">
                          {/* <img src={loader} alt="Loader" className="h-8 w-8 justify-center items-center"/> */}
                          <svg
                            aria-hidden="true"
                            className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </td>
                      </tr>
                    )}
                    {!loading && filteredTableData.length === 0 && (
                      <tr className="w-full flex px-2.5 py-1 justify-center items-center border-[0.6px] border-solid border-[#E0E3E7] h-8 hover:cursor-pointer">
                        <td className="w-full flex flex-row justify-center items-center">
                          Data Not Found!
                        </td>
                      </tr>
                    )}
                    {filteredTableData.map((item) => {
                      const fill =
                        item.status === "active"
                          ? "#00CBA0"
                          : item.status === "inactive"
                          ? "#FF9950"
                          : "#FA1464";
                      return (
                        <tr className="w-full flex px-2.5 py-1 items-center gap-8 border-[0.6px] border-solid border-[#E0E3E7] h-10 hover:bg-gray-100">
                          <td
                            onClick={() =>
                              navigate(`${ROLE_MANAGEMENT_EDIT_ROLE_DETAILS}`, {
                                state: { id: item.id },
                              })
                            }
                            className="w-[24%] cursor-pointer"
                          >
                            <div className="flex flex-row text-[#1D1A22] text-[14px] font-[500] items-center gap-1 shrink-0 ]">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                              >
                                <circle
                                  cx="5.5"
                                  cy="5.35156"
                                  r="5"
                                  fill={fill}
                                />
                              </svg>
                              <span className="text-[#1D1A22] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium">
                                {item.role_name}
                              </span>
                            </div>
                          </td>
                          <td className="w-[17%]">
                            <div className="flex items-center">
                              <div className="flex flex-row items-center gap-1 shrink-0">
                                <p className=" text-[#1D1A22] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium">
                                  {item.createdByUser
                                    ? item.createdByUser.firstName
                                    : ""}{" "}
                                  {item.createdByUser
                                    ? item.createdByUser.lastName
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="w-[10%]">
                            <div className="flex items-center">
                              <button
                                onClick={() =>
                                  navigate(`${ROLE_MANAGEMENT_ROLE_DETAILS}`, {
                                    state: { id: item.id },
                                  })
                                }
                                className="flex flex-row items-center gap-1 shrink-0"
                              >
                                <p className="underline ml-6 text-[#1D1A22] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium">
                                  {item.total_user}
                                </p>
                              </button>
                            </div>
                          </td>
                          <td className="w-[16%]">
                            <div className="flex items-center">
                              <div className="flex flex-row items-center gap-1 shrink-0">
                                <p className="text-[#1D1A22] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium">
                                  {
                                    new Date(item.createdAt)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="w-[15%]">
                            <div className="flex items-center">
                              <div className="flex flex-row items-center gap-1 shrink-0">
                                <p className="text-[#1D1A22] shrink-0 font-[Poppins] text-[12px] leading-[13.981px] font-medium">
                                  {
                                    new Date(item.updatedAt)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="w-[8%]">
                            <div className="flex items-center">
                              <img
                                src={edit}
                                alt="Table Action Icon"
                                onClick={() =>
                                  navigate(`/edit-role`, {
                                    state: { id: item.id },
                                  })
                                }
                                className="rounded-md cursor-pointer p-0.5 hover:bg-[#E6E6E6]"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="mb-8 mt-4">
                {count >= 10 && (
                  <TablePaginationDemo
                    count={count}
                    handleItemsChange={handleNumberOfPages}
                    handlePageChange={handlePageChange}
                    currentPage={1}
                    numOfItems={10}
                  />
                )}
                <Filter
                  isDate
                  isOther={false}
                  heading="Role Management Filter"
                  selectnameBusiness="Select Business Unit"
                  optionsBusiness={bussness}
                  onOptionSelectBusiness={handleBusinessUnitSelect}
                  selectnameDepartment="Select Department"
                  optionsDepartment={department}
                  onOptionSelectDepartment={handleDepartmentUnitSelect}
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  onApply={handleFilterApply}
                  selected={selectedDates[1]}
                  startDate={selectedDates[0]}
                  endDate={selectedDates[1]}
                  isCalendarOpen={isCalendarOpen}
                  handleCalanderClick={handleCalanderClick}
                  handleDateChange={handleDateChange}
                />
              </div>

              {/* <nav className="flex justify-end">
                <List>
                  {items.map(({ page, type, selected, ...item }, index) => {
                    let children = null;

                    if (type === "start-ellipsis" || type === "end-ellipsis") {
                      children = "…";
                    } else if (type === "page") {
                      children = (
                        <Button className="text-[12px] font-[400]" type="button" selected={selected} {...item}>
                          {page}
                        </Button>
                      );
                    } else if (type === "next" || type === "previous") {
                      const imgSrc = type === "next" ? `${right}` : `${left}`;
                      const imgAlt = type === "next" ? "Next" : "Previous";
                      children = (
                        <IconButton type="button" selected={false} {...item}>
                          <div className="flex">
                            <img
                              src={imgSrc}
                              alt={imgAlt}
                              style={{ width: "16px", height: "16px" }}
                            />
                            <span className="text-[12px] font-[600]">{imgAlt}</span>
                          </div>
                          <span className="sr-only">{type}</span>{" "}
                          {/* Optional: for screen readers --------
                        </IconButton>
                      );
                    } else {
                      children = (
                        <Button type="button" selected={false} {...item}>
                          {type}
                        </Button>
                      );
                    }

                    return <li key={index}>{children}</li>;
                  })}
                </List>

                <div className="border-2 items-center rounded-[6px] p-1 border-[#EDEDED] font-[600] text-[12px] flex">
                  100 per page<img className="w-4" src={arr} alt=""/>
                </div>
              </nav> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default RoleManagement;
