import { useEffect, useState } from "react";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { Navigate } from "react-router";
import Sidebar from "../Layout/Sidebar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ABOUT_GOTRUST,
  HOME,
  ONBOARDING_QUESTIONS,
} from "../../utils/routeConstant";
import { fetchSideBarData } from "../../redux/actions/SideBar/SideBarActions";

const PrivateRoutes = ({ children }: { children: JSX.Element }) => {
  const [sessionStart, setSessionStart] = useState("");
  const [sessionEnd, setSessionEnd] = useState("");

  const handleSessionChange = (start:any, end:any) => {
    setSessionStart(start);
    setSessionEnd(end);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem("access_token");
  const onboardingStatus = useSelector(
    (state: any) => state.auth.login?.onboarding_status
  );

  let hasAccess = false;

  const routesRedux = useSelector((state: any) => state.routes);
  const { routesArray } = routesRedux;

  const RenderComponent = () => {
    const currentPath = window.location.pathname;
    // as everyone has access to home and dashboard page
    if (currentPath === "/home" || currentPath === "/dashboard") {
      return children;
    }

    routesArray.map((route: string) => {
      if (currentPath.includes(route)) {
        hasAccess = true;
      }
    });

    if (
      !hasAccess &&
      !(
        currentPath.startsWith("/login/") ||
        currentPath === "/onboarding-questionnaire"
      )
    ) {
      navigate(`${HOME}`);
    }

    if (hasAccess) {
      return children;
    }
  };

  console.log(sessionStart,"sessss----route---->>>>>");
  console.log(sessionEnd,"sessionEnd----route---->>>>>");
  

  // useEffect(() => {
  //   if (routesArray.length) {
  //     RenderComponent();
  //   }
  // }, [window.location.pathname, routesArray]);

  useEffect(() => {
    if (isLoggedIn && window.location.pathname !== ONBOARDING_QUESTIONS) {
      dispatch<any>(fetchSideBarData());
    }
  }, [window.location.pathname]);

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  if (
    window.location.pathname === "/question" &&
    onboardingStatus !== "QUESTIONNAIRES_COMPLETED"
  ) {
    return (
      <div className="flex h-[100vh]">
        <div className="flex-grow bg-[#FFF] w-[80%] h-[100%] overflow-y-auto">
          <Header onSessionChange={handleSessionChange} />
          {children}
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="flex h-[100vh]">
        <Sidebar />
        <div className="flex-grow bg-[#FFF] w-[80%] h-[100%] overflow-y-auto">
          <Header onSessionChange={handleSessionChange}/>
          {children}
        </div>
        <Footer />
      </div>
    );
  }
};

export default PrivateRoutes;

// import React, { useEffect } from "react";
// import Header from "../Layout/header";
// import Footer from "../Layout/footer";
// import Sidebar from "../Layout/Sidebar";
// import { fetchSideBarData } from "../../redux/actions/SideBar/SideBarActions";

// const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
//   useEffect(() => {
//     fetchSideBarData();
//     // if (isLoggedIn && window.location.pathname !== ONBOARDING_QUESTIONS) {
//     //   dispatch<any>(fetchSideBarData());
//     // }
//   }, []);
//   return (
//     <div className="flex h-[100vh]">
//       <Sidebar />
//       <div className="flex-grow bg-[#FFF] w-[80%] h-[100%] overflow-y-auto">
//         <Header />
//         {children}
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default ProtectedRoute;
