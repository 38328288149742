import React, { useCallback, useEffect, useState } from "react";
import search from "../../assets/Search.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";
import del from "../../image/Vector.png";
import { Fetch_Expense_Type_List } from "../common/services/ExpenseType";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import TablePaginationDemo from "../common/Pagenation";
import { createGlobalStyle } from "styled-components";
import download from "../../image/Vector (1).png";
import date from "../../assets/date.svg";
import filter from "../../assets/filter.svg";
import DatePicker from "react-datepicker";
import { Fetch_Clients_List } from "../common/services/Clients";
import dayjs from "dayjs";
import moment from "moment";
import eye from "../../assets/Crud_Icons/view.svg";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

interface Leave_Type {
  id: string;
  client_name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

const Clients = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [dateRange, setDateRange] = useState(false);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Clients_List(
        customer_id,
        searchTerm,
        numOfItems,
        page,
        startDate,
        endDate
      );

      if (response.status === 200) {
        console.log("Data: clients Tpye", response.data.result);
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, [page, searchTerm, numOfItems, startDate, endDate]);

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  return (
    <>
      <div className="flex justify-between mt-6">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center w-full">
            <Search
              onChange={updateSearchTerm}
              label="Search with Client Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label={`${moment(selectedDates[0]).format(
                      "YYYY-MM-DD"
                    )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text">
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="w-full min-w-max text-left table-auto border-[0.6px] bg-white border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5  font-['Poppins'] text-[14px] text-[#1D1A22]">
                Client Name
              </th>
              <th className="pl-10 pr-2.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22]">
                Email
              </th>
              <th className="pl-6 pr-2.5 py-2.5  font-['Poppins'] text-[14px] text-[#1D1A22]">
                Added Date
              </th>
              <th className="pl-6 pr-2.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22]">
                Last Update
              </th>
              <th className="pr-6 flex justify-end font-['Poppins'] pl-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item) => (
              <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
                <td className="text-[#3F4254] font-['Poppins'] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {item.client_name}
                </td>
                <td className="text-[#3F4254] font-['Poppins'] font-[500] text-[12px] pl-10 pr-2.5 py-1">
                  {item.email}
                </td>
                <td className="text-[#3F4254] font-['Poppins'] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </td>
                <td className="text-[#3F4254] font-['Poppins' ] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {moment(item.updatedAt).format("YYYY-MM-DD")}
                </td>

                <td className="pr-6 pl-2.5 py-1">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      src={eye}
                      onClick={() =>
                        navigate("/client-details", {
                          state: { id: item.id },
                        })
                      }
                      alt="View"
                      className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                    />
                    <img
                      src={edit}
                      onClick={() =>
                        navigate(`/edit-clients`, {
                          state: { id: item.id },
                        })
                      }
                      alt="Edit"
                      className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
      <Filter
        isDate
        isOther={false}
        heading="Client Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </>
  );
};

export default Clients;
