import close from "../../../../assets/close-circle.svg";
 
import React from "react";
 
interface CreateUserProps {
    onClose: () => void;
    onConfirm: () => void;
    modalType: string;
}
 
const CreateUser: React.FC<CreateUserProps> = ({ onConfirm, onClose, modalType }) => {
  return (
    <>
        <div className="flex flex-col items-end px-[11px] py-[21px]">
            <div className="flex flex-col items-center shrink-0 justify-center gap-[23px]">
                <div className="text-[#262626] text-center font-[Poppins] text-lg font-bold leading-4">
                    {modalType} User
                </div>
                <div className="text-[#262626] text-center font-[Poppins] text-base font-semibold leading-[25px] px-[23px]">
                    Are you sure you want to {modalType.toLowerCase()} this {modalType === "Create" ? "new" : "existing"} user?
                </div>
                <div className="flex flex-row gap-3 h-[46px] justify-center w-full">
                    <button
                        className="w-[40%] rounded-lg bg-custom-primary text-white"
                        onClick={onConfirm}
                    >
                        Yes
                    </button>
                    <button className="w-[40%] rounded-lg bg-white border border-solid border-custom-primary text-custom-primary" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    </>
  );
}
 
export default CreateUser;