import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Fetch_Resources_List,
  Fetch_Task_List,
  fetchEditProject,
} from "../../common/services/Project";
import ModalViewer from "../../ExpenseManagement/ModalViewer";
import Receipt from "../../ExpenseManagement/ImageView";

interface EmployeeData {
  project_name: string;
  currency: string;
  status: string;
  Client: string;
  estimated_cost: string;
  actual_cost: string;
  start_date: string;
  end_date: string;
  estimated_hours: string;
  actual_hours: string;
  project_type: string;
  description: string;
}

interface RowData {
  User: {
    firstName: string;
    lastName: string;
  };
  billing_cost: string;
  actual_cost: string;
  employee_type: string;
}

interface TaskData {
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  start_date: string;
  end_date: string;
}

interface ApiResponse {
  rows: {
    id: number;
    expense_date: string;
    expense_amount: number;
    project_document: string[];
    receipt_filename: string[];
  }[];
}

const ViewProject = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [numOfItems, setNumOfItems] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [tableData1, setTableData2] = useState<TaskData[]>([]);
  const location = useLocation();
  const id = location.state?.id;
  const [receipts, setReceipts] = useState<ApiResponse["rows"]>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);

  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    project_name: "",
    currency: "",
    status: "",
    Client: "",
    estimated_cost: "",
    actual_cost: "",
    start_date: "",
    end_date: "",
    estimated_hours: "",
    actual_hours: "",
    project_type: "",
    description: "",
  });

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditProject(id)
      .then((data) => {
        // console.log(data.result.rows[0].User.Department.name, "data--res----edit expense--->>>>");
        if (data.result) {
          // setUpdateData(data);
          console.log(data.result, "data.result.state.iddata.result.state.id");

          const taskResources = data.result.TaskResources;

          const { task_document, receipt_name } = data.result;

          setEmployeeData({
            project_name: data?.result?.project_name,
            currency: data?.result?.currency,
            status: data.result?.status,
            Client: data.result?.Client?.client_name,
            estimated_cost: data.result?.estimated_cost,
            actual_cost: data.result?.actual_cost,
            start_date: data.result?.start_date,
            end_date: data.result?.end_date,
            estimated_hours: data.result?.estimated_hours,
            actual_hours: data.result?.actual_hours,
            project_type: data.result?.project_type,
            description: data.result?.description,
          });

          setReceipts([data?.result]);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Resources_List(
        id,
        searchTerm,
        numOfItems,
        page
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        console.log("Data: Resources employee", response.data.result);
        setTableData(response.data.result.rows);
        // setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData();
    console.log("after fetch page", page);
  }, [page, numOfItems]);

  const fetchData2 = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Task_List(
        id,
        numOfItems,
        page
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        console.log("Data: Task employee", response.data.result);
        setTableData2(response.data.result.rows);
        // setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData2();
    console.log("after fetch page", page);
  }, [page, numOfItems]);

  return (
    <>
      <div className="bg-[#FBFBFB] pt-6 pb-12 px-[18.5px]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/project-management")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
              Project Details
            </span>
          </div>
        </div>

        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-semibold mb-4">Project Details</h2>
          <div className=" bg-[#FBFBFB] p-4">
            <div className="grid grid-cols-3 p-1 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Project Name
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.project_name}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Status
                </strong>
                <br />{" "}
                <span className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  <span className="bg-[#6BC0E44A] px-2 py-1">
                    {employeeData.status}
                  </span>
                </span>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Currency
                </strong>
                <br />
                <span className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {employeeData.currency}
                </span>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Client
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.Client}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Estimate Cost
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {employeeData.estimated_cost}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Actual Cost
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {employeeData.actual_cost}
                </strong>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b-[2px] border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Start Date
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.start_date}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  End Date
                </strong>
                <br />{" "}
                <strong className="font-[500] font-['Poppins'] text-[16px] ">
                  {employeeData.end_date}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Estimate Hours
                </strong>
                <br />{" "}
                <strong className="font-[500] font-['Poppins'] text-[16px] ">
                  {employeeData.estimated_hours}
                </strong>
              </p>
            </div>

            <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Actual Hours
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.actual_hours}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Project Type
                </strong>
                <br />{" "}
                <strong className="font-[500] font-['Poppins'] text-[16px] ">
                  {employeeData.project_type}
                </strong>
              </p>
            </div>

            <div className="grid grid-cols-1 p-2 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Description
                </strong>
                <br />{" "}
                <strong className="font-['Poppins'] font-[500] text-[16px] ">
                  {employeeData.description}
                </strong>
              </p>
            </div>

            <div className="mt-4 p-1">
              <strong className="text-[#99A1B7] font-[500] text-[16px] mb-4">
                Attachment
              </strong>
              <div className="grid grid-cols-2 gap-4">
                {receipts?.map((receipt) =>
                  receipt?.project_document?.map((url, index) => {
                    const fileName =
                      receipt?.project_document &&
                      receipt?.project_document[index]
                        ? receipt.project_document[index]
                        : `Receipt ${index + 1}`;

                    return (
                      <Receipt
                        key={index}
                        fileName={fileName}
                        fileSize={`${(Math.random() * 2 + 1)?.toFixed(2)} MB`} // Random file size for demonstration
                        onView={() => handleView(url)}
                      />
                    );
                  })
                )}

                {selectedFileUrl && (
                  <ModalViewer
                    isOpen={!!selectedFileUrl}
                    onRequestClose={closeModal}
                    fileUrl={selectedFileUrl}
                  />
                )}
              </div>
            </div>
          </div>
          <h2 className="text-lg font-semibold mb-4">Resources Details</h2>

          <div className="bg-white shadow-sm mt-4 overflow-hidden">
            <table className="w-full min-w-max text-left table-auto border-[0.6px] border-[#E1E1E1]">
              <thead className="bg-[#E1E1E1]">
                <tr className="h-11 px-8">
                  <th className="pl-8 pr-2.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22]">
                    Resources
                  </th>
                  <th className="pl-6 text-center font-['Poppins'] py-2.5 text-[14px] text-[#1D1A22]">
                    Billable Cost/hr (Dollar)
                  </th>
                  <th className="pl-6 pr-2.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22] text-center">
                    Actual Cost/hr (Dollar)
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((items) => (
                  <tr className="h-10 px-8 hover:cursor-pointer">
                    <td className="relative text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-8 py-1">
                      {items?.employee_type === "Manager" && (
                        <div className="flex h-full items-center absolute left-0 top-0">
                          <div className="h-7 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none w-[22px] bg-[#EAE0FF] flex items-center justify-center">
                            <span className="font-[Poppins] font-semibold text-[12px] leading-[18px] text-[#1D1A22]">
                              M
                            </span>
                          </div>
                        </div>
                      )}
                      {items?.User?.firstName} {items?.User?.lastName}
                    </td>
                    <td className="text-[#3F4254] font-['Poppins'] font-[500] text-[12px] py-1 text-center">
                      {items?.actual_cost || "--"}
                    </td>
                    <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 pr-2.5 py-1 text-center">
                      {items?.billing_cost || "--"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h2 className="text-lg font-semibold my-4">Task Details</h2>
          <div className="overflow-x-auto mt-4">
            <table className="w-full min-w-max text-left table-auto border-[1px] border-[#EBEBEB]">
              <thead className="bg-[#E1E1E1]">
                <tr className="h-11">
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] font-['Poppins'] text-[#1D1A22]">
                    Task Name
                  </th>
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] font-['Poppins'] text-[#1D1A22]">
                    Estimate Hours
                  </th>
                  <th className="pl-6 pr-2.5 py-2.5 text-[14px] font-['Poppins'] text-[#1D1A22]">
                    Actual Hours
                  </th>
                  <th className="pl-6 pr-0.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22]">
                    Start Date
                  </th>
                  <th className="pl-6 pr-0.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22]">
                    End Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData1?.map((items) => (
                  <tr className="h-10 border-[0.6px] border-solid font-['Poppins'] border-[#E0E3E7] hover:bg-[#f9f9f9] cursor-pointer">
                    <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 pr-2.5 py-1">
                      {items.task_name}
                    </td>
                    <td className="text-[#3F4254] font-['Poppins'] font-[500] text-[12px] py-1 pl-6">
                      {items.estimated_hours}
                    </td>
                    <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 pr-2.5 py-1">
                      {items.actual_hours}
                    </td>
                    <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 py-1">
                      {items.start_date}
                    </td>
                    <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 py-1">
                      {items.end_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProject;
