import React, { useEffect, useState } from "react";
import {
  Fetch_Birthday_Anniversary_Lst,
  Fetch_Leave_Dashboard_List,
  Fetch_WFH_Dashboard_List,
} from "../common/services/Dashboard";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import noResult from "../../assets/noResult.svg";
import { enUS } from "date-fns/locale";
import avatar from "../../assets/avatar.svg"

interface Leave_Type {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
  wfh_session: string;
}

interface Dashboard {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
  leave_session: string;
}

interface BirthdayAnniversary {
  firstName: string;
  lastName: string;
  type: string;
}

const Dashboard = () => {
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [tableData1, setTableData1] = useState<Dashboard[]>();
  const [tableData2, setTableData2] = useState<BirthdayAnniversary[]>();
  const [loading, setLoading] = useState(false);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const signupData = useSelector((state: any) => state.signup.details);

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_WFH_Dashboard_List(numOfItems, page);

      if (response.status === 200) {
        console.log("Data: Dashboard wfh Tpye", response.data.result);
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, [page, numOfItems]);

  const fetchLeaveDashboard = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Leave_Dashboard_List(numOfItems, page);

      if (response.status === 200) {
        console.log("Data: Dashboard wfh Tpye", response.data.result);
        setTableData1(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveDashboard();
  }, [page, numOfItems]);

  const fetchBirthdayAnniversary = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Birthday_Anniversary_Lst(numOfItems, page);

      if (response.status === 200) {
        console.log("Data: Dashboard birthday Tpye", response.data.result);
        setTableData2(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBirthdayAnniversary();
  }, [page, numOfItems]);

  const users = [
    { name: "Cameron Williamson", time: "Full day" },
    { name: "Cameron Williamson", time: "First half" },
    { name: "Cameron Williamson", time: "First half" },
    { name: "Devon Lane", time: "Second half" },
    { name: "Devon Lane", time: "Second half" },
    // add more users as needed
  ];

  const users1 = [
    { name: "Cameron Williamson" },
    { name: "Devon Lane" },
    { name: "Devon Lane" },
    { name: "Devon Lane" },
    { name: "Devon Lane" },
    // add more users as needed
  ];

  console.log(loginData, "logindata-------->>>>>>>>>>");

  const currentDate = new Date();

  // Format the date as "Friday, 15/08/2024"
  const formattedDate = format(currentDate, "EEEE, MMMM do yyyy", {
    locale: enUS,
  });

  return (
    <div className="bg-[#F9F9F9] pb-10">
      <header className="flex justify-between items-center h-10 px-4 pt-6 pb-2">
        <div>
          <p className="text-[#79747E] font-[400] font-[Poppins] text-[12px]">
            {formattedDate}
          </p>
          <h1 className="text-[#3F3F3F] font-[600] font-[Poppins] text-[18px]">
            {loginData && loginData.first_name ? (
              <span>
                {" "}
                <span className="text-[#3D3D3D] font-[400] pr-1">Welcome</span>
                {loginData.first_name} {loginData.last_name}
              </span>
            ) : signupData && signupData.sign_up_details.firstName ? (
              `Welcome ${signupData.sign_up_details.firstName} ${signupData.sign_up_details.lastName}`
            ) : (
              "NA"
            )}
          </h1>
        </div>
      </header>
      <div className="grid grid-cols-1 px-4 md:grid-cols-3 gap-6 mt-1">
        <div className="col-span-2">
          <div className="bg-white shadow-custom rounded-lg p-4 mt-4 min-h-[72vh]">
            <div style={{ minHeight: "calc(36vh - 28px)" }}>
              <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] pb-4">
                On Leave Today
              </h2>
              {tableData1?.length !== 0 ? (
                <div className="grid grid-cols-4 gap-4">
                  {tableData1?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user.User.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.User?.firstName} {user.User?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.leave_session}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="h-[calc(100% - 30px)] w-full flex justify-center items-center">
                  <img src={noResult} alt="No results" />
                </div>
              )}
            </div>
            <div className="mt-6" style={{ minHeight: "calc(36vh - 28px)" }}>
              <h2 className="text-[#1D1A22] font-[500] text-[20px] pb-4 font-[Poppins]">
                Remotely Available
              </h2>
              {tableData?.length !== 0 ? (
                <div className="grid grid-cols-4 gap-4">
                  {tableData?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user.User.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user.User.firstName} {user.User.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user.wfh_session}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="h-[calc(100% - 30px)] w-full flex justify-center items-center">
                  <img src={noResult} alt="No results" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="min-h-[72vh] mt-4">
          <div className="bg-white shadow-custom rounded-lg p-4 min-h-[46vh]">
            <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] mb-4">
              Activities
            </h2>
            <div className="flex justify-center items-center h-48">
              <img src={noResult} alt="No results" />
            </div>
            {/* <div className="mb-4 bg-[#e6e2ee] border-s-4 p-2 border-solid border-[#AD8CF1] rounded-[5px] ">
              <p className="text-[#333333] text-[16px] font-[500] font-[Poppins]">
                Today's lunch from office
              </p>
              <p className="font-[Poppins] font-[400] text-[#8F8F8F] text-[12px]">
                Lorem ipsum dolor sit amet...
              </p>
            </div>
            <div className="mb-4 bg-[#d8f0d0] border-s-4 p-2 border-solid border-[#9BD486] rounded-[5px] ">
              <p className="text-[#333333] text-[16px] font-[500] font-[Poppins]">
                Holiday
              </p>
              <p className="font-[Poppins] font-[400] text-[#8F8F8F] text-[12px]">
                Independence Day
              </p>
            </div>
            <div className="bg-[#e6e2ee] border-s-4 p-2 border-solid border-[#AD8CF1] rounded-[5px] ">
              <p className="text-[#333333] text-[16px] font-[500] font-[Poppins]">
                Today's lunch from office
              </p>
              <p className="font-[Poppins] font-[400] text-[#8F8F8F] text-[12px]">
                Lorem ipsum dolor sit amet...
              </p>
            </div> */}
          </div>
          <div className="bg-white shadow-custom rounded-lg p-4 mt-4 min-h-[24vh]">
            <h2 className="text-[#1D1A22] font-[Poppins] text-[20px] font-[500]">
              Birthdays & Anniversaries
            </h2>
            {tableData2?.length !== 0 ? (
              <div className="flex flex-col gap-2 pt-2">
                {tableData2?.map((item) => (
                  <div className="viewBackground p-1.5 rounded-md">
                    <p className="text-[#333333] font-[500] font-[Poppins] text-[16px]">
                      {item?.firstName} {item?.lastName}'s
                    </p>
                    <p className="text-[#8F8F8F] font-[400] font-[Poppins] text-[12px]">
                      {item?.type}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="h-full w-full flex justify-center items-center pt-2">
                <img src={noResult} alt="No results" className="h-20"/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
