import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as RouterRoutes,
} from "react-router-dom";
import LoginScreen from "./components/Auth/LoginScreen";
import Signup from "./components/Signup/signup";
import CannotLogin from "./components/Auth/CannotLogin";
import Recovery from "./components/Auth/Recovery";
import OrgDetails from "./components/Signup/OrgDetails";
import VerifyEmail from "./components/Signup/VerifyEmail";
import SignupPassword from "./components/Signup/SignupPassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import UserManagement from "./components/UserManagement/UserManagement";

import {
  SELECTED_VIEW_GROUP_DETAILS,
  USER_MANAGEMENT,
} from "./utils/routeConstant";

import RoleManagement from "./components/roleManagement/roleManagement";
import Questions from "./components/Onboarding/Questions";

import CustomerManagement from "./components/CustomerManagement/CustomerManagement";

import RoleDetails from "./components/roleManagement/roleDetails";
import AddRole from "./components/roleManagement/addRole";
import EditRole from "./components/roleManagement/editRole";
import EditRoleDetails from "./components/roleManagement/editRoleDetails";
import {
  ROLE_MANAGEMENT_EDIT_ROLE_DETAILS,
  ROLE_MANAGEMENT_ROLE_DETAILS,
  COMPANY_STRUCTURE,
} from "./utils/routeConstant";

import PublicRoutes from "./components/ScopeRoute/PublicRoute";
import PrivateRoutes from "./components/ScopeRoute/ProtectedRoute";
import CompanyStructure from "./components/Company Structure/CompanyStructure";
import ViewGroupDetails from "./components/Company Structure/ViewGroupDetails";
import AboutUs from "./components/common/AboutUs";
import { AddEmployee } from "./components/EmployeeManagement/AddEmployee";
import { Designation } from "./components/DesignationManagement/Designation";
import { AddDesignation } from "./components/DesignationManagement/AddDesignation";
import { EditDesignation } from "./components/DesignationManagement/EditDesignation";
import { Employee } from "./components/EmployeeManagement/Employee";
import AddExitProcedure from "./components/EmployeeManagement/AddExitProcedure";
import { EditExitProcedure } from "./components/EmployeeManagement/EditExitProcedure";
import { LeaveAndHoliday } from "./components/LeaveAndHoliday/LeaveAndHoliday";
import AddLeave from "./components/LeaveAndHoliday/Leave/AddLeave";
import AddWFH from "./components/WorkFromHome/AddWFH";
import AddHoliday from "./components/LeaveAndHoliday/Holiday/AddHoliday";
import AddLeaveType from "./components/LeaveAndHoliday/LeaveType/AddLeaveType";
import { EditLeave } from "./components/LeaveAndHoliday/Leave/EditLeave";
import ViewLeaveDetails from "./components/LeaveAndHoliday/Leave/ViewLeaveDetails";
import ViewEmployeeList from "./components/EmployeeManagement/ViewEmployeeList";
import ViewExitProcedure from "./components/EmployeeManagement/ViewExitProcedure";
import ViewWFH from "./components/WorkFromHome/ViewWFH";
import ViewLeaveBalance from "./components/LeaveAndHoliday/LeaveBalance/ViewLeaveBalance";
import AddLeaveBalance from "./components/LeaveAndHoliday/LeaveBalance/AddLeaveBalance";
import EditWFH from "./components/WorkFromHome/EditWFH";
import EditHoliday from "./components/LeaveAndHoliday/Holiday/EditHoliday";
import EditLeaveType from "./components/LeaveAndHoliday/LeaveType/EditLeaveType";
import AttendenceDetails from "./components/AttendenceDetails/AttendenceDetails";
import AddAttendence from "./components/AttendenceDetails/AddAttendence";
import EditAttendence from "./components/AttendenceDetails/EditAttendence";
import { EditEmployee } from "./components/EmployeeManagement/EditEmployee";
import Dashboard from "./components/Dashboard/Dashboard";
import { ExpenseManagement } from "./components/ExpenseManagement/ExpenseManagement";
import AddExpense from "./components/ExpenseManagement/AddExpense";
import EditExpense from "./components/ExpenseManagement/EditExpense";
import ViewExpense from "./components/ExpenseManagement/ViewExpense";
import AddClient from "./components/ExpenseManagement/AddClient";
import ViewClients from "./components/ExpenseManagement/ViewClients";
import EditExpenseType from "./components/ExpenseManagement/EditExpenseType";
import { ProjectManagement } from "./components/ProjectManagement/ProjectManagement";
import AddClients from "./components/ProjectManagement/AddClients";
import EditClients from "./components/ProjectManagement/EditClients";
import AddProjects from "./components/ProjectManagement/AddProjects";
import ViewClient from "./components/ProjectManagement/ViewClients";
import EditResources from "./components/ProjectManagement/EditResources";
import EditTask from "./components/ProjectManagement/EditTask";
import { EditLeaveBalance } from "./components/LeaveAndHoliday/LeaveBalance/EditLeaveBalance";
import { EditProjectDetails } from "./components/ProjectManagement/EditProject/EditProjectDetails";
import ViewProject from "./components/ProjectManagement/EditProject/ViewProject";
import ViewTask from "./components/ProjectManagement/ViewTask";
import { CompanyManagement } from "./components/CompanyManagement/CompanyManagement";
import { CreatePolicies } from "./components/CompanyManagement/CreatePolicies";
import { EditPolicyDetails } from "./components/CompanyManagement/EditPolicyDetails";
import { ViewPolicy } from "./components/CompanyManagement/ViewPolicy";
import { EditCompanyDetails } from "./components/CompanyManagement/EditCompanyDetails";
import { AddPolicy } from "./components/CompanyManagement/AddPolicy";
import { EmployeeDSR } from "./components/EmployeeDSR/EmployeeDSR";
import { EmployeeDSRDetails } from "./components/EmployeeDSR/EmployeeDSRDetails";
import Announcements from "./components/Announcements/Announcements";
import ViewAnncouncement from "./components/Announcements/ViewAnncouncement";
import AddAnnouncement from "./components/Announcements/AddAnnouncement";
import EditAnnouncement from "./components/Announcements/EditAnnouncement";
import AssetManagement from "./components/AssetManagement/AssetManagement";
import AddAsset from "./components/AssetManagement/AddAsset";
import EditAsset from "./components/AssetManagement/EditAsset";
import ViewAssetDetails from "./components/AssetManagement/ViewAssetDetails";

const AuthenticatedRoutes: React.FC = () => {
  // const loginData = useSelector((state: any) => state.auth.login.login_details);
  // const currentRiskAssessment = useSelector(
  //   (state: any) => state?.riskAssessment?.currentAssessment
  // );
  // const role = loginData?.role;
  // let customer_id = 0;
  // let access_token = "";
  // if (loginData !== null) {
  //   access_token = loginData.token || loginData.access_token || "";
  //   customer_id = loginData.customer_id;
  // }
  // const isManager = role === "Data Protection Officer" || role === "God Admin";
  return (
    <RouterRoutes>
      <Route
        path={"/about"}
        element={
          <PrivateRoutes>
            <AboutUs />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/role-management"}
        element={
          <PrivateRoutes>
            <RoleManagement />
          </PrivateRoutes>
        }
      />
      {/* <Route
        path={"/company-structure"}
        element={
          <PrivateRoutes>
            <CompanyStructure />
          </PrivateRoutes>
        }
      /> */}
      <Route
        path={COMPANY_STRUCTURE}
        element={
          <PrivateRoutes>
            <CompanyStructure />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/customer-management"}
        element={
          <PrivateRoutes>
            <CustomerManagement />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/employee-management"}
        element={
          <PrivateRoutes>
            <Employee />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/exit-procedure"}
        element={
          <PrivateRoutes>
            <Employee />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-role"}
        element={
          <PrivateRoutes>
            <AddRole />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-employee"}
        element={
          <PrivateRoutes>
            <AddEmployee />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-employee"}
        element={
          <PrivateRoutes>
            <EditEmployee />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-role"}
        element={
          <PrivateRoutes>
            <EditRole />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-exit"}
        element={
          <PrivateRoutes>
            <AddExitProcedure />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-exit"}
        element={
          <PrivateRoutes>
            <EditExitProcedure />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/leave-holiday-management"}
        element={
          <PrivateRoutes>
            <LeaveAndHoliday />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-leave"}
        element={
          <PrivateRoutes>
            <AddLeave />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-work-from-home"}
        element={
          <PrivateRoutes>
            <AddWFH />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/add-holiday"}
        element={
          <PrivateRoutes>
            <AddHoliday />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-leave-type"}
        element={
          <PrivateRoutes>
            <AddLeaveType />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-leave-balance"}
        element={
          <PrivateRoutes>
            <AddLeaveBalance />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/leave-details/:id"}
        element={
          <PrivateRoutes>
            <ViewLeaveDetails />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-leave/:id"}
        element={
          <PrivateRoutes>
            <EditLeave />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/work-from-home"}
        element={
          <PrivateRoutes>
            <LeaveAndHoliday />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-work-from-home"}
        element={
          <PrivateRoutes>
            <EditWFH />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/holiday"}
        element={
          <PrivateRoutes>
            <LeaveAndHoliday />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-holiday"}
        element={
          <PrivateRoutes>
            <EditHoliday />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/leave-balance"}
        element={
          <PrivateRoutes>
            <LeaveAndHoliday />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/leave-balance-details"}
        element={
          <PrivateRoutes>
            <ViewLeaveBalance />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/leave-type"}
        element={
          <PrivateRoutes>
            <LeaveAndHoliday />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-leave-type/:id"}
        element={
          <PrivateRoutes>
            <EditLeaveType />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/employee-list-details"}
        element={
          <PrivateRoutes>
            <ViewEmployeeList />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/exit-procedure-details"}
        element={
          <PrivateRoutes>
            <ViewExitProcedure />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/work-from-home-details"}
        element={
          <PrivateRoutes>
            <ViewWFH />
          </PrivateRoutes>
        }
      />
      <Route
        path={ROLE_MANAGEMENT_EDIT_ROLE_DETAILS}
        element={
          <PrivateRoutes>
            <EditRoleDetails />
          </PrivateRoutes>
        }
      />
      <Route
        path={ROLE_MANAGEMENT_ROLE_DETAILS}
        element={
          <PrivateRoutes>
            <RoleDetails />
          </PrivateRoutes>
        }
      />

      <Route
        path={USER_MANAGEMENT}
        element={
          <PrivateRoutes>
            <UserManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={SELECTED_VIEW_GROUP_DETAILS}
        element={
          <PrivateRoutes>
            <ViewGroupDetails />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/designation-management"}
        element={
          <PrivateRoutes>
            <Designation />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/attendance-management"}
        element={
          <PrivateRoutes>
            <AttendenceDetails />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/attendance-management/add-attendence"}
        element={
          <PrivateRoutes>
            <AddAttendence />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/attendance-management/edit-attendence/:id"}
        element={
          <PrivateRoutes>
            <EditAttendence />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-designation"}
        element={
          <PrivateRoutes>
            <AddDesignation />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-designation"}
        element={
          <PrivateRoutes>
            <EditDesignation />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/dashboard"}
        element={
          <PrivateRoutes>
            <Dashboard />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/expense-management"}
        element={
          <PrivateRoutes>
            <ExpenseManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/clients"}
        element={
          <PrivateRoutes>
            <ExpenseManagement />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-expense"}
        element={
          <PrivateRoutes>
            <AddExpense />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-expense"}
        element={
          <PrivateRoutes>
            <EditExpense />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/view-expense"}
        element={
          <PrivateRoutes>
            <ViewExpense />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-client"}
        element={
          <PrivateRoutes>
            <AddClient />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-client/:id"}
        element={
          <PrivateRoutes>
            <EditExpenseType />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/view-client"}
        element={
          <PrivateRoutes>
            <ViewClients />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/project-management"}
        element={
          <PrivateRoutes>
            <ProjectManagement />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/client"}
        element={
          <PrivateRoutes>
            <ProjectManagement />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-clients"}
        element={
          <PrivateRoutes>
            <AddClients />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-clients"}
        element={
          <PrivateRoutes>
            <EditClients />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-project"}
        element={
          <PrivateRoutes>
            <AddProjects />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/add-resources/:id"}
        element={
          <PrivateRoutes>
            <AddProjects />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-resources"}
        element={
          <PrivateRoutes>
            <EditResources />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/add-task/:id"}
        element={
          <PrivateRoutes>
            <AddProjects />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/edit-task"}
        element={
          <PrivateRoutes>
            <EditTask />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-leave-balance"}
        element={
          <PrivateRoutes>
            <EditLeaveBalance />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/view-project-details"}
        element={
          <PrivateRoutes>
            <ViewProject />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/view-task-details"}
        element={
          <PrivateRoutes>
            <ViewTask />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/edit-project-details"}
        element={
          <PrivateRoutes>
            <EditProjectDetails />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/edit-resources-details/:id"}
        element={
          <PrivateRoutes>
            <EditProjectDetails />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/edit-task-details/:id"}
        element={
          <PrivateRoutes>
            <EditProjectDetails />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/client-details"}
        element={
          <PrivateRoutes>
            <ViewClient />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/policies"}
        element={
          <PrivateRoutes>
            <CompanyManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/edit-company-details"}
        element={
          <PrivateRoutes>
            <EditCompanyDetails />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/create-policy"}
        element={
          <PrivateRoutes>
            <CreatePolicies />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/edit-policy-details"}
        element={
          <PrivateRoutes>
            <EditPolicyDetails />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/view-policy-details"}
        element={
          <PrivateRoutes>
            <ViewPolicy />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/policy-categories"}
        element={
          <PrivateRoutes>
            <CompanyManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/company-management"}
        element={
          <PrivateRoutes>
            <CompanyManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/add-policy"}
        element={
          <PrivateRoutes>
            <AddPolicy />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/employee-dsr"}
        element={
          <PrivateRoutes>
            <EmployeeDSR />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/employee-dsr-details"}
        element={
          <PrivateRoutes>
            <EmployeeDSRDetails />
          </PrivateRoutes>
        }
      />

      {/* Announcements  */}
      <Route
        path={"/announcement-management"}
        element={
          <PrivateRoutes>
            <Announcements />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/view-announcement"}
        element={
          <PrivateRoutes>
            <ViewAnncouncement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/add-announcement"}
        element={
          <PrivateRoutes>
            <AddAnnouncement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/edit-announcement"}
        element={
          <PrivateRoutes>
            <EditAnnouncement />
          </PrivateRoutes>
        }
      />

      {/* Asset Management */}
      <Route
        path={"/asset-management"}
        element={
          <PrivateRoutes>
            <AssetManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/assigned-asset"}
        element={
          <PrivateRoutes>
            <AssetManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/asset-category"}
        element={
          <PrivateRoutes>
            <AssetManagement />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/add-asset"}
        element={
          <PrivateRoutes>
            <AddAsset />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/edit-asset"}
        element={
          <PrivateRoutes>
            <EditAsset />
          </PrivateRoutes>
        }
      />

      <Route
        path={"/view-asset"}
        element={
          <PrivateRoutes>
            <ViewAssetDetails />
          </PrivateRoutes>
        }
      />

    </RouterRoutes>
    // <RouterRoutes>
    //   <Route
    //     path={HOME}
    //     element={
    //       <PrivateRoutes>
    //         <Home />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={PRIVACY_POLICY_CREATE_POLICY}
    //     element={
    //       <PrivateRoutes>
    //         <CreatePolicy />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={RISK_ASSESSMENT}
    //     element={
    //       <PrivateRoutes>
    //         <AssessmentActivityLog />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={`${RISK_ASSESSMENT.slice(0, -1)}${
    //       currentRiskAssessment.key
    //     }/basics-information`}
    //     element={
    //       <PrivateRoutes>
    //         <AssessmentBasicsInformation />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={`${RISK_ASSESSMENT.slice(0, -1)}${
    //       currentRiskAssessment.key
    //     }/action/*`}
    //     element={
    //       <PrivateRoutes>
    //         <RiskAssessment />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={USER_MANAGEMENT}
    //     element={
    //       <PrivateRoutes>
    //         <UserManagement />
    //       </PrivateRoutes>
    //     }
    //   />

    //   <Route
    //     path={INVOICE}
    //     element={
    //       <PrivateRoutes>
    //         <InvoiceTableLayout />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROPA_DASHBOARD}
    //     element={
    //       <PrivateRoutes>
    //         <DashboardRopa />
    //       </PrivateRoutes>
    //     }
    //   />

    //   <Route
    //     path={PRIVACY_POLICY_MANAGEMENT}
    //     element={
    //       <PrivateRoutes>
    //         <PrivacyPolicy />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={SCANLINK}
    //     element={
    //       <PrivateRoutes>
    //         <ScanLink />
    //       </PrivateRoutes>
    //     }
    //   />

    //   <Route
    //     path={ABOUT_GOTRUST}
    //     element={
    //       <PrivateRoutes>
    //         <AboutUs />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={DOCUMENTS}
    //     element={
    //       <PrivateRoutes>
    //         <Documents />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={VIDEOS}
    //     element={
    //       <PrivateRoutes>
    //         <Videos />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={BLOGS}
    //     element={
    //       <PrivateRoutes>
    //         <Blogs />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={VIEW_DETAIL_BLOGS}
    //     element={
    //       <PrivateRoutes>
    //         <ViewDetailBlog />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={COMMUNITY}
    //     element={
    //       <PrivateRoutes>
    //         <Community />
    //       </PrivateRoutes>
    //     }
    //   />

    //   <Route
    //     path={PROFILE}
    //     element={
    //       <PrivateRoutes>
    //         <Profile />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={CHANGE_PASSWORD}
    //     element={
    //       <PrivateRoutes>
    //         <ChangePassword />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={EDIT_GROUP}
    //     element={
    //       <PrivateRoutes>
    //         <EditGroupUser />
    //       </PrivateRoutes>
    //     }
    //   />

    //   <Route
    //     path={ONBOARDING}
    //     element={
    //       <PrivateRoutes>
    //         <OnboardWithUs />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROLE_MANAGEMENT}
    //     element={
    //       <PrivateRoutes>
    //         <RoleManagement />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROLE_MANAGEMENT_ROLE_DETAILS}
    //     element={
    //       <PrivateRoutes>
    //         <RoleDetails />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROLE_MANAGEMENT_ADD_ROLE}
    //     element={
    //       <PrivateRoutes>
    //         <AddRole />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={EDIT_TICKET_DETAILS}
    //     element={
    //       <PrivateRoutes>
    //         <EditTicketDetails />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROLE_MANAGEMENT_EDIT_ROLE}
    //     element={
    //       <PrivateRoutes>
    //         <EditRole />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROLE_MANAGEMENT_EDIT_ROLE_DETAILS}
    //     element={
    //       <PrivateRoutes>
    //         <EditRoleDetails />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={SUPPORT}
    //     element={
    //       <PrivateRoutes>
    //         {isManager ? <Support /> : <SupportEmployeeDashboard />}
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={SUPPORT_DASBOARD}
    //     element={
    //       <PrivateRoutes>
    //         {isManager ? <SupportDashboard /> : <SupportEmployeeDashboard />}
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={CREATE_TICKET}
    //     element={
    //       <PrivateRoutes>
    //         <CreateTicket />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ALL_TICKET}
    //     element={
    //       <PrivateRoutes>
    //         {isManager ? <AllTicket /> : <SupportEmployeeDashboard />}
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={CLOSE_TICKET}
    //     element={
    //       <PrivateRoutes>
    //         {isManager ? <CloseTicket /> : <SupportEmployeeDashboard />}
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={OPEN_TICKET}
    //     element={
    //       <PrivateRoutes>
    //         {isManager ? <OpenTicket /> : <SupportEmployeeDashboard />}
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={CUSOMER_MANAGEMENT}
    //     element={
    //       <PrivateRoutes>
    //         <CustomerManagement />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ONBOARDING_QUESTIONS}
    //     element={
    //       <PrivateRoutes>
    //         <Questions />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={COMPANY_STRUCTURE}
    //     element={
    //       <PrivateRoutes>
    //         <CompanyStructure />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={SELECTED_VIEW_GROUP_DETAILS}
    //     element={
    //       <PrivateRoutes>
    //         <ViewGroupDetails />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={VIEW_TICKET_DETAILS}
    //     element={
    //       <PrivateRoutes>
    //         <ViewTicket />
    //       </PrivateRoutes>
    //     }
    //   />

    //   <Route
    //     path={ACTIVITY_LOG}
    //     element={
    //       <PrivateRoutes>
    //         <ActivityLog />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROPA_BASIC_INFORMATION}
    //     element={
    //       <PrivateRoutes>
    //         <RopaBasicsInformation />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROPA_REGISTER}
    //     element={
    //       <PrivateRoutes>
    //         <RopaRegister />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROPA_ADD_QUESTION}
    //     element={
    //       <PrivateRoutes>
    //         <AddQuestion />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route
    //     path={ROPA_RIVIEW}
    //     element={
    //       <PrivateRoutes>
    //         <RopaReview />
    //       </PrivateRoutes>
    //     }
    //   />
    //   <Route path="/*" element={<NotFound />} />
    //   <Route path="/server-error" element={<InternalServer />} />
    // </RouterRoutes>
  );
};
const UnauthenticatedRoutes: React.FC = () => {
  return (
    <RouterRoutes>
      <Route
        path={"/"}
        element={
          <PublicRoutes>
            <LoginScreen />
          </PublicRoutes>
        }
      />
      <Route
        path={"/signup"}
        element={
          <PublicRoutes>
            <Signup />
          </PublicRoutes>
        }
      />
      <Route
        path={"/orgnisatioal-details"}
        element={
          <PublicRoutes>
            <OrgDetails />
          </PublicRoutes>
        }
      />
      <Route
        path={"/signup-password"}
        element={
          <PublicRoutes>
            <SignupPassword />
          </PublicRoutes>
        }
      />
      <Route
        path={"/question"}
        element={
          <PrivateRoutes>
            <Questions />
          </PrivateRoutes>
        }
      />
      <Route
        path={"/verify-email"}
        element={
          <PublicRoutes>
            <VerifyEmail />
          </PublicRoutes>
        }
      />
      <Route
        path={"/forget-password"}
        element={
          <PublicRoutes>
            <CannotLogin />
          </PublicRoutes>
        }
      />
      <Route
        path={"/create-password"}
        element={
          <PublicRoutes>
            <ForgotPassword />
          </PublicRoutes>
        }
      />
      <Route
        path={"/onboarding-questionnaire"}
        element={
          <PublicRoutes>
            <Questions />
          </PublicRoutes>
        }
      />
      <Route
        path={"/recovery"}
        element={
          <PublicRoutes>
            <Recovery />
          </PublicRoutes>
        }
      />
    </RouterRoutes>
    // <RouterRoutes>
    //   <Route
    //     path={HOMEPAGE}
    //     element={
    //       <PublicRoutes>
    //         <Homepage />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={LOGIN}
    //     element={
    //       <PublicRoutes>
    //         <LoginScreen />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={LOGIN_WITH_PARAM}
    //     element={
    //       <PublicRoutes>
    //         <LoginScreen />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={SIGNUP}
    //     element={
    //       <PublicRoutes>
    //         <Signup />
    //       </PublicRoutes>
    //     }
    //   />

    //   <Route
    //     path={CANNOT_LOGIN}
    //     element={
    //       <PublicRoutes>
    //         <CannotLogin />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={RECOVERY}
    //     element={
    //       <PublicRoutes>
    //         <Recovery />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={FORGOT_PASSWORD}
    //     element={
    //       <PublicRoutes>
    //         <ForgotPassword />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={ORGANISATION_DETAILS}
    //     element={
    //       <PublicRoutes>
    //         <OrgDetails />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={VERIFY_EMAIL}
    //     element={
    //       <PublicRoutes>
    //         <VerifyEmail />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={SET_PASSWORD}
    //     element={
    //       <PublicRoutes>
    //         <SignupPassword />
    //       </PublicRoutes>
    //     }
    //   />
    //   <Route
    //     path={SET_PASSWORD}
    //     element={
    //       <PublicRoutes>
    //         <SignupPassword />
    //       </PublicRoutes>
    //     }
    //   />
    // </RouterRoutes>
  );
};
const Routes: React.FC = () => {
  return (
    <>
      <Router>
        {/* Render authenticated routes if user is authenticated, otherwise render unauthenticated routes */}
        <UnauthenticatedRoutes />
        <AuthenticatedRoutes />

        {/* <RouterRoutes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/orgnisatioal-details" element={<OrgDetailsCopy />} />
          <Route path="/signup-password" element={<SignupPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/onboarding-questionnaire" element={<Questions/>} />
          <Route path="/recovery" element={<Recovery/>}/>
        </RouterRoutes> */}

        {/* <div className="flex h-[100vh]">
          <Sidebar />
          <div className="flex-grow bg-[#FFF] w-[80%] h-[100%] overflow-y-auto">
            <Header />
            <RouterRoutes>
              <Route path="/home" element={<DashboardRopa />} />
              <Route path="/about-go-ems" element={<AboutUs />} />
              <Route path="/role-management" element={<RoleManagement />} />
              <Route path="/company-structure" element={<CompanyStructure />} />
              <Route path="/customer-management" element={<CustomerManagement />} />
              <Route path="/employee-management" element={<Employee />} />
              <Route path="/employee-exit-procedure" element={<ExitProcedure />} />
              <Route path="/add-role" element={<AddRole />} />
              <Route path="/add-employee" element={<AddEmployee />} />
              <Route path="/edit-employee" element={<AddEmployee />} />
            </RouterRoutes>
          </div>
          <Footer />
        </div> */}
      </Router>
    </>
  );
};

export default Routes;
