import { FunctionComponent, useEffect, useState } from "react";
import {
  Select,
  Checkbox,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import Header from "../Layout/header";
//import Footer from "./Footer";
import Footer from "./Submitbuttom"
import {
  Fetch_Questions_Data_Api,
  Send_Answers_Data,
} from "../common/services/onBoarding";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface QuestionData {
  id: number;
  question: string;
  ques_type: string;
  ques_number: number;
  answer_type: string;
  ans_options: [];
  industry_vertical_id: number;
}

interface FormData {
  question_id: number;
  answer: any;
}

// interface PostData {
//   answers: FormData;
// }

const Questions: FunctionComponent = () => {
  // const [value, setValue] = useState("placeholder");
  const [isSubmit, setIsSubmit] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const [formData, setFormData] = useState<FormData[]>([]);
  const [validationData, setValidationData] = useState<any[]>([]);
  const [isValid, setIsValid] = useState<{ [key: string]: any }>({});
  const [questionsData, setQuestionsData] = useState<QuestionData[]>([]);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const navigate=useNavigate();

  const onboardingStatus = useSelector(
    (state: any) => state.auth.login?.onboarding_status
  );

  function handleValidation(data: any) {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    for (let item of questionsData) {
      const currentData = formData?.find((answer: any) => answer?.question_id === item.id);

      console.log(currentData)
      if(!currentData){
        updated[`question_${item.id}`] = true;
      }else if(!currentData?.answer){
        updated[`question_${item.id}`] = true;
      }else if(currentData?.answer && typeof(currentData?.answer) === "string" && currentData?.answer?.trim() === ""){
        updated[`question_${item.id}`] = true;
      }else if(typeof(currentData?.answer) !== "string" && typeof(currentData?.answer) !== "number" && currentData?.answer?.includes(-1)){
        updated[`question_${item.id}`] = true;
      }else{
        updated[`question_${item.id}`] = false;
      }
      // console.log(typeof(data.get(`question_${item.id}`)), `question_${item.id}`, data.get(`question_${item.id}`));
      // updated[`question_${item.id}`] = !data.get(`question_${item.id}`);
    }

    setIsValid(updated);

    return updated;
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    updated[`${item}`] = false;

    setIsValid(updated);
  }

  console.log(formData,"form------>>>>>>>>>>>");

  const handleChange = (
    event: any,
    questionId: number,
    answer_type: string
  ) => {
    let { value } = event.target as { value: any };

    if (answer_type === "radio") {
      value = Number(value);
    }
    if(answer_type === "dropdown" && value.length){
      value = value?.filter((item: any) => item !== -1);
    }else if(answer_type === "dropdown" && !value.length){
      value = [-1];
    }
    setFormData((prevData) => {
      // Check if a response for this question already exists in the formData array
      const existingIndex = prevData.findIndex(
        (item) => item.question_id === questionId
      );
      if (existingIndex !== -1) {
        // If yes, update the existing response
        return prevData.map((item, index) => {
          if (index === existingIndex) {
            return { ...item, answer: [value] };
          }
          return item;
        });
      } else {
        // If no, add a new response to the formData array
        return [...prevData, { question_id: questionId, answer: [value] }];
      }
    });

    validateInput(value, questionId);
  };

  const validateInput = (value: any, questionId: number) => {
    //* Performing validation here
    //* For demonstration, I'm just checking if the value is empty
    if (typeof value === "string" && value.trim() === "") {
      setValidationMessages((prevMessages) => [
        ...prevMessages.filter((msg) => !msg.includes(questionId.toString())),
        `Question ${questionId} cannot be empty.`,
      ]);
    } else if (value.length) {
      setValidationMessages((prevMessages) => [
        ...prevMessages.filter((msg) => !msg.includes(questionId.toString())),
        `Question ${questionId} cannot be empty.`,
      ]);
    } else {
      setValidationMessages((prevMessages) =>
        prevMessages.filter((msg) => !msg.includes(questionId.toString()))
      );
    }
  };

  const industry_vertical_id_signup: number = useSelector(
    (state: any) =>
      state?.signup?.details?.organisation_details?.industry_vertical
  );
  const industry_vertical_id_login: number = useSelector(
    (state: any) => state?.auth?.login?.login_details?.industry_vertical
  );

  let industry_vertical_id = -1;

  if (industry_vertical_id_signup) {
    industry_vertical_id = industry_vertical_id_signup;
  } else {
    industry_vertical_id = industry_vertical_id_login;
  }
  console.log(questionsData, "abc");

  //! fetching questions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Fetch_Questions_Data_Api(industry_vertical_id);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setQuestionsData(data?.result?.rows);
        setQuestionCount(data?.result?.count);

        setValidationData(
          data?.result?.rows.map((item: any) => `question_${item.id}`)
        );

        setFormData(
          data?.result?.rows
            .filter((item: any) => item?.answer_type === "dropdown")
            ?.map((dropdownElements: any) => {
              return {
                question_id: dropdownElements?.id,
                answer: [-1],
              };
            })
        );
      } catch (error) {
        console.error("Error fetching industry vertical data:", error);
      }
    };

    if (industry_vertical_id !== -1) {
      fetchData();
    }
  }, [industry_vertical_id]);


  // const handleSubmit=()=>{
  //   navigate("/about-go-ems")
  // }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const updated = handleValidation(data);

    for (let item in updated) {
      if (updated[item]) {
        return;
      }
    }

    try {
      const response = await Send_Answers_Data(formData);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success === true) {
        console.log("check");
        
        toast.success("Submitted Successfully!");
        setIsSubmit(true);
      }
    } catch (error) {
      console.error("Error fetching industry vertical data:", error);
    }
  }

  return (
    <>
      {/* <Header /> */}
      <main className="self-stretch flex flex-col items-start justify-center pt-[0rem] px-[1.25rem] pb-[15.75rem] box-border max-w-full shrink-0 lg:pb-[6.688rem] lg:box-border mq750:pb-[2.813rem] mq750:box-border mq1050:pb-[4.375rem] mq1050:box-border">
        <div className="w-auto h-[84px] pl-[37px] pt-[25px] bg-neutral-50 flex-col justify-start items-start inline-flex">
          <div className="text-zinc-900 text-xl font-semibold font-['Poppins']">
            ON-BOARDING QUESTIONNAIRE
          </div>
        </div>
        <div className="flex flex-row items-center justify-center w-full">
          <Box component="form" id="questionForm" onSubmit={handleSubmit}>
            <div className="inline-flex flex-col items-start gap-[50px] relative">
              <div className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]">
                {questionsData?.map((item) => {
                  if (item.ques_type === "ONBOARDING") {
                    if (item.answer_type === "text") {
                      return (
                        <div
                          key={item.id}
                          className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]"
                        >
                          <div className="flex flex-col w-[886px] items-start px-[30px] py-[36px] relative flex-[0_0_auto] bg-white rounded-[8px] overflow-hidden border border-solid border-[#c0cde0] shadow-[0px_10px_24.9px_#0000000d]">
                            <div className="inline-flex flex-col items-start gap-[46px] relative flex-[0_0_auto]">
                              <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                <div className="relative w-fit mt-[-1.00px] text-zinc-900 text-base font-semibold font-['Poppins']">
                                  {item.ques_number}. {item.question}
                                </div>
                              </div>
                              <div className="inline-flex flex-col items-start gap-[31px] relative flex-[0_0_auto]">
                                <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                  <FormControl fullWidth>
                                    <TextField
                                      key={item.id}
                                      sx={{
                                        "& .MuiInput-root::before": {
                                          border: "none !important",
                                        },
                                        "& .MuiInput-underline:hover:after": {
                                          border: "none",
                                        },
                                      }}
                                      value={
                                        formData.find(
                                          (data) => data.question_id === item.id
                                        )?.answer || ""
                                      }
                                      id={`${item.id}`}
                                      name={`question_${item.id}`}
                                      error={isValid[`question_${item.id}`]}
                                      helperText={
                                        isValid[`question_${item.id}`]
                                          ? "This field is required!"
                                          : ""
                                      }
                                      className="relative w-[791px] h-[50.14px] bg-white rounded-[12px] border border-solid border-[#c0cde0] text-neutral-500 text-base font-normal font-['Poppins'] p-[19px]"
                                      placeholder="Write here"
                                      onChange={(event) => {
                                        handleChange(
                                          event,
                                          item.id,
                                          item.answer_type
                                        );
                                        handleIsValidItem(
                                          `question_${item.id}`
                                        );
                                      }}
                                      FormHelperTextProps={{
                                        style: {
                                          color: "red",
                                          fontSize: "12px",
                                          marginLeft: "0px",
                                        },
                                      }}
                                    />
                                    {/* {validationMessages.map((msg, index) => (
                                      <FormHelperText key={index}>
                                        {msg.includes(item.id.toString()) && msg}
                                      </FormHelperText>
                                    ))} */}
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else if (item.answer_type === "dropdown") {
                      return (
                        <div
                          key={item.id}
                          className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]"
                        >
                          <div className="flex flex-col w-[886px] items-start px-[30px] py-[36px] relative flex-[0_0_auto] bg-white rounded-[8px] overflow-hidden border border-solid border-[#c0cde0] shadow-[0px_10px_24.9px_#0000000d]">
                            <div className="inline-flex flex-col items-start gap-[46px] relative flex-[0_0_auto]">
                              <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                <p className="relative w-fit mt-[-1.00px] text-zinc-900 text-base font-semibold font-['Poppins']">
                                  {item.ques_number}. {item.question}
                                </p>
                              </div>
                              <div className="inline-flex flex-col items-start gap-[31px] relative flex-[0_0_auto]">
                                <FormControl
                                  fullWidth
                                  // error={validationMessages.some((msg) =>
                                  //   msg.includes(item.id.toString())
                                  // )}
                                >
                                  {/* <Select
                                    multiple
                                    labelId={item.id.toString()}
                                    key={item.id}
                                    value={
                                      formData.find(
                                        (data) => data.question_id === item.id
                                      )?.answer || []
                                    }
                                    id={`${item.id}`}
                                    name={`question_${item.id}`}
                                    error={isValid[`question_${item.id}`]}
                                    onChange={(event) => {
                                      handleChange(
                                        event,
                                        item.id,
                                        item.answer_type
                                      );
                                      handleIsValidItem(`question_${item.id}`);
                                    }}
                                    renderValue={(selected: any) =>
                                      selected
                                        ?.map(
                                          (selectedItem: any) =>
                                            [
                                              ...item.ans_options,
                                              { id: -1, name: "Select" },
                                            ]?.find(
                                              (law: any) =>
                                                Number(law.id) ===
                                                Number(selectedItem)
                                            )?.name
                                        )
                                        .join(", ")
                                    }
                                    className="relative w-[625px] h-[52px] bg-white rounded-[8px] border border-solid border-[#c0cde0]"
                                    placeholder="Select"
                                  >
                                    <MenuItem disabled value={-1}>
                                      Select
                                    </MenuItem>
                                    
                                    {item?.ans_options?.map(
                                      (option: any, index) => (
                                        <MenuItem
                                          key={index}
                                          value={option?.id}
                                        >
                                          <Checkbox
                                            id={String(item?.id)}
                                            checked={formData
                                              ?.find(
                                                (data) =>
                                                  data.question_id === item.id
                                              )
                                              ?.answer?.includes(option.id)}
                                          />
                                          {option?.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select> */}

                                  {isValid[`question_${item.id}`] && (
                                    <FormHelperText
                                      error
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      This field is required!
                                    </FormHelperText>
                                  )}
                                  {/* {validationMessages.map((msg, index) => (
                                    <FormHelperText key={index}>
                                      {msg.includes(item.id.toString()) && msg}
                                    </FormHelperText>
                                  ))} */}
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else if (item.answer_type === "radio") {
                      return (
                        <div
                          key={item.id}
                          className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]"
                        >
                          <div className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]">
                            <div className="flex flex-col w-[886px] items-start px-[30px] py-[36px] relative flex-[0_0_auto] bg-white rounded-[8px] overflow-hidden border border-solid border-[#c0cde0] shadow-[0px_10px_24.9px_#0000000d]">
                              <div className="inline-flex flex-col items-start gap-[46px] relative flex-[0_0_auto]">
                                <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                  <p className="w-[788px] relative mt-[-1.00px] text-zinc-900 text-base font-semibold font-['Poppins']">
                                    {item.ques_number}. {item.question}
                                  </p>
                                </div>
                                <div className="items-start contents gap-[28px] relative flex-[0_0_auto]">
                                  <div className="inline-flex items-start gap-[17px] relative flex-[0_0_auto]">
                                    <FormControl
                                      // error={validationMessages.some((msg) =>
                                      //   msg.includes(item.id.toString())
                                      // )}
                                      error={isValid[`question_${item.id}`]}
                                    >
                                      <RadioGroup
                                        key={item.id}
                                        value={
                                          formData.find(
                                            (data: any) =>
                                              data.question_id === item.id
                                          )?.answer || ""
                                        }
                                        id={`${item.id}`}
                                        name={`question_${item.id}`}
                                        onChange={(event) => {
                                          handleChange(
                                            event,
                                            item.id,
                                            item.answer_type
                                          );
                                          handleIsValidItem(
                                            `question_${item.id}`
                                          );
                                        }}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                      >
                                        {/* {item.ans_options?.map(
                                          (option: any, index) => (
                                            <FormControlLabel
                                              key={index}
                                              value={option?.id}
                                              control={
                                                <Radio
                                                  size="medium"
                                                  sx={
                                                    isValid[
                                                      `question_${item.id}`
                                                    ]
                                                      ? {
                                                          color: "#DC2626",
                                                          "&.Mui-checked": {
                                                            color: "black",
                                                          },
                                                        }
                                                      : {
                                                          color: "black",
                                                          "&.Mui-checked": {
                                                            color: "black",
                                                          },
                                                        }
                                                  }
                                                />
                                              }
                                              label={option?.name}
                                            />
                                          )
                                        )} */}
                                      </RadioGroup>

                                      {isValid[`question_${item.id}`] && (
                                        <FormHelperText
                                          error
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          This field is required!
                                        </FormHelperText>
                                      )}
                                      {/* {validationMessages.map((msg, index) => (
                                        <FormHelperText key={index}>
                                          {msg.includes(item.id.toString()) &&
                                            msg}
                                        </FormHelperText>
                                      ))} */}
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
              <div className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]">
                  {/* <div className="relative w-fit mt-[-1.00px] text-zinc-900 text-base font-semibold font-['Poppins']">
                    Questions to determine Obligation:
                  </div> */}
                  <div className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]">
                    {questionsData?.map((item) => {
                      if (item.ques_type === "OBLIGATION") {
                        if (item.answer_type === "text") {
                          return (
                            <div
                              key={item.id}
                              className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]"
                            >
                              <div className="flex flex-col w-[886px] items-start px-[30px] py-[36px] relative flex-[0_0_auto] bg-white rounded-[8px] overflow-hidden border border-solid border-[#c0cde0] shadow-[0px_10px_24.9px_#0000000d]">
                                <div className="inline-flex flex-col items-start gap-[46px] relative flex-[0_0_auto]">
                                  <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                    <div className="relative w-fit mt-[-1.00px] text-zinc-900 text-base font-semibold font-['Poppins']">
                                      {item.ques_number}. {item.question}
                                    </div>
                                  </div>
                                  <div className="inline-flex flex-col items-start gap-[31px] relative flex-[0_0_auto]">
                                    <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                      <FormControl fullWidth>
                                        <TextField
                                          key={item.id}
                                          sx={{
                                            "& .MuiInput-root::before": {
                                              border: "none !important",
                                            },
                                            "& .MuiInput-underline:hover:after":
                                              {
                                                border: "none",
                                              },
                                          }}
                                          value={
                                            formData.find(
                                              (data) =>
                                                data.question_id === item.id
                                            )?.answer || ""
                                          }
                                          id={`${item.id}`}
                                          name={`question_${item.id}`}
                                          error={isValid[`question_${item.id}`]}
                                          helperText={
                                            isValid[`question_${item.id}`]
                                              ? "This field is required!"
                                              : ""
                                          }
                                          className="relative w-[791px] h-[50.14px] bg-white rounded-[12px] border border-solid border-[#c0cde0] text-neutral-500 text-base font-normal font-['Poppins'] p-[19px]"
                                          placeholder="Write here"
                                          onChange={(event) => {
                                            handleChange(
                                              event,
                                              item.id,
                                              item.answer_type
                                            );
                                            handleIsValidItem(
                                              `question_${item.id}`
                                            );
                                          }}
                                          FormHelperTextProps={{
                                            style: {
                                              color: "red",
                                              fontSize: "12px",
                                              marginLeft: "0px",
                                            },
                                          }}
                                        />
                                        {/* {validationMessages.map((msg, index) => (
                                      <FormHelperText key={index}>
                                        {msg.includes(item.id.toString()) && msg}
                                      </FormHelperText>
                                    ))} */}
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (item.answer_type === "dropdown") {
                          return (
                            <div
                              key={item.id}
                              className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]"
                            >
                              <div className="flex flex-col w-[886px] items-start px-[30px] py-[36px] relative flex-[0_0_auto] bg-white rounded-[8px] overflow-hidden border border-solid border-[#c0cde0] shadow-[0px_10px_24.9px_#0000000d]">
                                <div className="inline-flex flex-col items-start gap-[46px] relative flex-[0_0_auto]">
                                  <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                    <p className="relative w-fit mt-[-1.00px] text-zinc-900 text-base font-semibold font-['Poppins']">
                                      {item.ques_number}. {item.question}
                                    </p>
                                  </div>
                                  <div className="inline-flex flex-col items-start gap-[31px] relative flex-[0_0_auto]">
                                    <FormControl
                                      fullWidth
                                      // error={validationMessages.some((msg) =>
                                      //   msg.includes(item.id.toString())
                                      // )}
                                    >
                                      {/* <Select
                                        multiple
                                        labelId={item.id.toString()}
                                        key={item.id}
                                        value={
                                          formData.find(
                                            (data) =>
                                              data.question_id === item.id
                                          )?.answer || ""
                                        }
                                        id={`${item.id}`}
                                        name={`question_${item.id}`}
                                        error={isValid[`question_${item.id}`]}
                                        onChange={(event) => {
                                          handleChange(
                                            event,
                                            item.id,
                                            item.answer_type
                                          );
                                          handleIsValidItem(
                                            `question_${item.id}`
                                          );
                                        }}
                                        renderValue={(selected: any) =>
                                          selected
                                            ?.map(
                                              (selectedItem: any) =>
                                                [
                                                  ...item.ans_options,
                                                  { id: -1, name: "Select" },
                                                ]?.find(
                                                  (law: any) =>
                                                    Number(law.id) ===
                                                    Number(selectedItem)
                                                )?.name
                                            )
                                            .join(", ")
                                        }
                                        className="relative w-[625px] h-[52px] bg-white rounded-[8px] border border-solid border-[#c0cde0]"
                                        placeholder="Select"
                                      >
                                        <MenuItem disabled value={-1}>
                                          Select
                                        </MenuItem>
                                        {item.ans_options?.map(
                                          (option: any, index) => (
                                            <MenuItem
                                              key={index}
                                              value={option?.id}
                                            >
                                              <Checkbox
                                                id={String(item?.id)}
                                                checked={formData
                                                  ?.find(
                                                    (data) =>
                                                      data.question_id ===
                                                      item.id
                                                  )
                                                  ?.answer?.includes(option.id)}
                                              />
                                              {option?.name}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select> */}

                                      {isValid[`question_${item.id}`] && (
                                        <FormHelperText
                                          error
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          This field is required!
                                        </FormHelperText>
                                      )}
                                      {/* {validationMessages.map((msg, index) => (
                                    <FormHelperText key={index}>
                                      {msg.includes(item.id.toString()) && msg}
                                    </FormHelperText>
                                  ))} */}
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (item.answer_type === "radio") {
                          return (
                            <div
                              key={item.id}
                              className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]"
                            >
                              <div className="inline-flex flex-col items-start gap-[10px] relative flex-[0_0_auto]">
                                <div className="flex flex-col w-[886px] items-start px-[30px] py-[36px] relative flex-[0_0_auto] bg-white rounded-[8px] overflow-hidden border border-solid border-[#c0cde0] shadow-[0px_10px_24.9px_#0000000d]">
                                  <div className="inline-flex flex-col items-start gap-[46px] relative flex-[0_0_auto]">
                                    <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                                      <p className="w-[788px] relative mt-[-1.00px] text-zinc-900 text-base font-semibold font-['Poppins']">
                                        {item.ques_number}. {item.question}
                                      </p>
                                    </div>
                                    <div className="contents items-start gap-[28px] relative flex-[0_0_auto]">
                                      <div className="inline-flex items-start gap-[17px] relative flex-[0_0_auto]">
                                        <FormControl
                                          error={isValid[`question_${item.id}`]}
                                        >
                                          <RadioGroup
                                            key={item.id}
                                            value={
                                              formData.find(
                                                (data: any) =>
                                                  data.question_id === item.id
                                              )?.answer || ""
                                            }
                                            id={`${item.id}`}
                                            name={`question_${item.id}`}
                                            onChange={(event) => {
                                              handleChange(
                                                event,
                                                Number(item.id),
                                                item.answer_type
                                              );
                                              handleIsValidItem(
                                                `question_${item.id}`
                                              );
                                            }}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                          >
                                            {/* {item.ans_options?.map(
                                              (option: any, index) => {
                                                return (
                                                  <FormControlLabel
                                                    key={index}
                                                    value={option?.id}
                                                    control={
                                                      <Radio
                                                        size="medium"
                                                        sx={
                                                          isValid[
                                                            `question_${item.id}`
                                                          ]
                                                            ? {
                                                                color:
                                                                  "#DC2626",
                                                                "&.Mui-checked":
                                                                  {
                                                                    color:
                                                                      "black",
                                                                  },
                                                              }
                                                            : {
                                                                color: "black",
                                                                "&.Mui-checked":
                                                                  {
                                                                    color:
                                                                      "black",
                                                                  },
                                                              }
                                                        }
                                                      />
                                                    }
                                                    label={option?.name}
                                                  />
                                                );
                                              }
                                            )} */}
                                          </RadioGroup>

                                          {isValid[`question_${item.id}`] && (
                                            <FormHelperText
                                              error
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                                marginLeft: "0px",
                                              }}
                                            >
                                              This field is required!
                                            </FormHelperText>
                                          )}
                                          {/* {validationMessages.map((msg, index) => (
                                        <FormHelperText key={index}>
                                          {msg.includes(item.id.toString()) &&
                                            msg}
                                        </FormHelperText>
                                      ))} */}
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </main>
      {onboardingStatus !== "QUESTIONNAIRES_COMPLETED" && (
        <Footer
          validationMessages={validationMessages}
          formData={formData}
          questionCount={questionCount}
          isSubmit={isSubmit}
        />
      )}
    </>
  );
};

export default Questions;
