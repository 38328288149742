import React, { useEffect, useState } from "react";
import { fetchEditTask } from "../common/services/Project";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Receipt from "../ExpenseManagement/ImageView";
import ModalViewer from "../ExpenseManagement/ModalViewer";

interface TaskData {
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  start_date: string;
  end_date: string;
  status: string;
  project_id: string;
}

interface ApiResponse {
  rows: {
    id: number;
    expense_date: string;
    expense_amount: number;
    task_document: string[];
    receipt_filename: string[];
  }[];
}

const ViewTask = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [receipts, setReceipts] = useState<ApiResponse["rows"]>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [taskData, setTaskData] = useState<TaskData>({
    task_name: "",
    estimated_hours: "",
    actual_hours: "",
    start_date: "",
    end_date: "",
    status: "",
    project_id: "",
  });

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditTask(id)
      .then((data) => {
        // console.log(data.result.rows[0].User.Department.name, "data--res----edit expense--->>>>");
        if (data.result) {
          // setUpdateData(data);
          console.log(data.result, "data.result.state.iddata.result.state.id");

          const taskResources = data.result.TaskResources;

          const { task_document, receipt_name } = data.result;

          const files = task_document?.map((name: string, index: number) => ({
            //  originalName: name,
            url: task_document[index],
            // fileType: receipt_file_type[index],
          }));

          setTaskData({
            task_name: data.result?.task_name,
            estimated_hours: data.result?.estimated_hours,
            actual_hours: data.result?.actual_hours,
            start_date: data.result?.start_date,
            end_date: data.result?.end_date,
            status: data.result?.status,
            project_id: data.result?.project_id,
          });
          setReceipts([data?.result]);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  console.log(receipts, "receipts  task ----->>>>>>");

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  return (
    <div className="px-4 pt-6 pb-12">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button
            onClick={() =>
              navigate(`/edit-task-details/${taskData?.project_id}`, {
                state: { id: taskData?.project_id },
              })
            }
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Task Details
          </span>
        </div>
      </div>
      <div className="p-6 font-[Poppins] bg-white rounded-lg mt-6 shadow-md">
        <h2 className="text-lg font-semibold mb-4">Task Details</h2>
        <div className=" bg-[#FBFBFB] p-4">
          <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Task Name
              </strong>
              <br />{" "}
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.task_name}
              </strong>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Estimate Hours
              </strong>
              <br />{" "}
              <span className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.estimated_hours || "--"}
              </span>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Actual Hours
              </strong>
              <br />
              <span className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.actual_hours || "--"}{" "}
              </span>
            </p>
          </div>
          <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Start Date
              </strong>
              <br />{" "}
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {taskData.start_date}
              </strong>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                End Date
              </strong>
              <br />
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {" "}
                {taskData.end_date}
              </strong>
            </p>
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Status
              </strong>
              <br />
              <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                {"--"}
              </strong>
            </p>
          </div>

          <div className="grid grid-cols-1 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Description
              </strong>
              <br /> <strong className="p-1 text-[#1D1A22] font-[500] text-[16px]">{"--"}</strong>
            </p>
          </div>
          <div className="grid grid-cols-1 p-2 gap-4 border-b border-gray-200">
            <p>
              <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                Attachments
              </strong>
              <br />{" "}
              <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                {receipts?.map((receipt) =>
                  receipt?.task_document?.map((url, index) => (
                    <>
                      {" "}
                      {console.log(url, "url check---->>>>")}
                      <Receipt
                        key={index}
                        fileName={
                          receipt?.task_document[index]?.split("/")?.pop() ||
                          `Receipt ${index + 1}`
                        }
                        fileSize={`${(Math?.random() * 2 + 1)?.toFixed(2)} MB`} // Random file size for demonstration
                        onView={() => handleView(url)}
                      />
                    </>
                  ))
                )}
              </div>
              {selectedFileUrl && (
                <ModalViewer
                  isOpen={!!selectedFileUrl}
                  onRequestClose={closeModal}
                  fileUrl={selectedFileUrl}
                />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTask;
