import { useState } from "react";
import Footer from "../Layout/footer";
import CompanyTree from "./CompanyTree";
import CompanyView from "./CompanyView";

export default function CompanyStructure() {
  const [currentPage, setCurrentPage] = useState("tree");
  const [id, setId] = useState(0);

  function handleCurrentPageChange(value: string) {
    setCurrentPage(value);
  }

  function handleCustomerEdit(id: number) {
    setId(id);
  }

  return (
    <>
      {/* Your main content goes here */}
      {currentPage === "tree" && (
        <CompanyTree
          handleCurrentPageChange={handleCurrentPageChange}
          handleEdit={handleCustomerEdit}
        />
      )}
      {currentPage === "list" && (
        <CompanyView
          handleCurrentPageChange={handleCurrentPageChange}
          handleEdit={handleCustomerEdit}
        />
      )}
    </>
  );
}
