import { useCallback, useEffect, useState } from "react";
import styles from "./Footer.module.css";
import rightArrow from "../../assets/arrow-right.svg";
import { Send_Answers_Data } from "../common/services/onBoarding";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { COMPANY_STRUCTURE } from "../../utils/routeConstant";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authActions } from "../../redux/reducers/Auth/authSlice";

interface FormData {
  question_id: number;
  answer: string;
}

const Footer: React.FC<{
  formData: FormData[];
  validationMessages: string[];
  questionCount: number;
  isSubmit: boolean;
}> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginDetails = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );

  if (props.isSubmit) {
    dispatch(
      authActions.setLoginDetails([
        {
          ...loginDetails,
        },
        true,
        "QUESTIONNAIRES_COMPLETED",
      ])
    );
    navigate(`${COMPANY_STRUCTURE}`);
  }

  return (
    <div className={styles.frameParent}>
      <div className={styles.rectangleParent} />
      <button
        type="submit"
        form="questionForm"
        className={styles.rectangleGroup}
        // onClick={onSubmit}
      >
        <div className={styles.frameItem} />
        <div className={styles.vuesaxlineararrowRightParent}>
          <div className={styles.previous}>Submit</div>
        </div>
      </button>

      <button type="submit" form="questionForm" className="bg-blue-gray-900">
        submit
      </button>
    </div>
  );
};

export default Footer;
