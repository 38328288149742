import AddUser from "./AddUser";
import ViewUser from "./ViewUser";
import EditUser from "./EditUser";
import { useEffect, useState } from "react";
 
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import UserManagementTableLayout from "./UserManagementTableLayout";
import { userManagementActions } from "../../redux/reducers/userManagement/userManagementSlice";
import { fetchRoleList, fetchUserData } from "../../redux/actions/UserManagement/UserManagementActions";
import { ROLE_MANAGEMENT_ROLE_DETAILS, SELECTED_VIEW_GROUP_DETAILS } from "../../utils/routeConstant";

export default function UserManagement() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginData = useSelector((state: any) => state.auth.login.login_details);

    const isNextRoleData = useSelector((state: any) => state.userManagement.userRoleData.isNext);
    const numOfItems = useSelector((state: any) => state.userManagement.userRoleData.itemsPerPage);
    const currentPageRoleData = useSelector((state: any) => state.userManagement.userRoleData.currentPage);
 
    const currentPage = useSelector((state: any) => state.userManagement.currentPage);
    // const [currentPage, setCurrentPage] = useState("table");
    const [ previousPath, setPreviousPath ] = useState("table");
    const [ isLoading, setIsLoading ] = useState(false);
    const [labelId, setLabelId] = useState<number>();
  
    function handleCurrentPageChange(value: string) {
      if(previousPath === `${ROLE_MANAGEMENT_ROLE_DETAILS}` || previousPath === `${SELECTED_VIEW_GROUP_DETAILS}`){
        navigate(-1);
        setTimeout(() => {
          dispatch(userManagementActions.setCurrentPage("table"));
        }, 1000);
      }else if(previousPath === "viewUser"){
        dispatch(userManagementActions.setCurrentPage("table"));
      }{
        dispatch(userManagementActions.setCurrentPage(value));
        // setCurrentPage(value;)
      }
    }
 
    function handleUserIdChange(id: number) {
      setLabelId(id);
    }

    function handleChangePreviousPath(value: string){
      setPreviousPath(value);
    }

    useEffect(() => {
      dispatch(userManagementActions.setUserData({}));
      
      async function fetchData() {
        try {
          const resData = await dispatch<any>(fetchUserData(labelId));
  
          const userData = resData.result;
          dispatch(userManagementActions.setUserData(
            userData || {},
          ));
        } catch (error) {
          if (error instanceof Error) {
            // console.log("Fetching User data failed!");
          } else {
            // console.log('An unknown error occurred:', error);
          }
          setIsLoading(false);
        }
      }
  
      if (labelId !== undefined) {
        fetchData();
      }
    }, [labelId]);
  
    //for role management routing//
    const location = useLocation();

    useEffect(() => {
      if (location.state) {
        const state = location.state as { id: number; currentPage: string; previous: string; };
        dispatch(userManagementActions.setCurrentPage(state.currentPage));
        setLabelId(state.id);
        setPreviousPath(state.previous);
      }
    }, [location.state]);

    let customer_id = 0;
 
    if(loginData!==null){
      customer_id = loginData.customer_id;
    }
 
    useEffect(()=> {
      if(isNextRoleData){
        setIsLoading(true);
 
        const fetchData = async() => {
          let url = `/api/v1/role/list?page=${currentPageRoleData}&size=${numOfItems}&customer_id=${customer_id}`;
 
          try{
            const resData = await dispatch<any>(fetchRoleList(url));

            const userRoleData = resData.result;
 
            dispatch(userManagementActions.setUserRoleData(
                userRoleData || { "count": 0,"rows": [], "currentPage": 1, "itemsPerPage": 10, "isNext" : true,},
            ));
            setIsLoading(false);
          }catch(error){
              if (error instanceof Error) {
                  // console.log("Fetching User Role data failed!");
              } else {
                  // console.log('An unknown error occurred:', error);
                  // Handle other types of errors if needed
              }
              setIsLoading(false);
          }
        }
        fetchData();
      }
    }, [currentPageRoleData]);

    useEffect(() => {
      if (!location.state) {
        dispatch(userManagementActions.setCurrentPage("table"));
      }
    }, []);
 
  return (
    <div className="p-4 h-auto">
      {currentPage === "table" && (
        <UserManagementTableLayout
          handleChangePreviousPath={handleChangePreviousPath}
          handleCurrentPageChange={handleCurrentPageChange}
          handleUserIdChange={handleUserIdChange}
        />
      )}
      {currentPage === "addNew" && (
        <AddUser handleCurrentPageChange={handleCurrentPageChange} />
      )}
      {currentPage === "viewUser" && (
        <ViewUser 
          handleChangePreviousPath={handleChangePreviousPath}
          handleCurrentPageChange={handleCurrentPageChange}
          labelId={labelId}
        />
      )}
      {currentPage === "editUser" && (
        <EditUser
          handleCurrentPageChange={handleCurrentPageChange}
          labelId={labelId}
          previousPath={previousPath}
        />
      )}
    </div>
  );
}