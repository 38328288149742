import axios from "axios";
import { base_api, api_key_work as api_key} from "../../../utils/helperData";
import {
  FETCH_ROLE_MANAGEMENT_LIST,
  GET_ROLE_LIST,
  GET_ROLE_RESOURCE_LIST,
} from "../api";
import moment from "moment";

export const Role_Resource_List_Api = (
  customer_id: number,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?page=${page}&size=${numOfItems}&customer_id=${customer_id}`;
  return fetch(`${GET_ROLE_LIST}${query}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Role_Management_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  if (sortType != undefined) {
    query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  // &session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}
  return axios.get(`${FETCH_ROLE_MANAGEMENT_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Edit_Role = (id: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${GET_ROLE_LIST}/${id}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const get_Role_Resourse_Api = () => {
  const access_token = localStorage.getItem("access_token");
  let query = `?page=1&size=100&sort_by=createdAt&sort_order=ASC`;
  return axios.get(`${GET_ROLE_RESOURCE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

// editRoleanagement.tsx
export const fetchRoleDetails = (id: string) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${base_api}/api/v1/role/${id}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

//add role
export const createRole = async (
  roleName: string,
  customerId: number,
  resourceList: number[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    role_name: roleName,
    customer_id: customerId,
    resource_list: resourceList,
  };

  try {
    const response = await axios.post(`${base_api}/api/v1/role`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

//edit role
export const fetchEditRole = async (id: string) => {
  const access_token = localStorage.getItem("access_token");

  try {
    const response = await axios.get(`${base_api}/api/v1/role/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateRole = async (
  id: string,
  roleName: string,
  resourceChanges: { resource_id: number; type: string }[],
  status: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    role_name: roleName,
    resource_list: resourceChanges,
    status,
  };

  try {
    const response = await axios.put(`${base_api}/api/v1/role/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

//roleDetails

export const fetchRoleUsers = async (
  roleId: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let url = `${base_api}/api/v1/role/user?role_id=${roleId}&size=${numOfItems}&page=${page}`;

  try {
    const response = await axios.get(url, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchModalDataRoleDetail = async (
  customer_id: number,
  modalnumOfItems: number,
  modalpage: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");
  let modal_url = `${base_api}/api/v1/user/list?customer_id=${customer_id}&size=${modalnumOfItems}&page=${modalpage}`;

  if (searchTerm !== "") {
    modal_url += `&search=${searchTerm}`;
  }

  try {
    const response = await axios.get(modal_url, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const postRoleAssignment = async (
  checkedItems: any[],
  uncheckedItems: any[],
  roleId: string
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    check: checkedItems,
    uncheck: uncheckedItems,
    role_id: roleId,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/role/assign`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
