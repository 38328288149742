import rightArrow from "../../assets/chevron-right.svg";

import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MuiTelInput } from "mui-tel-input";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-tailwind/react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import Modal from "../UI/Modal";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CreateUser from "./ConfirmationModal/CreateUser";
import SelectGroupModal from "./SelectGroupModal/SelectGroup";
import { userManagementActions } from "../../redux/reducers/userManagement/userManagementSlice";
import {
  flattenGroupData,
  labelStyle,
  inputStyle,
  menuStyle,
} from "../../utils/helperData";
import {
  fetchUserData,
  fetchUserGroupAccessList,
  getUserRoleAccessList,
  sendUpdateUserData,
} from "../../redux/actions/UserManagement/UserManagementActions";
import { styled } from "@mui/system";
import RenderResources from "../common/ResourceList/Resources";

interface ViewUserProps {
  handleCurrentPageChange: (value: string) => void;
  labelId: number | undefined;
  previousPath: string;
}

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "91.5%",
    height: "100%",
  },
});

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    width: "85%",
    height: "100%",
  },
});

const validationData: any = ["firstName", "phone", "role"];

const validatePhoneNumber = (phoneNumber: string) => {
  // Remove any non-digit characters from the input
  const cleanedNumber = phoneNumber.toString();

  const regex = /^(\+?\d{1,3})?(\d{6,15})$/;

  if (cleanedNumber.match(regex)) {
    return true;
  } else {
    return false;
  }
};

const EditUser: React.FC<ViewUserProps> = ({
  handleCurrentPageChange,
  labelId,
  previousPath,
}) => {
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [formData, setFormData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState<Boolean>(false);
  const [groupModalIsOpen, setGroupModalIsOpen] =
    React.useState<Boolean>(false);

  let userData = useSelector((state: any) => state.userManagement.userData);
  const roleData = useSelector(
    (state: any) => state.userManagement.userRoleData.rows
  );
  const groupTreeData = useSelector(
    (state: any) => state.userManagement.userGroupData.rows
  );

  const [data, setData] = React.useState(userData);
  const [phone, setPhone] = React.useState(`${data.country_code}${data.phone}`);
  const [phoneInfo, setPhoneInfo] = React.useState({
    countryCallingCode: "",
    countryCode: "",
    nationalNumber: "",
  });

  const [userAccessList, setUserAccessList] = React.useState([]);
  const [role, setRole] = React.useState<any>({
    id: "",
    role_name: userData.role_name,
  });
  const [group, setGroup] = React.useState<any>({
    id: null,
    group_name: "",
    parent_id: null,
  });

  const groupData = flattenGroupData(groupTreeData);

  const [status, setStatus] = React.useState<unknown>(
    userData.status === "active"
      ? "active"
      : userData.status === "archived"
      ? "archived"
      : "inactive"
  );

  const [isValid, setIsValid] = React.useState<{ [key: string]: any }>({});

  function handleValidation(data: any) {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    updated["firstName"] = !data.get("firstName");
    updated["phone"] =
      phoneInfo.nationalNumber === ""
        ? 2
        : !validatePhoneNumber(
            phoneInfo.nationalNumber === "" ? phone : phoneInfo.nationalNumber
          )
        ? 1
        : 0;
    updated["role"] = (role.id ?? "") === "";

    setIsValid(updated);

    if (
      !data.get("firstName") ||
      !validatePhoneNumber(
        phoneInfo.nationalNumber === "" ? phone : phoneInfo.nationalNumber
      ) ||
      role.id === ""
    ) {
      return;
    }

    handleOpen();
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    if (item === "phone") {
      updated[`${item}`] = 0;
    } else {
      updated[`${item}`] = false;
    }

    setIsValid(updated);
  }

  function handleOpen() {
    setModalIsOpen(true);
  }

  function handleClose() {
    setModalIsOpen(false);
  }

  function handleGroupModalOpen() {
    setGroupModalIsOpen(true);
  }

  function handleGroupModaClose() {
    setGroupModalIsOpen(false);
  }

  function dummyParentFunction(event: any, id: any, parent_id: any, temp: any[]){

  }

  function dummyChildrenFunction(event: any, id: any, parent_id: any){

  }

  const handleChange = (newValue: any, info: any) => {
    setPhone(newValue);
    setData({
      ...data,
      phone: newValue,
    });
    setPhoneInfo({
      countryCallingCode: info.countryCallingCode,
      countryCode: info.countryCode,
      nationalNumber: info.nationalNumber,
    });

    handleIsValidItem("phone");
  };

  const handleRoleChange = (event: SelectChangeEvent<unknown>) => {
    setUserAccessList([]);
    const currentRole = roleData.find(
      (item: any) => item.id === event.target.value
    );
    setRole(currentRole);

    handleIsValidItem("role");
  };

  const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
    setStatus(event.target.value as unknown);
  };

  const handleGroupChange = (value: number) => {
    setGroup(groupData.find((item: any) => item.id === value));
    setGroupModalIsOpen(false);
  };

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // if (!data.get('firstName')){
    //   toast.error("Please fill First Name.");
    //   return;
    // }else if(!validatePhoneNumber(phoneInfo.nationalNumber === "" ? phone : phoneInfo.nationalNumber)){
    //   toast.error("Please fill correct phone number.");
    //   return;
    // }else if((role.id ?? "") === ""){
    //   toast.error("Please select any role.");
    //   return;
    // }

    handleValidation(data);

    if (
      !data.get("firstName") ||
      !validatePhoneNumber(
        phoneInfo.nationalNumber === "" ? phone : phoneInfo.nationalNumber
      ) ||
      role.id === ""
    ) {
      return;
    }

    if (phoneInfo) {
      setFormData({
        firstName: data.get("firstName") ?? "",
        lastName: data.get("lastName") ?? "",
        // email: data.get('email'),
        phone:
          phoneInfo.nationalNumber === "" ? phone : phoneInfo.nationalNumber,
        country_code: phoneInfo.countryCallingCode.includes("+")
          ? phoneInfo.countryCallingCode
          : "+" + phoneInfo.countryCallingCode,
        role_id: role.id ?? "",
        status: status,
        group_access: group ? [group.id] : [],
        // resource_list: data.getAll('resources').map((item) => Number(item))
        // group_access: data.getAll('group-access').map((item) => Number(item)),
      });
    }
  };

  async function handleSubmitForm() {
    setModalIsOpen(false);

    // if (!formData.firstName){
    //   toast.error("Please fill First Name.");
    //   return;
    // }else if(!validatePhoneNumber(formData.phone)){
    //   toast.error("Please fill correct phone number.");
    //   return;
    // }else if(formData.role_id === ""){
    //   toast.error("Please select any role.");
    //   return;
    // }

    try {
      toast.loading("Processing in...");
      const resData = await dispatch<any>(
        sendUpdateUserData(
          formData.firstName,
          formData.lastName,
          // formData.email,
          formData.phone,
          formData.country_code,
          formData.role_id,
          formData.status,
          formData.group_access,
          // formData.resource_list,
          data.id
        )
      );
      toast.dismiss();
      toast.success(<b>Process completed successfully</b>);
      handleCurrentPageChange("table");
    } catch (error) {
      if (error instanceof Error) {
        // console.log('Sending Update User data failed!');
        toast.dismiss();
        toast.error(error.message);
      } else {
        // console.log('An unknown error occurred:', error);
        // Handle other types of errors if needed
      }

      toast.dismiss();
      toast.error("Sending Update User data failed!");
    }
    setModalIsOpen(false);
  }

  React.useEffect(() => {
    const currentRole = roleData.find(
      (item: any) => item.id === userData.role_id
    );

    if (currentRole) {
      setRole(currentRole);
    }

    const fetchData = async () => {
      let url = `/api/v1/groups?customer_id=${customer_id}&page=${1}&size=${100}`;

      try {
        const resData = await dispatch<any>(fetchUserGroupAccessList(url));

        const userGroupData = resData.result;

        dispatch(userManagementActions.setUserGroupData(userGroupData || []));
      } catch (error) {
        if (error instanceof Error) {
          // console.log("Fetching User Role data failed!");
        } else {
          // console.log('An unknown error occurred:', error);
          // Handle other types of errors if needed
        }
      }
    };

    fetchData();
  }, [userData.role_id]);

  React.useEffect(() => {
    handleGroupChange(userData.group_access ? userData.group_access[0] : "");
  }, [userData.role_id]);

  const group_assigned = userData.group_access ? userData.group_access[0] : "";
  React.useEffect(() => {
    setData(userData);
    setPhone(`${userData.country_code}${userData.phone}`);
    setPhoneInfo({
      countryCallingCode: userData.country_code,
      countryCode: "",
      nationalNumber: userData.phone,
    });
    setStatus(userData.status ?? "inactive");
    handleGroupChange(group_assigned);
  }, [
    userData.firstName,
    userData.lastName,
    userData.phone,
    userData.country_code,
    userData.role_id,
    group_assigned,
    userData.status,
  ]);

  React.useEffect(() => {
    async function fetchResourceList() {
      setIsLoading(true);
      try {
        const data = await getUserRoleAccessList(role.id);
        if (data) {
          // setUserAccessList(
          //   data.result.UserRolePrivileges.map((item: any) => item.Resource)
          // );
          if(!data?.result?.resources){ 
            setUserAccessList([]);
          }else {
            setUserAccessList(data?.result?.resources);
          }
        } else {
          // console.error("Failed to fetch user role list.");
        }
      } catch (error) {
        // console.error("Error fetching user role list:", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (role.id) {
      fetchResourceList();
    }
    if (!role.id) {
      setUserAccessList([]);
    }
    // }, [role.id, userData.role_id]);
    // }, [userData.role_id]);
  }, [role.id]);
  // }, []);

  return (
    <>
      {modalIsOpen && (
        <Modal
          open={modalIsOpen}
          onClose={handleClose}
          cssClass="w-[23.19%] shrink-0 rounded-2xl bg-white shadow-[10px_20px_30px_0px_rgba(0,0,0,0.10)]"
        >
          <CreateUser
            onClose={handleClose}
            onConfirm={() => handleSubmitForm()}
            modalType="Update"
          />
        </Modal>
      )}
      {groupModalIsOpen && (
        <Modal
          open={groupModalIsOpen}
          onClose={handleGroupModaClose}
          cssClass="w-[23.5%] inline-flex p-5 flex-col items-start gap-2 rounded-[3px]"
        >
          <SelectGroupModal
            onClose={handleGroupModaClose}
            selectedGroupId={group ? group.id : null}
            handleGroupChange={handleGroupChange}
          />
        </Modal>
      )}
      <div className="bg-[#FBFBFB] py-10 px-[18.5px] h-full">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[26px] justify-center items-center">
            <button
              onClick={() => {
                handleCurrentPageChange(previousPath);
                // dispatch(userManagementActions.setUserData({}));
              }}
            >
              <img
                src={rightArrow}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit User
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              type="submit"
              form="updateUserForm"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg bg-custom-primary h-12"
              // onClick={handleOpen}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Save Changes
              </div>
            </button>
            <button
              onClick={() => {
                handleCurrentPageChange(previousPath);
                // dispatch(userManagementActions.setUserData({}));
              }}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12"
            >
              <div className="text-[#2C2C2C] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
          </div>
        </div>

        <div className="w-full mt-5">
          <div className="pt-[25px] pb-[50px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] bg-white">
            <Container component="main">
              <Box>
                <Box
                  component="form"
                  noValidate={false}
                  onSubmit={handleSubmit}
                  sx={{ mt: "0px" }}
                  id="updateUserForm"
                >
                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                    User Details
                  </div>

                  <Grid
                    container
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <Grid item style={{ width: "30%" }}>
                      <StyledTextField
                        autoComplete="given-name"
                        name="firstName"
                        // required
                        id="firstName"
                        label="First Name *"
                        className="w-full"
                        value={data.firstName}
                        onChange={(e) => {
                          setData({ ...data, firstName: e.target.value });
                          handleIsValidItem("firstName");
                        }}
                        error={isValid[`firstName`]}
                        helperText={
                          isValid[`firstName`] ? "This field is required!" : ""
                        }
                        placeholder="Enter First Name"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "30%" }}>
                      <StyledTextField
                        autoComplete="family-name"
                        name="lastName"
                        // required
                        id="lastName"
                        label="Last Name"
                        className="w-full"
                        value={data.lastName}
                        onChange={(e) => {
                          setData({ ...data, lastName: e.target.value });
                        }}
                        placeholder="Enter Last Name"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item style={{ width: "30%" }}>
                      <StyledTextField
                        autoComplete="email"
                        name="email"
                        // required
                        id="email"
                        label="Email *"
                        value={data.email}
                        // onChange={(e) => {
                        //   setData({...data, email: e.target.value})
                        // }}
                        placeholder="Enter Email"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        className="w-full"
                        InputProps={{
                          readOnly: true,
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                        tabIndex={-1}
                      />
                    </Grid>

                    <MuiTelInput
                      label="Phone"
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone Number"
                      // defaultCountry="IN"
                      focusOnSelectCountry
                      value={phone}
                      onChange={handleChange}
                      // required
                      className="w-[30%]"
                      error={isValid[`phone`]}
                      helperText={
                        isValid[`phone`] === 2
                          ? "This field is required!"
                          : isValid[`phone`] === 1
                          ? "Length must be of 8-16 characters long!"
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                    />

                    <FormControl style={{ width: "30%" }}>
                      <InputLabel
                        id="select-label"
                        shrink
                        sx={{
                          ...labelStyle,
                          color: isValid[`role`]
                            ? "#d32f2f"
                            : "rgba(0, 0, 0, 0.60)",
                        }}
                      >
                        Select Role
                      </InputLabel>
                      <StyledSelect
                        labelId="demo-simple-select-label"
                        id="selectRole"
                        error={isValid[`role`]}
                        sx={{
                          ...inputStyle,
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        }}
                        value={role.id ?? userData.role_name}
                        onChange={(e) => handleRoleChange(e)}
                        required
                        displayEmpty
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Select Role"
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: menuStyle,
                          },
                        }}
                      >
                        {roleData.map((role: any) => (
                          <MenuItem value={role.id} style={menuStyle}>
                            {role.role_name}
                          </MenuItem>
                        ))}
                      </StyledSelect>

                      {isValid[`role`] && (
                        <FormHelperText error>
                          This field is required!
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Grid
                      onClick={handleGroupModalOpen}
                      style={{ width: "30%" }}
                    >
                      <StyledTextField
                        autoComplete="group"
                        name="group-name"
                        // required
                        id="groupName"
                        className="w-full"
                        placeholder="Select"
                        label="Group Assigned"
                        value={group ? String(group.group_name) : ""}
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>

                    <FormControl style={{ width: "30%" }}>
                      <InputLabel
                        id="select-status-label"
                        shrink
                        sx={labelStyle}
                      >
                        Status
                      </InputLabel>
                      <StyledSelect
                        labelId="select-status-label"
                        id="status"
                        sx={{
                          ...inputStyle,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        value={String(status)}
                        onChange={(e) => handleStatusChange(e)}
                        displayEmpty
                        input={
                          <OutlinedInput id="select-chip" label="Status" />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: menuStyle,
                          },
                        }}
                      >
                        <MenuItem value="active" style={menuStyle}>
                          <div className="flex flex-row justify-between items-center w-full h-full">
                            <span className="">Active</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <circle
                                cx="6.5"
                                cy="6.34375"
                                r="6"
                                fill="#00CBA0"
                              />
                            </svg>
                          </div>
                        </MenuItem>
                        <MenuItem value="archived" style={menuStyle}>
                          <div className="flex flex-row justify-between items-center w-full h-full">
                            <span className="">Archived</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <circle
                                cx="6.5"
                                cy="6.34375"
                                r="6"
                                fill="#FA1464"
                              />
                            </svg>
                          </div>
                        </MenuItem>
                        <MenuItem value="inactive" style={menuStyle}>
                          <div className="flex flex-row justify-between items-center w-full h-full">
                            <span className="">Inactive</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <circle
                                cx="6.5"
                                cy="6.34375"
                                r="6"
                                fill="#FF9950"
                              />
                            </svg>
                          </div>
                        </MenuItem>
                      </StyledSelect>
                    </FormControl>
                  </Grid>

                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[30px] mb-[41px]">
                    Accesses given to user
                  </div>

                  {isLoading && userAccessList?.length === 0 && (
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  )}

                  {!isLoading && userAccessList?.length === 0 && (
                    <p className="flex text-black items-center justify-center text-base">
                      Accesses Not Found!
                    </p>
                  )}

                  <RenderResources
                    renderData={userAccessList}
                    isChecked={true}
                    cssClass="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0"
                    resourceList={[]}
                    handleResourceListChildren={dummyChildrenFunction}
                    handleResourceListParent={dummyParentFunction}
                  />
                </Box>
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
