import React, { useCallback, ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Chip } from "@material-ui/core";
import { debounce } from "lodash";

import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import view from "../../assets/Crud_Icons/view.svg";
import profile from "../../assets/Ellipse 580.png";

import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import TablePaginationDemo from "../common/Pagenation";

function AssignedAsset() {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      // setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  return (
    <div className="pt-12">
      <div className="flex flex-col gap-5">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee Name"
            />
            <div className="h-full flex items-center gap-5">
              {/* <div className="h-full flex items-center gap-2">
                
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div> */}
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                // onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text">
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-auto shadow-sm">
          <table className="w-full min-w-max text-left table-auto bg-white">
            <thead className="rounded-t-[3px] border border-[#E0E0E0]">
              <tr className="h-11 w-full">
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                  Asset ID
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                  Category
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                  Asset Name
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-3">
                  Assigned to
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                  Assigned Date
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-5 flex justify-end">
                  Action
                </th>
              </tr>
            </thead>
            {/* {tableData?.map((item) => ( */}
            <tbody className="border border-[#EBEBEB]">
              <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-5">
                  ASD001
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                  Laptop
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                  Mac Mini
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                  <div className="h-[40px] flex items-center gap-2.5">
                    <div className="h-full w-[40px] rounded-full">
                      <img
                        src={profile}
                        alt="profile"
                        className="h-full w-full rounded-full"
                      />
                    </div>
                    <div className="h-full flex flex-col justify-center">
                      <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                        Brooklyn Simmons
                      </span>
                      <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                        EMP0062
                      </span>
                    </div>
                  </div>
                </td>
                <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                  23/02/2024
                </td>
                <td className="pr-5">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      // onClick={() =>
                      //   navigate(`/edit-announcement`, {
                      //     state: { id: item.id },
                      //   })
                      // }
                      onClick={() => navigate("/view-asset")}
                      src={view}
                      alt="View"
                      />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      // onClick={() =>
                      //   navigate(`/edit-announcement`, {
                        //     state: { id: item.id },
                        //   })
                        // }
                        onClick={() => navigate("/edit-asset")}
                      src={edit}
                      alt="Edit"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
            {/* ))} */}
          </table>
          {/* {tableData.length === 0 ? (
            <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
              Data not Found!
            </div>
          ) : (
            <></>
          )} */}
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
    </div>
  );
}

export default AssignedAsset;
