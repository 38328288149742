import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_ATTENDENCE_LIST } from "../api";
import { Dayjs } from "dayjs";
import moment from "moment";

export const Fetch_Attendence_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  //sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(
    //`${FETCH_ATTENDENCE_LIST}${query}&session_start=2024-04-01&session_end=2025-03-31`,
    `${FETCH_ATTENDENCE_LIST}${query}&session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Attendence_List1 = (
  customer_id: number,
  
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  return axios.get(
    //`${FETCH_ATTENDENCE_LIST}${query}&session_start=2024-04-01&session_end=2025-03-31`,
    `${FETCH_ATTENDENCE_LIST}${query}&session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Edit_Attendence_List = (customer_id: number, id: number) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  return axios.get(
    `${FETCH_ATTENDENCE_LIST}${query}&session_start=2024-04-01&session_end=2025-03-31&` +
      id,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Edit_Attendence_List_List = (id: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", id);
  console.log("Constructed URL:", `${FETCH_ATTENDENCE_LIST}`);
  return fetch(
    `${FETCH_ATTENDENCE_LIST}/${id}`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const createAttendence = async (
  // designation: string,
  user_id: string | null,
  status: string,
  end_time: Dayjs | null,
  start_time: Dayjs | null,
  date: string | undefined
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    //name: designation,
    user_id: user_id,
    status: status,
    end_time: end_time,
    start_time: start_time,
    date: date,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/checkin-checkout`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateAttendence = async (
  id: number,
  start_time: string | null | Dayjs,
  end_time: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    start_time: start_time,
    end_time: end_time,
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/attendance/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
