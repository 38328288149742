import { Checkbox } from "@material-tailwind/react";

interface RenderChildrenrProps {
    children: any[];
    selectedId: any[];
    handleCheckboxChange: (value: number) => void;
    depth: number;
}

const RenderChildren: React.FC<RenderChildrenrProps> = ({children, selectedId, handleCheckboxChange, depth}) => {
    if (!children || children.length === 0) {
        return null;
    }

    // Calculate left padding based on depth level
    // const paddingLeft = `pl-[${10 + 20*depth}px]`;
    const paddingLeft = 10 + 20*depth;

    // let paddingLeft = "";
    // if(depth === 1){
    //     paddingLeft = "p-9"
    // }else if(depth === 2){
    //     paddingLeft = "p-14"
    // }else if(depth === 3){
    //     paddingLeft = "p-18"
    // }else if(depth === 4){
    //     paddingLeft = "p-24"
    // }else if(depth === 5){
    //     paddingLeft = "p-32"
    // }
    
    // Dynamically construct the class name with calculated left padding
    const cssClass = `flex py-2.5 pr-6 h-[26px] items-center gap-2.5 self-stretch border border-solid border-[#E5E5E5]`;

    return (
        <div>
            {children.map((child: any) => (
                <div key={child.id}>
                    <div className={cssClass} style={{ paddingLeft }}>
                        <Checkbox
                            ripple={false}
                            crossOrigin=""
                            value={child.id}
                            id={child.id}
                            name="group-assigned"
                            checked={selectedId[0] === child.id}
                            onChange={() => handleCheckboxChange(child.id)}
                            containerProps={{
                                className: "p-0",
                            }}
                            className="h-4 w-4 rounded-md shrink-0 border-[#E5E5E5] bg-[#E5E5E5] checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}  />
                        <label
                            htmlFor={child.id}
                            className="inline-flex shrink-0 text-[#262626] font-[Poppins] text-[12px] font-medium leading-[13.981px]"
                        >
                            {child.group_name}
                        </label>
                    </div>
                    <RenderChildren children={child.children} selectedId={selectedId} handleCheckboxChange={handleCheckboxChange} depth={depth+1}/>
                </div>
            ))}
        </div>
    );
}

export default RenderChildren;
