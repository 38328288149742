import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_EXPENSE_TYPE_LIST } from "../api";

export const Fetch_Expense_Type_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(`${FETCH_EXPENSE_TYPE_LIST}${query}&session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createExpenseType = async (
  expense_category_name: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    expense_category_name: expense_category_name,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/expenses-category`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Edit_Expense_Type = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${FETCH_EXPENSE_TYPE_LIST}`);
  return fetch(`${FETCH_EXPENSE_TYPE_LIST}?customer_id=${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const updateExpenseType = async (
  id: number,
  expense_category_name: string,
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    expense_category_name: expense_category_name,
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/expenses-category/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
