import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink, useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { loginUser } from "../../redux/actions/authActions";
import { authActions } from "../../redux/reducers/Auth/authSlice";
import Header from "../common/header";
import Icon from "../../assets/tick.svg";
import bgimg from "../../assets/18@2x.png";
import logo from "../../image/MicrosoftTeams-image (3).png";
import { useSelector } from "react-redux";
import {
  ABOUT_GOTRUST,
  CANNOT_LOGIN,
  HOME,
  LOGIN,
  ONBOARDING_QUESTIONS,
  PRIVACY_POLICY_CREATE_POLICY,
  SIGNUP,
} from "../../utils/routeConstant";

// import styles from "./LoginScreen.module.css";

const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let correctDetails: boolean = true;

  // const handleLogin = () => {
  //   navigate("/about-go-ems");
  // };

  const handleLogin = async () => {
    // Validation: Check if username and password are provided
    toast.dismiss();
    if (!email.trim() && !password.trim()) {
      correctDetails = false;
      toast.error("Please provide both email and password.");
      return;
    }
    if (!isValidEmail(email)) {
      correctDetails = false;
      toast.error("Please provide a valid email address");
      return;
    }
    if (!password.trim()) {
      correctDetails = false;
      toast.error("Please provide password.");
      return;
    }

    // Dispatch login action

    if (correctDetails) {
      setIsSigningIn(true);
      try {
        toast.loading("Logging in...");
        const userData = await dispatch<any>(loginUser(email, password));
        console.log(userData.token, "userData");

        // Dispatch action to save userData in Redux
        if (userData) {
          localStorage.setItem("access_token", userData.token);
          dispatch(
            authActions.setLoginDetails([
              {
                email,
                phone: userData.phone,
                first_name: userData.firstName,
                last_name: userData.lastName,
                token: userData.token,
                customer_id: userData.customer_id,
                role: userData.role,
                userId: userData.id,
                industry_vertical: userData?.customerData?.industry_vertical,
                profile_image: userData?.profile_image,
              },
              true,
              userData.onboarding_status,
            ])
          );
        }
        if (userData) {
          toast.dismiss();

          const onboardingStatus = userData.onboarding_status;

          if (onboardingStatus !== "") {
            if (onboardingStatus === "PASSWORD_UPDATED") {
               navigate("/question")
             // navigate("/about");
            } 
            // else if (id) {
            //   navigate("/about");
            //   //navigate(`${LOGIN}/${id}`);
            // } 
            else {
              navigate("/about");
              //navigate(`${HOME}`);
            }
          }
        }
      } catch (error: any) {
        toast.dismiss();
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSigningIn(false);
      }
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="Mainbg ">
      <div className="flex">
        {/* <img
        className="absolute top-[0px] left-[81px] w-[50%] h-[1773.5px] opacity-25 object-contain"
        alt=""
        src={bgimg}
      />
       <div className="absolute top-[182.94px] w-[50%] left-[-463.1px] rounded-[152px] [background:linear-gradient(149.76deg,_#000,_#8841e3)] h-[983.5px] [transform:_rotate(-29.1deg)] [transform-origin:0_0] z-[1]" /> */}
      </div>
      <div className="flex justify-center gap-[20%] h-screen">
        <div className="w-[29.3%] h-[15%] ml-[2.5%] mt-[10%] flex-col justify-start gap-5 items-start inline-flex text-white">
          <section className="flex-col justify-start items-start flex w-[27rem] h-[15rem]">
            <div className="mb-6 w-full">
              <div className="flex flex-row items-center justify-start gap-[12.2px] shrink-0 [debug_commit:69da668] z-[2]">
                <img
                  className="logocss relative min-h-[61px]"
                  loading="lazy"
                  alt=""
                  src={logo}
                />
                <div className="flex flex-col items-start justify-center pt-[18px] px-0 pb-[17.9px] gap-[3.7px]">
                  {/* <div className="flex flex-row items-end justify-start">
                    <b className="relative leading-[57%] font-semibold mq800:text-[28px] mq800:leading-[16px] mq450:text-[21px] mq450:leading-[12px]">
                      GO-EMS
                    </b>
                  </div> */}
                  <div className="w-[95px] relative text-[14.6px] tracking-[0.09em] capitalize text-gainsboro hidden">
                    trust to go
                  </div>
                </div>
              </div>
              <div className="text-white text-4xl font-semibold font-['Poppins'] whitespace-nowrap h-[3rem]">
                Welcome to GO-EMS
              </div>
              <div className="text-white text-base font-normal font-['Inter'] uppercase leading-normal">
              Employee Management System
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-4 flex w-[24rem] h-36">
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Spectacular reduction of compliance costs
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Regulatory framework proof
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  Unmatched easy of mind
                </div>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4 md:w-6 md:h-6" src={Icon} alt="Tick" />
                <div className="text-white text-sm font-normal font-['Inter'] leading-[14px]">
                  One-click certification per the highest EU standards
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <section className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
          <img
            className="absolute top-[-178px] left-[81px] w-[1864.7px] h-[1773.5px] object-contain"
            alt=""
            src={bgimg}
          />
          <div className="absolute top-[182.94px] left-[-463.1px] rounded-[152px] [background:linear-gradient(149.76deg,_#000,_#8841e3)] w-[959.9px] h-[983.5px] [transform:_rotate(-29.1deg)] [transform-origin:0_0] z-[1]" />
        </section> */}
        <div className=" bg-white flex flex-col items-center justify-center my-auto rounded-xl">
        {/* <div className="w-[21.3rem] h-[23.75rem] bg-white flex flex-col items-center ml-[24%] mr-[15%] mt-[3.9%] mb-[13rem] rounded-xl"> */}
          <div className="text-neutral-800 text-3xl font-semibold font-['Poppins'] mt-[1.75rem] mb-[1.6rem]">
            Log In
          </div>
          <form className="flex flex-col">
            <label
              className="text-neutral-800 text-xs font-semibold font-['Poppins'] mt-2 mb-[4px] ml-5 text-left"
              htmlFor="email"
            >
              Email
            </label>
            <div className="flex justify-center">
              <input
                className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] ml-5 mr-[20px] px-2"
                id="email"
                type="email"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <label
              className="text-neutral-800 text-xs font-semibold font-['Poppins'] mt-[0.87rem] mb-[4px] ml-5"
              htmlFor="password"
            >
              Password
            </label>
            <div className="flex justify-center">
              <div className="relative">
                <input
                  className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] px-2"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {showPassword ? (
                  <svg
                    className="absolute top-0 right-0 mt-2 mr-3 w-4 h-4 md:w-6 md:h-6 cursor-pointer"
                    onClick={togglePasswordVisibility}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="vuesax/bold/eye">
                      <path
                        id="Vector"
                        d="M21.25 9.15018C18.94 5.52017 15.56 3.43018 12 3.43018C10.22 3.43018 8.49 3.95018 6.91 4.92018C5.33 5.90018 3.91 7.33017 2.75 9.15018C1.75 10.7202 1.75 13.2702 2.75 14.8402C5.06 18.4802 8.44 20.5602 12 20.5602C13.78 20.5602 15.51 20.0402 17.09 19.0702C18.67 18.0902 20.09 16.6602 21.25 14.8402C22.25 13.2802 22.25 10.7202 21.25 9.15018ZM12 16.0402C9.76 16.0402 7.96 14.2302 7.96 12.0002C7.96 9.77018 9.76 7.96018 12 7.96018C14.24 7.96018 16.04 9.77018 16.04 12.0002C16.04 14.2302 14.24 16.0402 12 16.0402Z"
                        fill="#262626"
                      />
                      <path
                        id="Vector_2"
                        d="M12 9.14014C10.43 9.14014 9.15002 10.4201 9.15002 12.0001C9.15002 13.5701 10.43 14.8501 12 14.8501C13.57 14.8501 14.86 13.5701 14.86 12.0001C14.86 10.4301 13.57 9.14014 12 9.14014Z"
                        fill="#262626"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    className="absolute top-0 right-0 mt-2 mr-3 w-4 h-4 md:w-6 md:h-6 cursor-pointer"
                    onClick={togglePasswordVisibility}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.83 9L15 12.16V12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9H11.83ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.77 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 11.21 7.2 10.47 7.53 9.8ZM2 4.27L4.28 6.55L4.73 7C3.08 8.3 1.78 10 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.81 19.08L19.73 22L21 20.73L3.27 3M12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 12.64 16.87 13.26 16.64 13.82L19.57 16.75C21.07 15.5 22.27 13.86 23 12C21.27 7.61 17 4.5 12 4.5C10.6 4.5 9.26 4.75 8 5.2L10.17 7.35C10.74 7.13 11.35 7 12 7Z"
                      fill="black"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div className="flex justify-end mr-6 mt-3 ">
              <NavLink
                to={"/forget-password"}
                className="text-blue-600 text-xs font-medium font-['Poppins'] underline ml-[4rem]"
              >
                Forget Password?
              </NavLink>
            </div>
            <div className="flex justify-center mt-4">
              <button
                className="buttoncss text-white w-[18.75rem] h-[2.5rem] rounded-[8px] text-sm font-semibold font-['Poppins']"
                type="button"
                onClick={handleLogin}
                disabled={isSigningIn}
              >
                Log In
              </button>
            </div>
            {/* <div className="flex justify-center font-[400] text-[#605D66] text-[14px] mt-[.5rem]">or</div> */}
            <div className="mt-[.5rem] justify-center flex mb-6 flex-col md:flex-row gap-[4px]">
              <div className="mb-4 md:mb-0">
                <NavLink
                  to={""}
                  className="text-[#000000] decoration-none text-xs font- font-['Poppins']"
                >
                  New User ?
                </NavLink>
              </div>
              <div>
                <NavLink
                  to={"/signup"}
                  className="text-[#679BFF] text-xs font-medium font-['Poppins'] underline"
                >
                  <strong>Create an account</strong>
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
