import { useState } from "react";
import CustomerManagementTable from "./CustomerManagementTable";
import AddCustomer from "./AddCustomer";
import Sidebar from "../Layout/Sidebar";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import EditCustomer from "./EditCustomer";
import ViewCustomer from "./ViewCustomer";

export default function CustomerManagement() {
  const [currentPage, setCurrentPage] = useState("table");
  const [id, setId] = useState(0);
  function handleCurrentPageChange(value: string, event: any) {
    event?.stopPropagation();
    setCurrentPage(value);
  }

  function handleCustomerEdit(id: number) {
    setId(id);
  }

  return (
    <div className="flex flex-col h-[calc(100% - 100px)] bg-gray-100">
      <div className="flex-grow">
        {/* Your main content goes here */}
        {currentPage === "table" && (
          <CustomerManagementTable
            handleCurrentPageChange={handleCurrentPageChange}
            handleEdit={handleCustomerEdit}
          />
        )}
        {currentPage === "addNew" && (
          <AddCustomer handleCurrentPageChange={handleCurrentPageChange} />
        )}
        {currentPage === "edit" && (
          <EditCustomer
            handleCurrentPageChange={handleCurrentPageChange}
            id={id}
          />
        )}
        {currentPage === "viewCustomer" && (
          <ViewCustomer
            handleCurrentPageChange={handleCurrentPageChange}
            id={id}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}
