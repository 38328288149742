import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";

import {
  Fetch_Announcement_Details,
  updateAnnouncement,
} from "../common/services/Announcements";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface EditData {
  title: string;
  status: string;
  business: string;
  description: string;
}

function EditAnnouncement() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const Status = [
    { value: "published", label: "Published" },
    { value: "scheduled", label: "Scheduled" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state?.id;
  const [open, setOpen] = useState(false);
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [departmentName, setDepartmentName] = useState<string[]>([]);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<number[]>(
    []
  );
  const [date, setDate] = useState<Dayjs | string | null>(null);
  const [editData, setEditData] = useState<EditData>({
    title: "",
    description: "",
    business: "",
    status: "",
  });

  const handleClose = () => {
    setOpen(false);
    navigate("/announcement-management");
  };

  const handleUpdate = () => {
    submitChanges();
  };

  const handleDepartmentChange = (
    event: SelectChangeEvent<typeof departmentName>
  ) => {
    const {
      target: { value },
    } = event;
    const selectedNames = typeof value === "string" ? value.split(",") : value;

    setDepartmentName(selectedNames);

    const selectedIds =
      department
        ?.filter((dept) => selectedNames.includes(dept.name))
        .map((dept) => Number(dept.id)) || [];

    setSelectedDepartmentIds(selectedIds);
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  useEffect(() => {
    Fetch_Announcement_Details(id)
      .then((data) => {
        if (data.result) {
          setEditData({
            title: data.result.title,
            business: data.result.Group.id,
            status: data.result.status,
            description: data.result.description,
          });
          setDate(dayjs(data.result?.date));
          const deptNames = data.result.AnnouncementDepartments.map(
            (dept: any) => dept.Department.name
          );
          const deptIds = data.result.AnnouncementDepartments.map(
            (dept: any) => dept.Department.id
          );
          setDepartmentName(deptNames);
          setSelectedDepartmentIds(deptIds);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  const submitChanges = async () => {
    try {
      const data = await updateAnnouncement(
        id,
        editData.title,
        editData.status,
        Number(editData.business),
        selectedDepartmentIds,
        editData.description,
        moment(date?.toString()).format("YYYY-MM-DD")
      );
      if (data.status_code === 200) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          navigate("/announcement-management");
        }, 1000);
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <img style={{ margin: "auto" }} src={check} alt="" />
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Announcement Updated Succesfully!
            </Typography>
          </Box>
        </Modal>
      </div>
      <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
        <div className="flex flex-col gap-[50px]">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={() => navigate("/announcement-management")}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
                Edit Announcement Details
              </span>
            </div>
            <div className="flex flex-start gap-5">
              <button
                onClick={() => navigate("/announcement-management")}
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
              >
                <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <button
                type="submit"
                form="form"
                className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
                onClick={handleUpdate}
              >
                <div className="text-white text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
                  Update
                </div>
              </button>
            </div>
          </div>
          <div className="rounded-lg shadow-custom p-4 bg-white">
            <div className="flex flex-col gap-8">
              <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626]">
                Announcement Details
              </span>
              <Box component="form" noValidate autoComplete="off">
                <div className="grid grid-cols-3 gap-8">
                  <TextField
                    required
                    id="outlined-required"
                    label="Title"
                    name="title"
                    value={editData.title}
                    onChange={handleFormChange}
                  />
                  <TextField
                    required
                    select
                    id="outlined-required"
                    label="Published/Scheduled"
                    name="status"
                    value={editData.status}
                    onChange={handleFormChange}
                  >
                    {Status?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {editData.status === "scheduled" ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Scheduled Date"
                        value={date}
                        minDate={dayjs()}
                        className="w-full"
                        onChange={(newValue) => setDate(newValue)}
                      />
                    </LocalizationProvider>
                  ) : (
                    <></>
                  )}
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Business Unit"
                    name="business"
                    defaultValue="hello"
                    variant="outlined"
                    value={editData.business}
                    onChange={handleFormChange}
                  >
                    {bussness?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControl>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Department
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={departmentName}
                      onChange={handleDepartmentChange}
                      input={<OutlinedInput label="Business Unit" />}
                      renderValue={(selected) => selected.join(", ")}
                      // MenuProps={MenuProps}
                    >
                      {department?.map((option) => (
                        <MenuItem key={Number(option.id)} value={option.name}>
                          <Checkbox
                            checked={departmentName.indexOf(option.name) > -1}
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="col-span-3">
                    <TextField
                      required
                      id="outlined-required"
                      label="Description"
                      defaultValue={null}
                      name="description"
                      multiline
                      rows="3"
                      className="w-full"
                      value={editData.description}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAnnouncement;
