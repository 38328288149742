import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import FileUpload from "./EditFileUoload";
import toast from "react-hot-toast";
import axios from "axios";
import {
  fetchEditEmployeeExpense,
  updateEmployeeExpense,
} from "../common/services/EmployeeExpense";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original:string;
}

interface FormValues {
  expense_type: Number;
  currency: string;
  amount: string;
  department: string;
  reason: string;
  employee_id: string;
}

const EditExpense = () => {
  //const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<UploadedFile[]>([]);
  const [applicationDate, setapplicationDate] = React.useState<Dayjs | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const expenseType = useSelector(
    (state: any) => state.expenseType.ExpenseTypeData
  );

  console.log(
    expenseType,
    "expenseTypeexpenseType-------expenseTypeexpenseType"
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    validate();
    submitChanges();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/expense-management");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/expense-management");
  };

  const [formValues, setFormValues] = useState<FormValues>({
    expense_type: -1,
    currency: "",
    amount: "",
    department: "",
    reason: "",
    employee_id: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({})

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {}
    if(formValues.amount === "")
      tempErrors.amount = "Amount is required"
    if(formValues.reason === "")
      tempErrors.reason = "Reason is required"

    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0;
  }

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    console.log("Files updated:", files);
  };

  // const handleFilesChange = (newFiles: UploadedFile[]) => {
  //   // Combine initial files and new files, ensuring no duplicates
  //   const combinedFiles = [...initialFiles, ...newFiles];
  //   console.log(combinedFiles,"combinedfiles");

  //   setUploadedFiles(combinedFiles);
  // };

  const Currency = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
  ];

  console.log(uploadedFiles, "upload");

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditEmployeeExpense(id)
      .then((data) => {
        if (data.result) {

          setFormValues({
            expense_type: data.result?.ExpenseCategory?.id,
            currency: data.result?.expense_currency,
            amount: data.result?.expense_amount,
            department: data.result?.User?.Department?.name,
            reason: data.result?.description,
            employee_id: data.result?.User?.employee_id,
          });

          const { receipt_file_type, receipt_filename, receipt_name } =
            data.result;

          const files = receipt_filename?.map(
            (name: string, index: number) => ({
              originalName: receipt_name,
              url: receipt_filename[index],
            })
          );

          console.log(files,"expenxe files------>>>>>");
          


          setapplicationDate(dayjs(data.result?.expense_date));

          console.log(receipt_name, "receipt_name--->>>>");
          console.log(files, "receipt_name files--->>>>");
          setInitialFiles(files);
          setUploadedFiles(files);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  console.log(formValues, "initialFiles---->>>>");

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateEmployeeExpense(
        id,
        formValues.expense_type,
        dayjs(applicationDate).format("YYYY-MM-DD"),
        formValues.amount,
        formValues.currency,
        // uploadedFiles.map((files) => files.originalName),
        uploadedFiles.map((files) => files.url),
        // ["png"],
        formValues.reason
      );

      if (data && data.result) {
        console.log("final data:", data);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          navigate("/expense-management");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/expense-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Expense
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
          </button>
        </div>
      </div>

      <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
        <div className="container mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                select
                label="Expense Type"
                defaultValue=" "
                name="expense_type"
                value={formValues.expense_type}
                onChange={handleFrormChange}
              >
                {expenseType?.map((option: any) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.expense_category_name}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Application Date"
                  value={applicationDate}
                  onChange={(newValue) => setapplicationDate(newValue)}
                  //className="w-full"
                  maxDate={dayjs()}
                />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                select
                label="Select Currency"
                defaultValue=" "
                name="currency"
                value={formValues.currency}
                onChange={handleFrormChange}
              >
                {Currency.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Amount"
                defaultValue=" "
                name="amount"
                value={formValues.amount}
                onChange={handleFrormChange}
                error={!!errors.amount}
                helperText={errors.amount}
              />
              <TextField
                required
                id="outlined-required"
                label="Department"
                disabled
                defaultValue=" "
                value={formValues.department}
              />
              <TextField
                required
                id="outlined-required"
                label="Employee Id"
                disabled
                defaultValue=" "
                value={formValues.employee_id}
              />
            </div>

            <div className="grid grid-cols-1 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Reason"
                defaultValue=" "
                name="reason"
                multiline
                rows={5}
                value={formValues.reason}
                onChange={handleFrormChange}
                error={!!errors.reason}
                helperText={errors.reason}
              />
            </div>

            <FileUpload
              existingFiles={initialFiles}
              onFilesChange={handleFilesChange}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditExpense;
