import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { MuiTelInput } from "mui-tel-input";
import { CountryCode } from "libphonenumber-js/core";
import { inputStyle } from "../../utils/helperData";
import { MenuItem } from "@mui/material";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import { useSelector } from "react-redux";
import { fetchEditClientList, updateClients } from "../common/services/Clients";
import toast from "react-hot-toast";
import axios from "axios";

interface Country {
  id: number;
  country_name: string;
}

interface State {
  id: number;
  state_name: string;
}

interface FormValues {
  client_name: string;
  email: string;
  address: string;
  point_of_contact: string;
  status: string;
  getState: string;
}

const EditClients = () => {
  let signup_token: string = "";
  const signupData = useSelector((state: any) => state.signup.details);
  console.log(signupData, "signupData--->>>>");

  const navigate = useNavigate();
  const [countryInitial, setCountryInitial] = useState<CountryCode>();
  const [nationalNumber, setNationalNumber] = useState("");
  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);
  const [phone, setPhone] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState("");
  const [countryData, setCountryData] = useState<Country[]>([]);
  const [stateData, setStateData] = useState<State[]>([]);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("0");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    // email: signupData.sign_up_details.email,
    business_size: "default",
    industry_vertical: 0,
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });

  const [formValues, setFormValues] = useState<FormValues>({
    client_name: "",
    email: "",
    address: "",
    point_of_contact: "",
    status: "",
    getState: "",
  });

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleOpen = () => {
    submitChanges();
  };

  useEffect(() => {
    const getCountry = async () => {
      const access_token = localStorage.getItem("access_token");
      const reqdata = await fetch(
        `${base_api}/api/v1/country?page=1&size=264`,
        {
          method: "GET",
          headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      );
      const resdata = await reqdata.json();
      const validData = resdata.result.rows;
      setCountryData(validData);
    };
    getCountry();
  }, []);

  const handleCountry = async (e: any) => {
    setCountryId(e.target.value);
    const access_token = localStorage.getItem("access_token");

    if (e.target.value) {
      const reqStateData = await fetch(
        // `${base_api}/api/v1/state?country_id=${e.target.value}&page=1&size=100`,
        `${base_api}/api/v1/state?country_id=${e.target.value}&page=1&size=100`,
        {
          method: "GET",
          headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      );
      const resStateData = await reqStateData.json();
      const validData: [] = resStateData.result.rows;
      setStateData(validData);
      setEnable(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: countryData[Number(e.target.value) - 1].country_name,
      }));
    } else {
      setStateData([]);
      setEnable(true);
    }
  };

  useEffect(() => {
    const getState = async () => {
      const access_token = localStorage.getItem("access_token");
      const reqStateData = await fetch(
        // `${base_api}/api/v1/state?country_id=${e.target.value}&page=1&size=100`,
        `${base_api}/api/v1/state?country_id=${countryId}&page=1&size=100`,
        {
          method: "GET",
          headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      );
      const resStateData = await reqStateData.json();
      const validData: [] = resStateData.result.rows;
      setStateData(validData);
      setEnable(false);
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   country: countryData[Number() - 1].country_name,
      // }))
    };

    getState();
   }, [countryId]);

  console.log(countryId,"countryidd---->>>>>>>>>>>>>>>");
  

  const handleState = (e: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      state: stateData[Number(e.target.value)].state_name,
    }));
    console.log();

    setStateId(e.target.value);
  };
  const handlePhoneChange = (value: string, info: any) => {
    setPhone(value);
    console.log("Hi", info);
    setNationalNumber(info?.nationalNumber);
    setCountryCode(`+${info?.countryCallingCode}`);
  };

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditClientList(id)
      .then((data) => {
        // console.log(data.result.rows[0].User.Department.name, "data--res----edit expense--->>>>");
        if (data.result) {
          // setUpdateData(data);
          const phonevalue = data.result.phone_code + " " + data.result.phone;
          console.log(data.result.state.id,"data.result.state.iddata.result.state.id");
          
          setFormValues({
            client_name: data.result.client_name,
            email: data.result.email,
            address: data.result.address,
            point_of_contact: data.result.point_of_contact,
            status: data.result.status,
            getState: data.result.state.id,
          });
          setStateId(data.result.state.id);
          setCountryId(data.result.country.id);
          setPhone(phonevalue);
          setCountryCode(data.result.phone_code);
          setNationalNumber(data.result.phone);
          setEnable(true);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const Status = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "archived", label: "Archived" },
    { value: "hold", label: "Hold" },
  ];

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body
    console.log(nationalNumber, "nationalNumbernationalNumber");

    setLoading(true);
    try {
      const data = await updateClients(
        id,
        formValues.client_name,
        formValues.email,
        countryId,
        formValues.getState,
        formValues.address,
        nationalNumber,
        countryCode,
        formValues.point_of_contact,
        formValues.status
      );

      if (data && data.result) {
        console.log("final data:", data);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          navigate("/client");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    // Fetch country based on latitude and longitude
    async function fetchCountry() {
      try {
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        if (response.ok) {
          const data = await response.json();
          setCountryInitial(data.countryCode);
        } else {
          console.error("Failed to fetch country.");
        }
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    }

    if (latitude !== undefined && longitude !== undefined) {
      fetchCountry();
    }
  }, [latitude, longitude]);

  return (
    <div className="min-h-[90vh] px-4 py-6 bg-[#F9F9F9]">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/client")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Clients Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/client")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
          </button>
        </div>
      </div>

      <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
        <div className="container mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Client Name"
                // placeholder="Enter Declaration"
                name="client_name"
                value={formValues.client_name}
                onChange={handleFrormChange}
              />
              <TextField
                required
                id="outlined-required"
                label="Email ID"
                // placeholder="Enter Declaration"
                name="email"
                value={formValues.email}
                onChange={handleFrormChange}
              />
              <TextField
                required
                id="outlined-required"
                label="Country"
                select
                name="country"
                value={countryId}
                onChange={(e) => handleCountry(e)}
              >
                {countryData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.country_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="State"
                select
                // name="state"
                // value={stateId}
                // onChange={handleState}
                name="getState"
                value={formValues.getState}
                onChange={handleFrormChange}
                disabled={enable}
              >
                {stateData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.state_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Address"
                // placeholder="Enter Declaration"
                name="address"
                value={formValues.address}
                onChange={handleFrormChange}
              />

              <div className="flex flex-row mt-1">
                <MuiTelInput
                  // label="Phone"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number"
                  defaultCountry={countryInitial}
                  focusOnSelectCountry
                  autoComplete="off"
                  className="w-full  h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] px-2"
                  // required
                  // error={isValid[`phone`]}
                  // helperText={
                  //   isValid[`phone`] === 2
                  //     ? "This field is required!"
                  //     : isValid[`phone`] === 1
                  //     ? "Length must be of 8-16 characters long!"
                  //     : ""
                  // }
                  InputLabelProps={{
                    shrink: true,
                    // sx: labelStyle,
                  }}
                  InputProps={{
                    sx: {
                      ...inputStyle,
                      alignItems: "center",
                      flexDirection: "row",
                      // marginLeft: "20px",
                      marginBottom: "8px",
                      borderRadius: "3px",
                      height: "4.5rem",
                      // width: "300px",
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px #fff inset",
                        WebkitTextFillColor: "inherit",
                      },
                    },
                  }}
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>

              <TextField
                required
                id="outlined-required"
                label="Point Of Contact"
                // placeholder="Enter Declaration"
                name="point_of_contact"
                value={formValues.point_of_contact}
                onChange={handleFrormChange}
              />
              <TextField
                required
                id="outlined-required"
                label="Status"
                select
                // placeholder="Enter Declaration"
                name="status"
                value={formValues.status}
                onChange={handleFrormChange}
              >
                {Status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditClients;
