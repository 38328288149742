import { Dispatch } from "redux";
import { CustomerManagementActions } from "../../reducers/CustomerManagement/CustomerManagementSlice";
import {
  Add_Customer,
  Customer_Management_List_Api,
  Fetch_Customer_Details_Api,
  Send_Updated_Customer_Data,
} from "../../../components/common/services/customerManagement";

import {api_key_work as api_key , base_api} from "../../../utils/helperData"
import axios from "axios";
import InternalServer from "../../../components/common/InternalServer";

export const fetchCustomerManagementList = (
  customer_id: number, 
  numOfItems: number,
  page: number,
  sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null,
  setIsLoading: (isLoading: boolean) => void
) => {
  return async (dispatch: Dispatch) => {
    setIsLoading(true);
    const fetchData = async () => {
      dispatch(
        CustomerManagementActions.setCustomerManagementData({
          count: 0,
          rows: [],
        })
      );

      const response = await Customer_Management_List_Api(
        customer_id,
        numOfItems,
        page,
        sortType,
        searchTerm,
        startDate,
        endDate
      );

      const data = await response.json();
      if (!response.ok) {
        if(response.status === 500) {
          return <InternalServer />;
        }
        throw new Error("Could not fetch service data!");
      }

      return data;
    };

    try {
      const resData = await fetchData();
      console.log(resData);

      const CustomerManagementListData = resData.result;
      dispatch(
        CustomerManagementActions.setCustomerManagementData(
          CustomerManagementListData || []
        )
      );
      setIsLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        console.log("Fetching Service data failed!");
      } else {
        console.log("An unknown error occurred:", error);
      }
      setIsLoading(false);
    }
  };
};

export const getCustomerResourseList: any = async () => {
  const access_token = localStorage.getItem("access_token");

  console.log("resource");
  const response = await axios.get(
    base_api +
      `/api/v1/resources?page=1&size=100&sort_by=createdAt&sort_order=ASC`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
  if (response.status === 200) {
    const userData = response?.data || null;
    console.log(userData);
    return userData.result;
  }
  return response;
};

export const fetchCustomerDetails = (id: number) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const response = await Fetch_Customer_Details_Api(id);
      const data = await response.json();
      if (!response.ok) {
        if(response.status === 500) {
          return <InternalServer />;
        }
        throw new Error("Could not fetch user data!");
      }
      return data;
    };
    try {
      const resData = await fetchData();
      const customerData = resData.result;
      dispatch(CustomerManagementActions.setCustomerData(customerData || {}));
    } catch (error) {
      if (error instanceof Error) {
        console.log("Fetching User data failed!");
      } else {
        console.log("An unknown error occurred:", error);
      }
    }
  };
};

export const addCustomer = (customerDetails: {}) => async () => {
  try {
    const response = await Add_Customer(customerDetails);
    console.log(response);
    const userData = await response.json();
    console.log(userData);

    if (!userData.success) {
      throw new Error(userData.message);
    }
    return true;
  } catch (error: any) {
    const err = error.message;
    console.error("Error adding customer:", error);
    throw new Error(err);
  }
};

export const sendUpdatedCustomerData =
  (
    firstName: string,
    lastName: string,
    phone: string,
    address: string,
    country_code: string,
    customer_name: string,
    status: string,
    resource_list: { id: number; type: string }[],
    user_id: number,
    customer_id: number
  ) =>
  async () => {
    const response = await Send_Updated_Customer_Data(
      firstName,
      lastName,
      phone,
      address,
      country_code,
      customer_name,
      status,
      resource_list,
      user_id,
      customer_id
    );

    if (!response.ok) {
      if(response.status === 500) {
        return <InternalServer />;
      }
      throw new Error("Could not send update user data!");
    }
    const data = await response.json();
    return data;
  };
