import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_BIRTHDAY_ANNIVERSARY_LIST, FETCH_DASHBOARD_WFH_LIST, FETCH_LEAVE_DASHBORAD_LIST } from "../api";

export const Fetch_WFH_Dashboard_List = (
  // customer_id: string|undefined,
  // searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;
  //let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  // if (searchTerm !== "") {
  //   query += `&search=${searchTerm}`;
  // }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(`${FETCH_DASHBOARD_WFH_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_Dashboard_List = (
  // customer_id: string|undefined,
  // searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;
  //let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  // if (searchTerm !== "") {
  //   query += `&search=${searchTerm}`;
  // }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(`${FETCH_LEAVE_DASHBORAD_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Birthday_Anniversary_Lst = (
  // customer_id: string|undefined,
  // searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;
  //let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  // if (searchTerm !== "") {
  //   query += `&search=${searchTerm}`;
  // }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(`${FETCH_BIRTHDAY_ANNIVERSARY_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
