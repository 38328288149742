import search from "../../assets/Search.svg";
import loader from "../../assets/Loader.gif";
import ascending from "../../assets/ascending.svg";
import descending from "../../assets/descending.svg";
import moment from "moment";

import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createGlobalStyle } from "styled-components";
import action from "../../assets/tableActionIcon.svg";
import TablePaginationDemo from "../common/Pagenation";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { userManagementActions } from "../../redux/reducers/userManagement/userManagementSlice";
import { fetchUserManagementList } from "../../redux/actions/UserManagement/UserManagementActions";
import { convertToISO8601 } from "../../utils/helperData";
import date from "../../assets/date.svg";
import "./UserManagementTable.css";

const tableHeadings = [
  { id: "firstName", label: "First Name", sort: false, width: "w-[10%]" },
  { id: "lastName", label: "Last Name", sort: false, width: "w-[10%]" },
  { id: "email", label: "E-Mail", sort: false, width: "w-[15.7%]" },
  { id: "phone", label: "Contact Number", sort: false, width: "w-[14.1%]" },
  { id: "createdAt", label: "Added Date", sort: true, width: "w-[12.2%]" },
  { id: "updatedAt", label: "Last Updated", sort: true, width: "w-[12.2%]" },
  { id: "group", label: "Groups", sort: false, width: "w-[12.9%]" },
  { id: "role_name", label: "Role", sort: false, width: "w-[8%]" },
  { id: "action", label: "Action", sort: false, width: "w-[4.9%]" },
];

interface UserManagementTableProps {
  handleChangePreviousPath: (value: string) => void;
  handleCurrentPageChange: (value: string) => void;
  handleUserIdChange: (value: number) => void;
}

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

const UserManagementTable: React.FC<UserManagementTableProps> = ({
  handleChangePreviousPath,
  handleCurrentPageChange,
  handleUserIdChange,
}) => {
  const dispatch = useDispatch();

  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const endDate = useSelector(
    (state: any) => state.userManagement.userManagementData.endDate
  );
  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const startDate = useSelector(
    (state: any) => state.userManagement.userManagementData.startDate
  );

  const count = useSelector(
    (state: any) => state.userManagement.userManagementData.count
  );
  const tableData = useSelector(
    (state: any) => state.userManagement.userManagementData.rows
  );
  const numOfItems = useSelector(
    (state: any) => state.userManagement.userManagementData.itemsPerPage
  );
  const currentPage = useSelector(
    (state: any) => state.userManagement.userManagementData.currentPage
  );

  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [sortType, setSortType] = useState<any>({
    key: "createdAt",
    order: "DESC",
  });

  function handleNumberOfPages(value: number) {
    dispatch(userManagementActions.setUserManagementItemsPerPage(value));
  }

  function handlePageChange(value: number) {
    dispatch(userManagementActions.setUserManagementDataCurrentPage(value));
  }

  function handleSortType(item: any) {
    if (item.sort) {
      const id = item.id;

      if (sortType && sortType.key === id) {
        const newOrder = sortType.order === "ASC" ? "DESC" : "ASC";
        setSortType({ key: id, order: newOrder });
      } else {
        setSortType({ key: id, order: "DESC" });
      }
    }
  }

  const handleSearchValue = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    }, 1000), // delay in ms
    []
  );

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  // const filterTableDataByDateRange = (
  //   data: any,
  //   startDate: Date | null,
  //   endDate: Date | null
  // ) => {
  //   if (!startDate || !endDate) {
  //     console.log("filtered dataa ", data);
  //     return data;
  //   }

  //   return data.filter((row: any) => {
  //     const createdAtDate = new Date(row.createdAt);
  //     return createdAtDate >= startDate && createdAtDate <= endDate;
  //   });
  // };
  // console.log("filterTableDataByDateRange", filterTableDataByDateRange);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);

    dispatch(userManagementActions.setStartDate(dates[0]));
    dispatch(userManagementActions.setEndDate(dates[1]));
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      let url = `/api/v1/user/list?customer_id=${customer_id}&page=${currentPage}&size=${numOfItems}`;

      if (searchValue !== "") {
        url += `&search=${searchValue}`;
      }

      if (sortType !== undefined) {
        url += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
      }

      if (startDate instanceof Date && endDate instanceof Date) {
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");
        url += `&start_date=${start}&end_date=${end}`;
      }

      if (startDate && !endDate) {
        return;
      }

      dispatch(
        userManagementActions.setUserManagementData({ count: 0, rows: [] })
      );

      try {
        const resData = await dispatch<any>(fetchUserManagementList(url));

        const userManagementListData = resData.result;

        dispatch(
          userManagementActions.setUserManagementData(
            userManagementListData || { count: 0, rows: [] }
          )
        );
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          // console.log("Fetching User data failed!");
        } else {
          // console.log("An unknown error occurred:", error);
          // Handle other types of errors if needed
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [numOfItems, currentPage, searchValue, sortType, startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      setIsCalendarOpen(false);
    }
  }, [startDate, endDate]);

  return (
    <>
      <div className="w-full mt-[17px]">
        <div className="p-5 flex flex-col items-flex-end gap-[17px] self-stretch rounded-md bg-white">
          <div className="flex flex-start self-stretch justify-between w-full h-10">
            <div className="w-[40%]  pr-2.5 flex justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA] bg-white">
              <input
                type="text"
                id="searchBar"
                name="searchBar"
                placeholder="Search"
                className="text-[#3F4254] w-[95%] outline-none rounded-md py-2 pl-2.5 font-[Poppins] text-[13px] font-normal leding-normal"
                onChange={(e) => handleSearchValue(e)}
                // value={searchValue}
              />
              <button
              //  onClick={handleSearchValue}
              >
                <img
                  src={search}
                  alt="search sign"
                  className="h-[19px] w-[19px] shrink-0"
                />
              </button>
            </div>
            <div className="flex w-[28.5%] h-10 pr-4 justify-between items-center rounded-md border-[1px] border-solid border-[#CACACA]">
              <GlobalStyle />
              <DatePicker
                selected={selectedDates[1]}
                onChange={handleDateChange}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                className="w-full flex py-2.5 px-3 h-[38px] justify-between items-center flex-[1,0,0] bg-white border-white"
                customInput={<input />}
                isClearable
                dateFormat="MMMM d, yyyy"
                placeholderText="MM/DD/YYYY - MM/DD/YYYY"
                open={isCalendarOpen}
                onInputClick={() => {
                  setIsCalendarOpen(!isCalendarOpen);
                }}
                // onClickOutside={() => {setIsCalendarOpen(!isCalendarOpen)}}
                // onCalendarClose={() => {setIsCalendarOpen(!isCalendarOpen)}}
                // onCalendarOpen={() => {setIsCalendarOpen(!isCalendarOpen)}}
              />
              {
                <img
                  src={date}
                  alt="date icon"
                  onClick={() => {
                    setIsCalendarOpen(!isCalendarOpen);
                  }}
                  className="flex items-center justify-between relative"
                ></img>
              }
            </div>
          </div>

          <div className="flex flex-row justify-end gap-[31px]">
            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Active users
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Inactive users
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Archived users
              </span>
            </div>
          </div>

          <div className="w-full px-0 py-0 mt-0 h-[19rem] overflow-x-auto table_main_content">
            <table className="mt-0 w-full h-full min-w-[910px] text-left table-auto">
              <thead className="sticky top-0 mt-[-1px]">
                <tr className="flex flex-row w-full items-center pl-6 pr-2.5 py-2.5 rounded-t-[3px] bg-[#E1E1E1] h-11">
                  {tableHeadings.map((item, i) => {
                    let cssClass = "flex items-center " + item.width + " ";
                    cssClass += item.sort
                      ? "cursor-pointer gap-1.5"
                      : "cursor-default ";

                    return (
                      <th
                        className={cssClass}
                        onClick={() => handleSortType(item)}
                        key={item.id + i}
                      >
                        <span className="shrink-0 text-[#3F4254] font-[Poppins] text-xs font-medium leading-[11.185px] tracking-[0.72px] capitialize">
                          {item.label}
                        </span>
                        {item.sort &&
                          (sortType.key === item.id &&
                          sortType.order === "DESC" ? (
                            <img
                              src={descending}
                              alt="Descending order symbol"
                              className="w-[13px] h-[13px]"
                            />
                          ) : (
                            <img
                              src={ascending}
                              alt="Ascending order symbol"
                              className="w-[13px] h-[13px]"
                            />
                          ))}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {isLoading && tableData.length === 0 && (
                  <tr className="w-full flex px-2.5 py-1 justify-center items-center border-[0.6px] border-solid border-[#E0E3E7] h-8 hover:cursor-pointer">
                    <td className="w-full flex flex-row justify-center items-center">
                      {/* <img src={loader} alt="Loader" className="h-8 w-8 justify-center items-center"/> */}
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </td>
                  </tr>
                )}
                {!isLoading && tableData.length === 0 && (
                  <tr className="w-full flex px-2.5 py-1 justify-center items-center border-[0.6px] border-solid border-[#E0E3E7] h-8 hover:cursor-pointer">
                    <td className="w-full flex flex-row justify-center items-center font-[Poppins]">
                      Data Not Found!
                    </td>
                  </tr>
                )}

                {tableData.map((item: any, i: number) => {
                  const fill =
                    item.status === "active"
                      ? "#00CBA0"
                      : item.status === "archived"
                      ? "#FA1464"
                      : "#FF9950";
                  return (
                    <tr
                      className="w-full flex px-2.5 py-1 items-center border-[0.6px] border-solid border-[#E0E3E7] h-8 hover:cursor-pointer"
                      key={i}
                      onClick={() => {
                        handleUserIdChange(item.id);
                      }}
                    >
                      <div
                        className="flex w-[95.1%]"
                        onClick={() => handleCurrentPageChange("viewUser")}
                      >
                        <td className="w-[11.66%]">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" fill={fill} />
                            </svg>
                            <span className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium overflow-hidden w-[86%] overflow-ellipsis">
                              {item?.firstName}
                            </span>
                          </div>
                        </td>
                        <td className="w-[10.38%]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium overflow-hidden w-[70px] overflow-ellipsis">
                                {item?.lastName}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[16.3%]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium w-[135px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                                {item?.email}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[14.64%]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                                {`${item?.country_code} ${item?.phone}`}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[12.675%]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                                {item?.addedDate}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[12.675%]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                                {item?.updatedDate}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[13.4%]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium whitespace-nowrap w-[95px] overflow-hidden overflow-ellipsis">
                                {item?.group}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="w-[8.27%]">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center gap-1 shrink-0">
                              <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium whitespace-nowrap w-[90px] overflow-hidden overflow-ellipsis">
                                {item?.role_name}
                              </p>
                            </div>
                          </div>
                        </td>
                      </div>

                      <td className="flex w-[3%] justify-center">
                        {item?.status !== "archived" && (
                          <button
                            className="align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                            type="button"
                            onClick={() => {
                              handleCurrentPageChange("editUser");
                              handleChangePreviousPath("table");
                            }}
                          >
                            <img
                              src={action}
                              alt="Table Action Icon"
                              className="h-6 w-6"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <TablePaginationDemo
        count={count}
        handleItemsChange={handleNumberOfPages}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        numOfItems={numOfItems}
      />
    </>
  );
};

export default UserManagementTable;
