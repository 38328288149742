import rightArrow from "../../assets/chevron-right.svg";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-tailwind/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { MuiTelInput } from "mui-tel-input";
import {
  getCustomerResourseList,
  fetchCustomerDetails,
  sendUpdatedCustomerData,
} from "../../redux/actions/CustomerManagement/CustomerManagementActions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import RenderResources from "../common/ResourceList/Resources";

interface EditCustomerProps {
  handleCurrentPageChange: (value: string, event: any) => void;
  id: number;
}

interface Resource {
  resource_id: string;
  parent_id: string;
  resource_name: string;
}

interface FormData {
  customer_name: string;
  email: string;
  address: string;
  admin_name: string;
  status: string;
  phone: string;
  country_code: string;
  resource_list: any[]; // Adjust the type according to your actual data structure
}

interface Group {
  resource_id: number;
  resource_name: string;
  parent_id: number | null;
  children?: Group[];
}

const labelStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
};
const inputStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "10px",
  border: "var(--Our_Colors-Grays-400, #C5CDD4)",
  height: "56px",
  width: "325px",
};
const menuStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
};
const validationData: any = [
  "adminName",
  "address",
  "phone",
  "customerName",
  "resources",
];

const validatePhoneNumber = (phoneNumber: string) => {
  // Remove any non-digit characters from the input
  const cleanedNumber = phoneNumber.toString();

  const regex = /^[0-9]{8,16}$/;

  if (cleanedNumber.match(regex)) {
    return true;
  } else {
    return false;
  }
};

const flattenGroupData = (groupData: Group[]): any => {
  const flattenedRecords: any[] = [];

  function flattenGroup(group: any[]) {
    console.log(group);
    group.forEach((item: any) => {
      const record = {
        resource_id: item.resource_id,
        resource_name: item.resource_name,
        parent_id: item.parent_id,
        children: item?.children?.length,
      };
      flattenedRecords.push(record);
      if (item.children && item.children.length > 0) {
        flattenGroup(item.children);
      }
    });
  }

  flattenGroup(groupData);

  return flattenedRecords;
};

const EditCustomer: React.FC<EditCustomerProps> = ({
  handleCurrentPageChange,
  id,
}) => {
  const dispatch = useDispatch();
  const customerData = useSelector(
    (state: any) => state.CustomerManagement.customerData
  );
  const [initialResourceList, setInitialResourceList] = useState<number[]>([]);
  const [customerDetails, setCustomerDetails] = useState(customerData);
  const [customerResourceList, setCustomerResourceList] = useState<any[]>([]);
  const [flattenResources, setFlattenResources] = useState<any[]>([]);
  const [resourceList, setResource_list] = useState<any[]>([]);
  const [data, setData] = useState(customerDetails);
  const [phone, setPhone] = useState(
    `+${customerDetails.country_code}${customerDetails.phone}`
  );
  const [phoneInfo, setPhoneInfo] = useState({
    countryCallingCode: "",
    countryCode: "",
    nationalNumber: customerDetails?.phone,
  });
  // const [fetchedResourceList, setFetchedResourceList] = useState(
  //   new Array(25).fill(false)
  // );

  const [isValid, setIsValid] = useState<{ [key: string]: any }>({});

  function handleValidation(adminName: string) {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    updated["adminName"] = !adminName.trim();
    updated["address"] = !formData.address.trim();
    updated["phone"] =
      formData.phone === "" ? 2 : !validatePhoneNumber(formData.phone) ? 1 : 0;
    updated["customerName"] = !formData.customer_name.trim();
    updated["resources"] = !resourceList.length;

    setIsValid(updated);

    if (
      !adminName.trim() ||
      !formData.address.trim() ||
      !validatePhoneNumber(formData.phone) ||
      !formData.customer_name.trim() ||
      !resourceList.length
    ) {
      return;
    }
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    if (item === "phone") {
      updated[`${item}`] = 0;
    } else {
      updated[`${item}`] = false;
    }

    setIsValid(updated);
  }

  // const [updatedResourceList, setUpdatedResourceList] = useState<any[]>([]);

  // useEffect(() => {
  //   // Update updatedResourceList after fetchedResourceList has been updated
  //   // setResource_list(fetchedResourceList);
  //   setUpdatedResourceList(fetchedResourceList);
  // }, [fetchedResourceList]);

  const [formData, setFormData] = useState<FormData>({
    customer_name: "",
    email: "",
    address: "",
    admin_name: "",
    status: "",
    phone: "",
    country_code: "",
    resource_list: [],
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (newValue: any, info: any) => {
    setPhone(newValue);
    setData({
      ...data,
      phone: newValue,
    });
    setPhoneInfo({
      countryCallingCode: info.countryCallingCode,
      countryCode: info.countryCode,
      nationalNumber: info.nationalNumber,
    });
    setFormData({
      ...formData,
      country_code: info.countryCallingCode,
      phone: info.nationalNumber,
    });

    handleIsValidItem("phone");
  };

  function handleResourceListChildren(event: any, id: any, parent_id: any) {
    let newResourceList: any[] = [...resourceList];
    const subResourceObjects = flattenResources.filter(
      (idx: Resource) => idx.parent_id === id
    );
    if (event.target.checked) {
      // If the checkbox is being checked, add the resource_id of the child and its parent to resourceList
      const currentElement = flattenResources.find(
        (item) => item.resource_id === Number(id)
      );
      const selectedElement = flattenResources.find(
        (item) => item.resource_id === Number(event.target.id)
      );
      if (parent_id) {
        newResourceList = [...newResourceList, Number(id), Number(parent_id)];
        const parentElement = flattenResources.find(
          (item) => item.resource_id === parent_id
        );

        if (parentElement && parentElement?.parent_id) {
          newResourceList = [
            ...handleResourceListChildren(
              event,
              parent_id,
              parentElement?.parent_id
            ),
            ...newResourceList,
          ];
        }
      }
      console.log(currentElement);
      if (selectedElement && selectedElement?.children) {
        newResourceList = handleResourceListParent(
          event,
          currentElement?.resource_id,
          currentElement?.parent_id,
          [...newResourceList]
        );
      }
      if (!parent_id) {
        newResourceList = [...newResourceList, Number(id)];
      }
    } else {
      // If the checkbox is being unchecked, remove the resource_id of the child from resourceList
      const currentElement = flattenResources.find(
        (item) => item.resource_id === Number(id)
      );

      if (currentElement && currentElement?.children) {
        newResourceList = handleResourceListParent(
          event,
          currentElement?.resource_id,
          currentElement?.parent_id,
          [...newResourceList]
        );
      }
      // If this is the last child of its parent that's selected, also remove the parent
      if (
        !newResourceList.some((item) =>
          subResourceObjects.find(
            (subObj: Resource) => Number(subObj.resource_id) === item
          )
        )
      ) {
        newResourceList = newResourceList.filter((item) => item !== Number(id));
      }
      setResource_list(newResourceList);
    }

    newResourceList = removeDuplicate(newResourceList);

    return newResourceList;
  }

  function handleResourceListParent(
    event: any,
    id: any,
    parent_id: any,
    temp: any[]
  ) {
    let newResourceList: any[] = [...temp, ...resourceList];

    const subResourceObjects = flattenResources.filter(
      (idx: Resource) => idx.parent_id === id
    );
    if (event.target.checked) {
      // If the checkbox is being checked, add the resource_id of the parent and all its children to resourceList
      newResourceList = [
        ...newResourceList,
        Number(id),
        ...subResourceObjects.map((subObj: Resource) =>
          Number(subObj.resource_id)
        ),
      ];
    } else {
      // If the checkbox is being unchecked, remove the resource_id of the parent and all its children from resourceList
      newResourceList = newResourceList.filter(
        (item) =>
          item !== Number(id) &&
          !subResourceObjects
            .map((subObj: Resource) => Number(subObj.resource_id))
            .includes(item)
      );
    }

    newResourceList = removeDuplicate(newResourceList);

    return newResourceList;
  }

  function removeDuplicate(tempList: any[]) {
    let checkRepeat = new Set();
    tempList.forEach((item: any) => checkRepeat.add(item));

    return Array.from(checkRepeat);
  }

  function handleResourceListChildrenChange(
    event: any,
    id: any,
    parent_id: any
  ) {
    const tempList = handleResourceListChildren(event, id, parent_id);

    const resourceListArray = removeDuplicate(tempList);

    setResource_list(resourceListArray);
    handleIsValidItem("resources");
  }

  function handleResourceListParentChange(event: any, id: any, parent_id: any) {
    const tempList = handleResourceListParent(event, id, parent_id, []);

    const resourceListArray = removeDuplicate(tempList);

    setResource_list(resourceListArray);
    handleIsValidItem("resources");
  }

  console.log(resourceList);

  useEffect(() => {
    if (customerDetails?.customer_details?.CustomerResources) {
      setInitialResourceList(
        customerDetails.customer_details.CustomerResources.map(
          (resource: any) => resource.resource_id
        )
      );
      setResource_list(
        customerDetails.customer_details.CustomerResources.map(
          (resource: any) => resource.resource_id
        )
      );
      // setFetchedResourceList((prevState) => {
      //   const updatedList = [...prevState];
      //   customerDetails.customer_details.CustomerResources.forEach(
      //     (resource: any) => {
      //       updatedList[resource.resource_id] = true;
      //     }
      //   );
      //   return updatedList;
      // });
    }
  }, [customerDetails?.customer_details?.CustomerResources]);

  useEffect(() => {
    if (customerDetails) {
      let tempdata = {
        customer_name: customerDetails?.customer_details?.name || "",
        email: customerDetails?.email || "",
        address: customerDetails?.customer_details?.address || "",
        admin_name: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
        status: customerDetails.status,
        phone: customerDetails?.phone || "",
        country_code: customerDetails?.country_code || "",
        resource_list: [],
      };

      let code: string = customerDetails?.country_code;
      if (code?.includes("+")) {
        setPhone(`${customerDetails?.country_code}${customerDetails?.phone}`);
      } else {
        setPhone(`+${customerDetails?.country_code}${customerDetails?.phone}`);
      }

      setFormData(tempdata);
    }
  }, [customerDetails]);

  useEffect(() => {
    async function fetchResourceList() {
      try {
        const data = await getCustomerResourseList();
        if (data) {
          setCustomerResourceList(data);
          setFlattenResources(flattenGroupData(data));
        } else {
          console.error("Failed to fetch customer resource list.");
        }
      } catch (error) {
        console.error("Error fetching customer resource list:", error);
      }
    }

    fetchResourceList();
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      dispatch<any>(fetchCustomerDetails(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    setCustomerDetails(customerData);
  }, [customerData]);

  useEffect(() => {
    setData(customerDetails);
  }, [customerDetails]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    toast.dismiss();
    event.preventDefault();

    const resourceChanges: { id: number; type: string }[] = [];

    resourceList.forEach((resource_id) => {
      if (!initialResourceList.includes(resource_id)) {
        resourceChanges.push({ id: resource_id, type: "add" });
      }
    });

    initialResourceList.forEach((resource_id) => {
      if (!resourceList.includes(resource_id)) {
        resourceChanges.push({ id: resource_id, type: "delete" });
      }
    });

    // extracting firstname and lastname from adminName
    const data = new FormData(event.currentTarget);

    const adminName = formData?.admin_name;
    const names = adminName?.split(" ");
    let firstName = names?.slice(0, -1)?.join(" ") || " ";
    let lastName: string = " ";
    if (firstName) {
      lastName = names[names?.length - 1];
    } else {
      firstName = names[names?.length - 1];
    }

    handleValidation(adminName);

    if (
      !adminName.trim() ||
      !formData.address.trim() ||
      !validatePhoneNumber(formData.phone) ||
      !formData.customer_name.trim() ||
      !resourceList.length
    ) {
      return;
    }

    try {
      toast.loading("Processing in...");
      const userData = await dispatch<any>(
        sendUpdatedCustomerData(
          firstName,
          lastName,
          formData.phone,
          formData.address,
          formData.country_code,
          formData.customer_name,
          formData.status,
          resourceChanges,
          customerDetails.id,
          customerDetails?.customer_details?.id
        )
      );

      toast.dismiss();
      toast.success("Customer editted successfully");
      handleCurrentPageChange("table", null);
      // Do something with the userData if needed
    } catch (error: any) {
      console.error("Error editting customer:", error);

      toast.dismiss();
      toast.error("Updating Customer data failed!");
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  let cssClass =
    "h-4 w-4 rounded-md shrink-0 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0 ";
  cssClass += isValid["resources"]
    ? "bg-[#FFD8D8] border-[#DC2626]"
    : "border-gray-900/20";

  return (
    <div className="bg-[#FBFBFB] py-10 px-[18.5px] h-full">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[26px] justify-center items-center">
          <button onClick={(e) => handleCurrentPageChange("table", e)}>
            <img
              src={rightArrow}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Customer
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            form="form"
            type="submit"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg buttoncss h-12"
          >
            <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Save Changes
            </div>
          </button>
          <button
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12"
            onClick={(e) => handleCurrentPageChange("table", e)}
          >
            <div className="text-[#2C2C2C] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
        </div>
      </div>

      <div className="w-full mt-5">
        <div className=" py-[25px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] bg-white">
          <Container component="main">
            <Box>
              <Box
                id="form"
                component="form"
                className="flex flex-col gap-8"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: "0px" }}
              >
                <div>
                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                    Customer Details
                  </div>

                  <Grid
                    container
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "self-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <Grid item>
                      <TextField
                        autoComplete="given-name"
                        name="customer_name"
                        value={formData?.customer_name}
                        required
                        error={isValid[`customerName`]}
                        helperText={
                          isValid[`customerName`]
                            ? "This field is required!"
                            : ""
                        }
                        id="customer_name"
                        onChange={(e) => {
                          handleInputChange(e);
                          handleIsValidItem("customerName");
                        }}
                        label="Customer Name"
                        placeholder="Enter Customer name"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        autoComplete="email"
                        name="email"
                        value={formData?.email}
                        required
                        id="email"
                        label="E-mail"
                        placeholder="Enter Email"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        autoComplete="address"
                        name="address"
                        value={formData?.address}
                        error={isValid[`address`]}
                        helperText={
                          isValid[`address`] ? "This field is required!" : ""
                        }
                        onChange={(event) => {
                          handleInputChange(event);
                          handleIsValidItem("address");
                        }}
                        required
                        id="address"
                        label="Address"
                        placeholder="Enter Address"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                    Admin Details
                  </div>
                  <Grid
                    container
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "self-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <TextField
                      name="admin_name"
                      required
                      label="Admin name"
                      value={formData.admin_name}
                      error={isValid[`adminName`]}
                      helperText={
                        isValid[`adminName`] ? "This field is required!" : ""
                      }
                      onChange={(event) => {
                        handleInputChange(event);
                        handleIsValidItem("adminName");
                      }}
                      placeholder="Enter Admin Name"
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                    />
                    <TextField
                      name="email"
                      required
                      value={formData.email}
                      label="E-mail"
                      placeholder="Enter Customer Email"
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                    />
                    <MuiTelInput
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                      defaultCountry="IN"
                      required
                      error={isValid[`phone`]}
                      helperText={
                        isValid[`phone`] === 2
                          ? "This field is required!"
                          : isValid[`phone`] === 1
                          ? "Length must be of 8-16 characters long!"
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={phone}
                      onChange={handleChange}
                    />
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        shrink
                        sx={labelStyle}
                      >
                        Status
                      </InputLabel>
                      <Select
                        label="Status"
                        name="status"
                        id="demo-simple-select"
                        value={formData?.status}
                        sx={inputStyle}
                        onChange={handleInputChange}
                        displayEmpty
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Status"
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: menuStyle,
                          },
                        }}
                      >
                        <MenuItem value="active" style={menuStyle}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              alignItems: "center",
                            }}
                          >
                            Active
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="#00CBA0"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" />
                            </svg>
                          </div>
                        </MenuItem>
                        <MenuItem value="inactive" style={menuStyle}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              alignItems: "center",
                            }}
                          >
                            In-active
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="#FF9950"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" />
                            </svg>
                          </div>
                        </MenuItem>
                        <MenuItem value="archived" style={menuStyle}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              alignItems: "center",
                            }}
                          >
                            Archive
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="#FA1464"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" />
                            </svg>
                          </div>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>

                <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[10px] mb-[10px]">
                  Accesses given to Customer
                </div>

                {isValid["resources"] && (
                  <div className="text-[#DC2626] font-[Poppins] text-xs font-normal leading-5 tracking-[-0.24px] my-[-20px]">
                    Ensure at least one option is selected!
                  </div>
                )}

                {/* <Grid
                  container
                  style={{
                    gap: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    marginBottom: "60px",
                  }}
                >
                  {customerResourceList.map((obj: Resource) => {
                    if (!checkRepeat.has(obj.resource_id)) {
                      const subResourceObjects = customerResourceList.filter(
                        (idx: Resource) => idx.parent_id === obj.resource_id
                      );

                      let cssClass =
                        "h-4 w-4 rounded-md shrink-0 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0 ";
                      cssClass += isValid["resources"]
                        ? "bg-[#FFD8D8] border-[#DC2626]"
                        : "border-gray-900/20";

                      const subResources = subResourceObjects.map(
                        (subObj: Resource) => {
                          checkRepeat.add(subObj.resource_id); // Add sub resource_id to the set
                          return (
                            <li
                              className="w-[366px] flex flex-row items-start"
                              key={subObj.resource_id}
                            >
                              <Checkbox
                                ripple={false}
                                crossOrigin=""
                                value="insights"
                                id={"insights"}
                                containerProps={{
                                  className: "p-0",
                                }}
                                checked={resourceList.includes(
                                  Number(subObj.resource_id)
                                )} // Check if the resource_id of the current item is in the resourceList array
                                onChange={(event) => {
                                  let newResourceList: number[];
                                  if (event.target.checked) {
                                    // If the checkbox is being checked, add the resource_id of the child and its parent to resourceList
                                    setResource_list([
                                      ...resourceList,
                                      Number(subObj.resource_id),
                                      Number(obj.resource_id),
                                    ]);
                                  } else {
                                    // If the checkbox is being unchecked, remove the resource_id of the child from resourceList
                                    newResourceList = resourceList.filter(
                                      (id) => id !== Number(subObj.resource_id)
                                    );
                                    // If this is the last child of its parent that's selected, also remove the parent
                                    if (!newResourceList.some((id) => subResourceObjects.find(
                                      (subObj: Resource) => Number(subObj.resource_id) === id
                                    )
                                    )) {
                                      newResourceList = newResourceList.filter(
                                        (id) => id !== Number(obj.resource_id)
                                      );
                                    }
                                    setResource_list(newResourceList);
                                  }

                                  handleIsValidItem("resources");
                                } }
                                className={cssClass} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                              />
                              <label
                                htmlFor={subObj.resource_id}
                                className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal pl-3 mt-[-3px]"
                              >
                                {subObj.resource_name}
                              </label>
                            </li>
                          );
                        }
                      );

                      return (
                        <div
                          key={obj.resource_id}
                          className="gap-7 flex flex-row w-full"
                        >
                          <div className="flex flex-row gap-3 w-[28%] items-center h-3">
                            <Checkbox
                              ripple={false}
                              crossOrigin=""
                              value="insights"
                              id={"insights"}
                              containerProps={{ className: "p-0" }}
                              checked={resourceList.includes(
                                Number(obj.resource_id)
                              )} // Check if the resource_id of the current item is in the resourceList array
                              onChange={(event) => {
                                if (event.target.checked) {
                                  // If the checkbox is being checked, add the resource_id of the parent and all its children to resourceList
                                  setResource_list([
                                    ...resourceList,
                                    Number(obj.resource_id),
                                    ...subResourceObjects.map(
                                      (subObj: Resource) => Number(subObj.resource_id)
                                    ),
                                  ]);
                                } else {
                                  // If the checkbox is being unchecked, remove the resource_id of the parent and all its children from resourceList
                                  setResource_list(
                                    resourceList.filter(
                                      (id) => id !== Number(obj.resource_id) &&
                                        !subResourceObjects
                                          .map((subObj: Resource) => Number(subObj.resource_id)
                                          )
                                          .includes(id)
                                    )
                                  );
                                }
                                handleIsValidItem("resources");
                              } }
                              className={cssClass} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            />

                            <label
                              htmlFor={obj.resource_name}
                              className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal"
                            >
                              {obj.resource_name}
                            </label>
                          </div>
                          <div className="flex flex-row gap-3 items-start">
                            <ul className="grid grid-cols-2 gap-5">
                              {subResources}
                            </ul>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </Grid> */}

                <RenderResources
                  renderData={customerResourceList}
                  isChecked={false}
                  cssClass={cssClass}
                  resourceList={resourceList}
                  handleResourceListChildren={handleResourceListChildrenChange}
                  handleResourceListParent={handleResourceListParentChange}
                />
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default EditCustomer;
