import rightArrow from "../../assets/chevron-right.svg";
import addNew from "../../assets/addNew.png";
import search from "../../assets/Search.svg";
import TablePaginationDemo from "../common/Pagenation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerManagementList } from "../../redux/actions/CustomerManagement/CustomerManagementActions";
import action from "../../assets/tableActionIcon.svg";
import { format } from "date-fns";
import { debounce } from "lodash";
import { CustomerManagementActions } from "../../redux/reducers/CustomerManagement/CustomerManagementSlice";
import DatePicker from "react-datepicker";
import ascending from "../../assets/ascending.svg";
import descending from "../../assets/descending.svg";
import date from "../../assets/date.svg";
import "./CustomerManagementTable.css";

interface Users {
  firstName: string;
  lastName: string;
}

interface RowData {
  id: number;
  name: string;
  email: string;
  address: string;
  createdAt: string;
  updatedAt: string;
  Users: Users;
}

const crmType = "Customer Management";
const addType = "Customer";
const tableHeadings = [
  { id: "name", label: "Company Name", sort: true, width: "w-[20%]" },
  { id: "email", label: "Company Email", sort: true, width: "w-[20%]" },
  { id: "firstName", label: "Contact Person", sort: false, width: "w-[20%]" },
  { id: "address", label: "Company Address", sort: true, width: "w-[15%]" },
  { id: "createdAt", label: "Added date", sort: true, width: "w-[10%]" },
  { id: "updatedAt", label: "Last updated", sort: true, width: "w-[10%]" },
  { id: "action", label: "Action", sort: false, width: "w-[5%]" },
];

interface CustomerManagementTableProps {
  handleCurrentPageChange: (value: string, event: any) => void;
  handleEdit: (id: number) => void;
}

const CustomerManagementTable: React.FC<CustomerManagementTableProps> = ({
  handleCurrentPageChange,
  handleEdit,
}) => {
  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const startDate = useSelector(
    (state: any) => state.CustomerManagement.startDate
  );
  const endDate = useSelector((state: any) => state.CustomerManagement.endDate);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const cnt = useSelector((state: any) => state.CustomerManagement.count);
  const currentPage = useSelector(
    (state: any) => state.CustomerManagement.currentPage
  );
  const numOfItems = useSelector(
    (state: any) => state.CustomerManagement.itemsPerPage
  );
  useEffect(() => {
    setCount(cnt);
  }, [cnt]);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const Data = useSelector(
    (state: any) => state.CustomerManagement.CustomerManagementData
  );
  useEffect(() => {
    setTableData(Data);
  }, [Data]);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState<any>({
    key: "createdAt",
    order: "DESC",
  });
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  //////////////////////////////searching///////////////////////////

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  const filterTableDataByDateRange = (
    data: RowData[],
    startDate: Date | null,
    endDate: Date | null
  ) => {
    if (!startDate || !endDate) {
      return data;
    }

    return data.filter((row) => {
      const createdAtDate = new Date(row.createdAt);
      return createdAtDate >= startDate && createdAtDate <= endDate;
    });
  };

  const filteredTableData = useMemo(() => {
    // setIsLoading(true);
    if (!tableData) {
      // setIsLoading(false);
      return [];
    }

    let filteredData = tableData.filter((row) => {
      const data =
        row?.name || ""?.toLowerCase().includes(searchTerm.toLowerCase());
      return data;
    });
    filteredData = filterTableDataByDateRange(
      filteredData,
      selectedDates[0],
      selectedDates[1]
    );
    // setIsLoading(false);
    return filteredData;
  }, [tableData, searchTerm, selectedDates]);

  function handleNumberOfPages(value: number) {
    dispatch(
      CustomerManagementActions.setCustomerManagementItemsPerPage(value)
    );
  }

  function handlePageChange(value: number) {
    dispatch(
      CustomerManagementActions.setCustomerManagementDataCurrentPage(value)
    );
  }

  function handleSortType(item: any) {
    if (item.sort) {
      const id = item.id;

      if (sortType && sortType.key === id) {
        const newOrder = sortType.order === "ASC" ? "DESC" : "ASC";
        setSortType({ key: id, order: newOrder });
      } else {
        setSortType({ key: id, order: "DESC" });
      }
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    dispatch<any>(
      fetchCustomerManagementList(
        customer_id,
        numOfItems,
        currentPage,
        sortType,
        searchTerm,
        selectedDates[0],
        selectedDates[1],
        setIsLoading
      )
    );
  }, [
    access_token,
    customer_id,
    numOfItems,
    currentPage,
    sortType,
    searchTerm,
    selectedDates,
  ]);
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
  };

  return (
    <div className="bg-[#FBFBFB] pt-10 px-[18.5px] h-full">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[26px] justify-center items-center">
          <img
            src={rightArrow}
            alt="right arrow sign"
            className="h-6 w-6 shrink-0 bg-black rounded-full"
          />
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            {crmType}
          </span>
        </div>
        <button
          className="flex w-[166px] py-[11px] px-[15px] justify-center items-center gap-2.5 rounded-lg buttoncss h-12"
          onClick={(event) => handleCurrentPageChange("addNew", event)}
        >
          <div className="flex justify-center items-center gap-[7px]">
            <div className="flex justify-center items-center">
              <img
                src={addNew}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-white rounded-full"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="rgb(79, 70, 229)"
                className="absolute"
              >
                <path
                  d="M11.0013 6.83464H6.83464V11.0013C6.83464 11.4596 6.45964 11.8346 6.0013 11.8346C5.54297 11.8346 5.16797 11.4596 5.16797 11.0013V6.83464H1.0013C0.542969 6.83464 0.167969 6.45964 0.167969 6.0013C0.167969 5.54297 0.542969 5.16797 1.0013 5.16797H5.16797V1.0013C5.16797 0.542969 5.54297 0.167969 6.0013 0.167969C6.45964 0.167969 6.83464 0.542969 6.83464 1.0013V5.16797H11.0013C11.4596 5.16797 11.8346 5.54297 11.8346 6.0013C11.8346 6.45964 11.4596 6.83464 11.0013 6.83464Z"
                  fill=""
                />
              </svg>
            </div>
            <span className="text-white font-[Poppins] text-sm font-medium leading-[29px]">
              Add {addType}
            </span>
          </div>
        </button>
      </div>

      <div className="w-full mt-[17px]">
        <div className="p-5 flex flex-col items-flex-end gap-[17px] self-stretch rounded-md bg-white">
          <div className="flex flex-start self-stretch justify-between w-full h-[41px]">
            <div className="w-[40%] flex pr-2 justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA] bg-white">
              <input
                type="text"
                id="searchBar"
                name="searchBar"
                placeholder="Search"
                className="text-[#3F4254] w-[95%] outline-none rounded-md py-2 pl-2.5 font-[Poppins] text-[13px] font-normal leding-normal"
                onChange={updateSearchTerm}
              />
              <button onClick={updateSearchTerm}>
                <img
                  src={search}
                  alt="search sign"
                  className="h-[19px] w-[19px] shrink-0"
                />
              </button>
            </div>
            <div className="w-[27.13%] h-full">
              <DatePicker
                selected={selectedDates[1]}
                onChange={handleDateChange}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                className="w-[280px] flex py-2 px-3 justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA] bg-white"
                customInput={<input />}
                isClearable
                dateFormat="MMMM d, yyyy"
                placeholderText="DD/MM/YYYY - DD/MM/YYYY"
              />
              {
                <img
                  src={date}
                  alt="date icon"
                  className="translate-x-[235px] translate-y-[-32px]"
                ></img>
              }
            </div>
          </div>

          <div className="flex flex-row justify-end gap-[31px]">
            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[11px] font-medium leading-[13.981px]">
                Active Customers
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[11px] font-medium leading-[13.981px]">
                Inactive Customers
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[11px] font-medium leading-[13.981px]">
                Archived Customers
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="px-0 py-0 mt-0 w-full h-[19rem] overflow-x-auto overflow-y-auto table_main_content">
        <table className="mt-0 w-full min-w-max text-left table-auto">
          <thead className="sticky top-0">
            <tr className="flex flex-row w-full items-center pl-6 pr-2.5 py-2.5 rounded-[3px] bg-[#E1E1E1] gap-8 h-11 justify-between">
              {tableHeadings.map((item) => {
                const cssClass =
                  "flex flex-row cursor-pointer transition-colors items-center gap-1 " +
                  item.width;

                return (
                  <th
                    className={cssClass}
                    key={customer_id}
                    onClick={() => handleSortType(item)}
                  >
                    <span className="shrink-0 text-[#3F4254] font-[Poppins] text-xs font-medium leading-[11.185px] tracking-[0.72px] capitialize">
                      {item.label}
                    </span>
                    {item.sort &&
                      (sortType.key === item.id && sortType.order === "DESC" ? (
                        // <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="h-4 w-4">
                        //     <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                        // </svg>
                        <img
                          src={descending}
                          alt="Descending order symbol"
                          className="w-[13px] h-[13px]"
                        />
                      ) : (
                        <img
                          src={ascending}
                          alt="Ascending order symbol"
                          className="w-[13px] h-[13px]"
                        />
                      ))}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="">
            {isLoading && tableData.length === 0 && (
              <tr className="w-full flex px-2.5 py-1 justify-center items-center border-[0.6px] border-solid border-[#E0E3E7] h-8 hover:cursor-pointer">
                <td className="w-full flex flex-row justify-center items-center">
                  {/* <img src={loader} alt="Loader" className="h-8 w-8 justify-center items-center"/> */}
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </td>
              </tr>
            )}
            {!isLoading && tableData?.length === 0 && (
              <tr className="w-full flex px-2.5 py-1 justify-center items-center border-[0.6px] border-solid border-[#E0E3E7] h-8 hover:cursor-pointer">
                <td className="w-full flex flex-row justify-center items-center">
                  Data Not Found!
                </td>
              </tr>
            )}
            {filteredTableData.map((item: any, i) => {
              const fill =
                item.status === "active"
                  ? "#00CBA0"
                  : item.status === "inactive"
                  ? "#FF9950"
                  : "#FA1464";
              return (
                <tr
                  className="w-full h-[52px] flex pl-6 pr-2.5 py-1 items-center gap-8 border-[0.6px] border-solid border-[#E0E3E7] justify-between cursor-pointer"
                  onClick={(event) => {
                    handleEdit(item.id);
                    handleCurrentPageChange("viewCustomer", event);
                  }}
                >
                  <td className="w-[20%] flex flex-row items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                    >
                      <circle cx="5.5" cy="5.35156" r="5" fill={fill} />
                    </svg>

                    {item.name && (
                      <span className="text-[#262626] shrink-0 font-[Poppins] text-[11px] leading-[13.981px] font-medium overflow-hidden w-fit overflow-ellipsis text-left">
                        {item.name}
                      </span>
                    )}
                  </td>
                  <td className="w-[20%] items-center text-[#262626] font-[Poppins] text-[11px] leading-[13.981px] font-medium overflow-hidden overflow-ellipsis text-left">
                    {item.email}
                  </td>
                  <td className="w-[20%] items-center text-[#262626] font-[Poppins] text-[11px] leading-[13.981px] font-medium overflow-hidden overflow-ellipsis text-left">
                    {item.Users[0].firstName + " " + item.Users[0].lastName}
                  </td>
                  <td className="w-[15%] items-center text-[#262626] font-[Poppins] text-[11px] leading-[13.981px] font-medium overflow-hidden overflow-ellipsis text-left">
                    {item.address}
                  </td>
                  <td className="w-[10%] items-center text-[#262626] font-[Poppins] text-[11px] leading-[13.981px] font-medium overflow-hidden overflow-ellipsis text-left">
                    {format(item.createdAt, "dd/MM/yyyy")}
                  </td>
                  <td className="w-[10%] items-center text-[#262626] font-[Poppins] text-[11px] leading-[13.981px] font-medium overflow-hidden overflow-ellipsis text-left">
                    {format(item.updatedAt, "dd/MM/yyyy")}
                  </td>
                  <td className="w-[5%]">
                    <button
                      className="align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                      type="button"
                      onClick={(event) => {
                        handleEdit(item.id);
                        handleCurrentPageChange("edit", event);
                      }}
                    >
                      <img
                        src={action}
                        alt="Table Action Icon"
                        className="h-6 w-6"
                      />
                      {/* {i+1} */}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mb-[70px]">
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          numOfItems={numOfItems}
        />
      </div>
    </div>
  );
};

export default CustomerManagementTable;
