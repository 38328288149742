import { useSelector } from "react-redux";
// import cross from "../../../../../assets/close-circle.svg"
import cross from "../../../assets/images/cross.png";
import "./styles.css";

import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { userManagementActions } from "../../../redux/reducers/userManagement/userManagementSlice";
import {
  fetchRoleList,
  fetchUserManagementList,
} from "../../../redux/actions/UserManagement/UserManagementActions";

interface AddUnitProps {
  onClose: () => void;
  onConfirm: (event: React.FormEvent<HTMLFormElement>) => void;
  modalType: string;
  handleIdChange: (value: string) => void;
}

export const menuStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
};

const AddUnit: React.FC<AddUnitProps> = ({
  onConfirm,
  onClose,
  modalType,
  handleIdChange,
}) => {
  const dispatch = useDispatch();
  const signupData = useSelector((state: any) => state.signup.details);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [isLoading, setIsLoading] = useState(false);
  let customer_id = 0;
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  if (signupData !== null) {
    customer_id = signupData.sign_up_details.customer_id;
  }

  const tableData = useSelector(
    (state: any) => state.userManagement.userManagementData.rows
  );

  console.log(tableData,"check  customer management-------->>>>>>>>");
  
  const [curretLabel, setCurrentLabel] = useState({ id: 0, name: "" });
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      let url = `/api/v1/user/list?customer_id=${customer_id}&page=${1}&size=${10000}`;

      try {
        const resData = await dispatch<any>(fetchUserManagementList(url));

        const userManagementListData = resData.result.userList.rows;

        console.log(userManagementListData,"userManagementListData------>>>>>");
        

        dispatch(
          userManagementActions.setUserManagementData(
            userManagementListData || { count: 0, rows: [] }
          )
        );
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
        } else {
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  let modalLabel;
  if (modalType === "Bussiness") {
    modalLabel = "Bussiness";
  } else if (modalType === "Department") {
    modalLabel = "Department";
  } else {
    modalLabel = "Process";
  }

  const handleRoleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const currentRole = tableData.find(
      (item: any) => item.id == event.target.value
    );
    setCurrentLabel(currentRole);
    handleIdChange(currentRole.id);
  };
  return (
    <>
      <form className="modal-overlay hide" onSubmit={onConfirm}>
        <div className="modal-wrapper ">
          <div className="pb-3">
            <div className="close-btn-wrapper">
              <button className="close-modal-btn" onClick={onClose}>
                <img src={cross} alt="any" />
              </button>
            </div>
            <label className="ml-4 font-[Poppins]">Add {modalLabel} Unit</label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder={`Enter ${modalLabel} Unit..`}
              className=" ml-4 w-[93%] p-3 border border-solid border-[#CCC] rounded-[4px] my-[6px] resize-y box-border"
            />

            <label className="ml-4 font-[Poppins]" id="spoc">
              Assign SPOC
            </label>
            <select
              className="ml-4 w-[93%] p-3 border border-solid border-[#CCC] rounded-[4px] my-[6px] resize-y box-border font-[Poppins]"
              id="spoc_id"
              name="spoc_id"
              defaultValue=""
              value={curretLabel.id}
              onChange={handleRoleChange}
            >
              <option value="Select">Select</option>
              {tableData.map((role: any) => (
                <option value={role.id} style={menuStyle}>
                  {role.firstName} {role.lastName}
                </option>
              ))}
            </select>
            {/* <label className="ml-4" id="email">
            Add Email
          </label>
          <input
            type="text"
            className="ml-4 w-[93%] p-3 border border-solid border-[#CCC] rounded-[4px] my-[6px] resize-y box-border"
            id="email"
            name="email"
            placeholder="Enter Email.."
          /> */}
          </div>
          <div className="justify-end flex">
            <input
              type="submit"
              className="w-[25%] p-2 px-5 border-none rounded-[4px] bg-custom-primary text-white cursor-pointer my-[4px] resize-y box-border  font-[poppins] mr-4"
              value="Submit"
            ></input>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddUnit;
