import React, { useEffect, useRef, useState } from "react";
import profile_pic from "../../assets/images/profile_pic.png";
import kebab_menu from "../../assets/images/kebab_menu.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/reducers/Auth/authSlice";
import { revertAll } from "../../redux/ResetRedux/RevertAll";
import downarrow from "../../assets/down_arrow.svg";
import uparrow from "../../assets/up_arrow.svg";
import moment from "moment";

const Header = ({ onSessionChange }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const signupData = useSelector((state: any) => state.signup.details);
  const userProfileData = useSelector((state: any) => state.userProfile);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [startYear, setStartYear] = useState(0);
  const [endYear, setEndYear] = useState(0);
  const [sessionStart, setSessionStart] = useState("");
  const [sessionEnd, setSessionEnd] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  console.log("111", loginData);
  

  const options = [
    { session: "2021-22", startYear: 2021, endYear: 2022 },
    { session: "2022-23", startYear: 2022, endYear: 2023 },
    { session: "2023-24", startYear: 2023, endYear: 2024 },
    { session: "2024-25", startYear: 2024, endYear: 2025 },
  ];

  const toggleSessionDropdown = () => setIsOpen(!isOpen);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log(loginData, "logindata------->>>>>>>");

  const logOut = () => {
    localStorage.clear();
    // dispatch(authActions.reset());
    dispatch(revertAll());
    navigate("/");
  };

  // useEffect(() => {
  //   const storedSession = localStorage.getItem("selectedSession");
  //   if (storedSession) {
  //     const parsedSession = JSON.parse(storedSession);
  //     setSelected(parsedSession.session);
  //     setStartYear(parsedSession.startYear);
  //     setEndYear(parsedSession.endYear);
  //   }
  // }, []);

  useEffect(() => {
    const storedSession = localStorage.getItem("selectedSession");
    if (storedSession) {
      const parsedSession = JSON.parse(storedSession);
      setSelected(parsedSession.session);
      setStartYear(parsedSession.startYear);
      setEndYear(parsedSession.endYear);
    } else {
      // Determine the current session
      const currentMonth = new Date().getMonth(); // January is 0!
      const currentYear = new Date().getFullYear();
      let defaultStartYear;
      let defaultEndYear;

      if (currentMonth >= 3) {
        // April or later
        defaultStartYear = currentYear;
        defaultEndYear = currentYear + 1;
      } else {
        // January, February, March
        defaultStartYear = currentYear - 1;
        defaultEndYear = currentYear;
      }

      const defaultSession = `${defaultStartYear}-${defaultEndYear
        .toString()
        .slice(-2)}`;
      setSelected(defaultSession);
      setStartYear(defaultStartYear);
      setEndYear(defaultEndYear);
    }
  }, []);

  // const start = new Date(startYear, 3, 1)
  // const end = new Date(endYear, 2, 31)

  // const session_start = moment(start).format('DD-MM-YYYY')
  // const session_end = moment(end).format('DD-MM-YYYY')

  useEffect(() => {
    if (startYear && endYear) {
      const start = new Date(startYear, 3, 1); // April 1st
      const end = new Date(endYear, 2, 31); // March 31st
      console.log(start, "startstartstart----->>>>>>");
      localStorage.setItem("start_session", moment(start).format("YYYY-MM-DD"));
      localStorage.setItem("end_session", moment(end).format("YYYY-MM-DD"));
      setSessionStart(moment(start).format("YYYY-MM-DD"));
      setSessionEnd(moment(end).format("YYYY-MM-DD"));
      onSessionChange(
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD")
      );
    }
  }, [startYear, endYear, onSessionChange]);

  // setStartDate(session_start)
  // setEndDate(session_end)

  // const handleSessionClick = (option: any) => {
  //   localStorage.setItem("selectedSession", JSON.stringify(option));
  //   setSelected(option.session);
  //   setIsOpen(false);
  // };

  const handleSessionClick = (option: any) => {
    window.location.reload();
    setSelected(option.session);
    setStartYear(option.startYear);
    setEndYear(option.endYear);
    localStorage.setItem("selectedSession", JSON.stringify(option));
    setIsOpen(false); // Close the dropdown after selection
  };

  console.log(sessionStart, "sessionStartsessionStart----->>>>");
  console.log(sessionEnd, "sessionEndsessionEnd----->>>>");

  return (
    <div className="flex sticky top-0 z-10 justify-center items-end py-1.5 px-5 bg-white border-solid shadow-sm border-b-[0.8px] border-b-slate-200 border-b-opacity-80 max-md:px-5 max-md:max-w-full">
      <div className="flex justify-between items-center w-full">
        <div className="h-11 w-[204px]">
          <div className="relative inline-block w-full">
            <div
              className="borderButton rounded-lg flex pl-[14px] items-center cursor-pointer w-full h-11"
              onClick={toggleSessionDropdown}
            >
              <div className="flex gap-2">
                <span className="font-[Inter] font-medium text-base text-[#101828]">
                  Session
                </span>
                <span className="font-[Inter] font-normal text-base text-[#475467]">
                  {selected || "Select"}
                </span>
              </div>
              {isOpen ? (
                <img src={uparrow} alt="up arrow" className="pl-1" />
              ) : (
                <img src={downarrow} alt="down arrow" className="pl-1" />
              )}
            </div>
            {isOpen && (
              <div className="absolute left-0 max-h-60 overflow-auto z-10 mt-1 w-[204px]  bg-white border border-[#F2F4F7] rounded-lg shadow-lg">
                <ul className="font-[Poppins]">
                  {options.map((option) => (
                    <li
                      key={option.session}
                      className={`font-[Inter] font-medium text-base text-[#101828] pl-5 py-2 cursor-pointer hover:bg-gray-100 ${
                        selected === option.session ? "bg-gray-100" : ""
                      }`}
                      onClick={() => handleSessionClick(option)}
                    >
                      {option.session}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-2.5  max-md:mr-2">
          <div className="flex justify-center items-center aspect-square">
            <img
              loading="lazy"
              // src={profile_pic}
              src={
                loginData.profile_image ||
                "https://via.placeholder.com/150"
              }
              className="w-8 rounded-full aspect-square bg-gray-500"
            />
          </div>
          <div className="flex flex-col font-semibold whitespace-nowrap">
            {loginData && loginData.first_name
              ? `${loginData.first_name} ${loginData.last_name}`
              : signupData && signupData.sign_up_details.firstName
              ? `${signupData.sign_up_details.firstName} ${signupData.sign_up_details.lastName}`
              : "NA"}
            <div className="text-xs leading-5 text-teal-900">
              {loginData && loginData.role ? loginData.role : "NA"}
            </div>
          </div>

          <div ref={dropdownRef} className="relative">
            <button
              onClick={toggleDropdown}
              id="dropdownDefaultButton"
              data-dropdown-toggle="dropdown"
              type="button"
            >
              <img
                loading="lazy"
                src={kebab_menu}
                className="w-8 h-7 rounded-full aspect-square mt-2"
              />
            </button>
            {/* Dropdown menu  */}
            {dropdownOpen && (
              <div
                id="dropdown"
                className="absolute right-0 mt-2 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Contact Support
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={logOut}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
