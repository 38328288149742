import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import {
  Fetch_Employee_List,
  Fetch_Employee_Management_List,
  Select_Dept_Unit,
  Select_Designation_Manager,
  Select_Reporting_Manager,
  Select_Role_Unit,
  Select_Team_Manager,
  Select_Unit,
} from "../../../components/common/services/Employee";
import { EmployeeActions } from "../../reducers/Employee/EmployeeSlice";
import { EmployeeListActions } from "../../reducers/Employee/EmployeeListSlice";

const handleErrorResponse = (status: number, navigate: NavigateFunction) => {
  if ([401, 402, 403, 405, 500].includes(status)) {
    navigate("/server-error");
    return true;
  }
  return false;
};

export const EmployeeUnit =
  (customer_id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Select_Unit(customer_id);
      console.log("responsive ness-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log();
      const setData = { name: data.result[0].name, id: data.result[0].id };
      dispatch(EmployeeActions.setDevelopment(setData));
      console.log("dataaaa", data);
      return data;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

export const Fetch_Employee_List_List_Management = (
  customer_id: number,
  numOfItems: number,
  page: number
) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      console.log("gyughbhgbhjbv ======");

      const response = await Fetch_Employee_List(customer_id, numOfItems, page);
      console.log(response, "response employee data------>>>>>");

      //const data = await response.json();
      if (response.status !== 200) {
        throw new Error("Could not fetch service data!");
      }
      return response;
    };

    try {
      console.log("before tryyyy----->>>>");
      const resData = await fetchData();
      const EmployeeData = resData?.data?.result.userData;
      console.log(EmployeeData, "EmployeeData==========>>>>>>");
      dispatch(EmployeeListActions.setEmployeeData(EmployeeData || []));
    } catch (error) {
      if (error instanceof Error) {
        console.log("Fetching Service data failed!", error);
      } else {
        console.log("An unknown error occurred:", error);
      }
    }
  };
};

export const Department_Unit =
  (customer_id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Select_Dept_Unit(customer_id);
      console.log("responsive department-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log();
      // const setData = {name: data.result[0].name, id: data.result[0].id}
      // dispatch(EmployeeActions.setDevelopment(setData))
      // console.log("dataaaa", data)
      return data;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

export const Role_Unit =
  (customer_id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Select_Role_Unit(customer_id);
      console.log("responsive department-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log();
      // const setData = {name: data.result[0].name, id: data.result[0].id}
      // dispatch(EmployeeActions.setDevelopment(setData))
      // console.log("dataaaa", data)
      return data;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

export const Team_Manager =
  (customer_id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Select_Reporting_Manager(customer_id);
      console.log("responsive Team Manager-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log();
      // const setData = {name: data.result[0].name, id: data.result[0].id}
      // dispatch(EmployeeActions.setDevelopment(setData))
      // console.log("dataaaa", data)
      return data;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

export const Designation_Manager =
  (customer_id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Select_Designation_Manager(customer_id);
      console.log("responsive Designation Manager-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log();
      // const setData = {name: data.result[0].name, id: data.result[0].id}
      // dispatch(EmployeeActions.setDevelopment(setData))
      // console.log("dataaaa", data)
      return data;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

export const Reporting_Manager =
  (customer_id: number, navigate: NavigateFunction) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Select_Team_Manager(customer_id);
      console.log("responsive reporting Manager-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log();
      // const setData = {name: data.result[0].name, id: data.result[0].id}
      // dispatch(EmployeeActions.setDevelopment(setData))
      // console.log("dataaaa", data)
      return data;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };
