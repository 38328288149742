import React, { useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

interface MyStatefulEditorProps {
  onChange?: (value: string) => void;
}

const MyStatefulEditor: React.FC<MyStatefulEditorProps> = ({ onChange }) => {
  const [value, setValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());

  const handleChange = (value: EditorValue) => {
    setValue(value);
    if (onChange) {
      onChange(value.toString('html'));
    }
  };

  return (
    <RichTextEditor
      value={value}
      onChange={handleChange}
      className='h-[300px]'
      editorClassName='h-full'
      placeholder='Enter Description here ...'
    />
  );
};

export default MyStatefulEditor;





