import rightArrow from "../../assets/chevron-right.svg";
import addNew from "../../assets/addNew.png"
import UserManagementTable from "./UserManagementTable";
import { useNavigate } from "react-router-dom";

const crmType = "User Management";
const addType = "User";

interface UserManagementTableLayoutProps {
    handleChangePreviousPath: (value: string) => void;
    handleCurrentPageChange: (value: string) => void;
    handleUserIdChange: (value: number) => void;
}

const UserManagementTableLayout: React.FC<UserManagementTableLayoutProps> = ({ handleChangePreviousPath ,handleCurrentPageChange, handleUserIdChange }) => {
    const navigate = useNavigate();

    return (
        <div className="bg-[#FBFBFB] pt-10 px-[18.5px] h-full">
            <div className="flex flex-row items-center h-12 justify-between">
                <div className="flex flex-row gap-[26px] justify-center items-center">
                  <button onClick={() => { navigate(-1) }}>
                    <img src={rightArrow} alt="right arrow sign" className="h-6 w-6 shrink-0 bg-black rounded-full" />
                  </button>
                    <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">{crmType}</span>
                </div>
                <button 
                  className="flex w-[166px] py-[11px] px-[15px] justify-center items-center gap-2.5 rounded-lg bg-custom-primary h-12"
                  onClick={() => handleCurrentPageChange("addNew")}
                >
                    <div className="flex justify-center items-center gap-[7px]">
                        <div className="flex justify-center items-center">
                          <img src={addNew} alt="right arrow sign" className="h-6 w-6 shrink-0 bg-white rounded-full"/>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="rgb(93,66,209)" className="absolute">
                            <path d="M11.0013 6.83464H6.83464V11.0013C6.83464 11.4596 6.45964 11.8346 6.0013 11.8346C5.54297 11.8346 5.16797 11.4596 5.16797 11.0013V6.83464H1.0013C0.542969 6.83464 0.167969 6.45964 0.167969 6.0013C0.167969 5.54297 0.542969 5.16797 1.0013 5.16797H5.16797V1.0013C5.16797 0.542969 5.54297 0.167969 6.0013 0.167969C6.45964 0.167969 6.83464 0.542969 6.83464 1.0013V5.16797H11.0013C11.4596 5.16797 11.8346 5.54297 11.8346 6.0013C11.8346 6.45964 11.4596 6.83464 11.0013 6.83464Z" fill=""/>
                          </svg>
                        </div>
                        <span className="text-white font-[Poppins] text-sm font-medium leading-[29px]">Add {addType}</span>
                    </div>
                </button>
            </div>

            <UserManagementTable 
              handleChangePreviousPath={handleChangePreviousPath}
              handleCurrentPageChange={handleCurrentPageChange}
              handleUserIdChange={handleUserIdChange}
            />
        </div>
    )
}

export default UserManagementTableLayout;
