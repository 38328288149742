import rightArrow from "../../assets/chevron-right.svg";

import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MuiTelInput } from "mui-tel-input";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-tailwind/react";
import {
  UserManagementForm,
  ViewUserForm,
  labelStyle,
  inputStyle,
} from "../../utils/helperData";

import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserRoleAccessList } from "../../redux/actions/UserManagement/UserManagementActions";
import RenderResources from "../common/ResourceList/Resources";

interface ViewUserProps {
  handleChangePreviousPath: (value: string) => void;
  handleCurrentPageChange: (value: string) => void;
  labelId: number | undefined;
}

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "91.5%",
    height: "100%",
  },
});

const ViewUser: React.FC<ViewUserProps> = ({
  handleChangePreviousPath,
  handleCurrentPageChange,
  labelId,
}) => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const userData = useSelector((state: any) => state.userManagement.userData);
  const roleData = useSelector(
    (state: any) => state.userManagement.userRoleData.rows
  );

  const [isLoading, setIsLoading] = React.useState(true);

  const [userAccessList, setUserAccessList] = React.useState([]);
  const [role, setRole] = React.useState<any>({
    id: userData.role_id,
    role_name: userData.role_name,
  });

  function handleUserDataChange(value: string) {
    handleCurrentPageChange(value);
  }

  function dummyParentFunction(event: any, id: any, parent_id: any, temp: any[]){

  }

  function dummyChildrenFunction(event: any, id: any, parent_id: any){

  }

  const fill =
    userData.status === "active"
      ? "#00CBA0"
      : userData.status === "archived"
      ? "#FA1464"
      : "#FF9950";

  const status =
    userData.status === "active"
      ? "Active"
      : userData.status === "archived"
      ? "Archived"
      : "Inactive";

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  React.useEffect(() => {
    const currentRole = roleData.find(
      (item: any) => item.id === userData.role_id
    );

    if (currentRole) {
      setRole(currentRole);
    }
  }, [userData.role_id]);

  React.useEffect(() => {
    async function fetchResourceList() {
      setIsLoading(true);
      try {
        const data = await getUserRoleAccessList(role.id);

        if (data) {
          // setUserAccessList(
          //   data.result.UserRolePrivileges.map((item: any) => item.Resource)
          // );
          if(!data?.result?.resources){ 
            setUserAccessList([]);
          }else {
            setUserAccessList(data?.result?.resources);
          }
        } else {
          // console.error("Failed to fetch user access list.");
        }

        setIsLoading(false);
      } catch (error) {
        // console.error("Error fetching user access list:", error);
        setIsLoading(false);
      }
    }

    if (role.id) {
      fetchResourceList();
    }
    if (!role.id) {
      setUserAccessList([]);
    }
    // }, [role.id, userData.role_id]);
    // }, [userData.role_id]);
  }, [role.id]);
  // }, []);

  return (
    <>
      <div className="bg-[#FBFBFB] pt-10 px-[18.5px] h-full">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[26px] justify-center items-center">
            <button onClick={() => handleUserDataChange("table")}>
              <img
                src={rightArrow}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              User Management
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              className="flex flex-row w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg bg-custom-primary gap-[7px] h-12"
              onClick={() => {
                handleCurrentPageChange("editUser");
                handleChangePreviousPath("viewUser");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.2573 5.07168C15.7234 4.60559 16.3555 4.34375 17.0147 4.34375C17.3411 4.34375 17.6643 4.40803 17.9658 4.53293C18.2673 4.65783 18.5413 4.8409 18.7721 5.07168C19.0028 5.30247 19.1859 5.57645 19.3108 5.87798C19.4358 6.17952 19.5 6.5027 19.5 6.82907C19.5 7.15545 19.4358 7.47863 19.3108 7.78017C19.1859 8.0817 19.0028 8.35568 18.7721 8.58647L17.8526 9.50597C17.5272 9.83141 16.9995 9.83141 16.6741 9.50597L14.3378 7.1697C14.0123 6.84427 14.0123 6.31663 14.3378 5.99119L15.2573 5.07168ZM13.1593 8.34822C12.8338 8.02277 12.3062 8.02277 11.9808 8.34822L6.17571 14.1533C5.64171 14.6873 5.26289 15.3563 5.07973 16.089L4.5249 18.3083C4.45391 18.5923 4.53711 18.8927 4.7441 19.0997C4.95108 19.3067 5.25149 19.3898 5.53546 19.3188L7.75478 18.764C8.48741 18.5809 9.1565 18.2021 9.69049 17.6681L15.4955 11.863C15.821 11.5376 15.821 11.0099 15.4955 10.6845L13.1593 8.34822Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 18.5052C12 18.045 12.3731 17.6719 12.8333 17.6719H18.6667C19.1269 17.6719 19.5 18.045 19.5 18.5052C19.5 18.9655 19.1269 19.3385 18.6667 19.3385H12.8333C12.3731 19.3385 12 18.9655 12 18.5052Z"
                  fill="white"
                />
              </svg>
              <div className="text-white text-center font-[Poppins] text-sm font-medium leading-[29px]">
                Edit User
              </div>
            </button>
          </div>
        </div>

        <div className="w-full mt-5">
          <div className="pt-[25px] pb-[50px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] bg-white">
            <Container component="main">
              <Box>
                <Box component="form" sx={{ mt: "0px" }} id="createUserForm">
                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                    User Details
                  </div>

                  <Grid
                    container
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >
                    {UserManagementForm.map((item) => (
                      <Grid key={item.id} style={{ width: "30%" }}>
                        <StyledTextField
                          autoComplete={item.autoComplete}
                          name={item.name}
                          id={item.id}
                          label={item.label}
                          // aria-readonly
                          value={userData[String(item.name)]}
                          placeholder={item.placeholder}
                          InputLabelProps={{
                            shrink: true,
                            sx: labelStyle,
                          }}
                          className="w-full"
                          InputProps={{
                            readOnly: true,
                            tabIndex: -1,
                            sx: {
                              ...inputStyle,
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                WebkitTextFillColor: "inherit",
                              },
                            },
                          }}
                        />
                      </Grid>
                    ))}

                    <MuiTelInput
                      label="Phone"
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone Number"
                      // defaultCountry="IN"
                      // aria-readonly
                      focusOnSelectCountry
                      className="w-[30%]"
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        readOnly: true,
                        tabIndex: -1,
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={`${userData.country_code}${userData.phone}`}
                    />

                    <Grid style={{ width: "30%" }}>
                      <StyledTextField
                        autoComplete="role"
                        name="role"
                        id="role"
                        label="Role"
                        placeholder="Role"
                        aria-readonly
                        value={userData.role_name}
                        className="w-full"
                        // onChange={(e) => handleRoleChange(e.currentTarget.value)}
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          readOnly: true,
                          tabIndex: -1,
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid style={{ width: "30%" }}>
                      <StyledTextField
                        autoComplete="group"
                        name="group-name"
                        id="groupName"
                        label="Group Assigned"
                        placeholder="Select"
                        value={userData.group}
                        aria-readonly
                        className="w-full"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          readOnly: true,
                          tabIndex: -1,
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid style={{ width: "30%" }}>
                      <StyledTextField
                        autoComplete="status"
                        name="status"
                        id="status"
                        label="Status"
                        placeholder="Status"
                        aria-readonly
                        className="w-full"
                        value={status}
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          readOnly: true,
                          tabIndex: -1,
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          },
                          endAdornment: (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                              >
                                <circle
                                  cx="6.5"
                                  cy="6.34375"
                                  r="6"
                                  fill={fill}
                                />
                              </svg>
                            </span>
                          ),
                        }}
                      />
                    </Grid>

                    {ViewUserForm.map((item: any) => (
                      <Grid key={item.id} style={{ width: "30%" }}>
                        <StyledTextField
                          autoComplete={item.autoComplete}
                          name={item.name}
                          id={item.id}
                          label={item.label}
                          placeholder={item.placeholder}
                          aria-readonly
                          className="w-full"
                          value={userData[String(item.name)]}
                          InputLabelProps={{
                            shrink: true,
                            sx: labelStyle,
                          }}
                          InputProps={{
                            readOnly: true,
                            sx: {
                              ...inputStyle,
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                WebkitTextFillColor: "inherit",
                              },
                            },
                          }}
                          tabIndex={-1}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[30px] mb-[41px]">
                    Accesses given to user
                  </div>

                  {isLoading && userAccessList?.length === 0 && (
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  )}

                  {!isLoading && userAccessList?.length === 0 && (
                    <p className="flex text-black font-[Poppins] items-center justify-center text-base">
                      Accesses Not Found!
                    </p>
                  )}

                  <RenderResources
                    renderData={userAccessList}
                    isChecked={true}
                    cssClass="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0"
                    resourceList={[]}
                    handleResourceListChildren={dummyChildrenFunction}
                    handleResourceListParent={dummyParentFunction}
                  />
                </Box>
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUser;
