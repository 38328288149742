import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { ExitProcedure } from "./ExitProcedure";
import EmployeeList from "./EmployeeList";
import "./employee.css";
import plus from "../../assets/bulk_upload.svg";
import maskPlus from "../../image/Mask group.svg";

export const Employee = () => {
  const history = useNavigate();
  const [value, setValue] = React.useState<string>(
    localStorage.getItem("tabValue") || "1"
  );
  const [store, setStore] = useState("");
  const AddEmployee = () => {
    history("/add-employee");
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    localStorage.setItem("tabValue", newValue);
    if (newValue === "1") {
      history("/employee-management");
      setStore(newValue);
      // history.push('/employee-list');
    } else if (newValue === "2") {
      history("/exit-procedure");
      setStore(newValue);
    }
  };

  const getIndicatorClass = () => {
    switch (localStorage.getItem("tabValue") || "1") {
      case "1":
        return "indicator-employee-management";
      case "2":
        return "indicator-exit-procedure";
      default:
        return "indicator-default";
    }
  };

  const handlenavigate = () => {
    history("/add-exit");
  };
  return (
    <div className="px-4 pt-6 pb-12 bg-[#F9F9F9] min-h-[90vh]">
      <div className="">
        <Box>
          <TabContext value={value}>
            <div className="flex justify-between">
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{ className: getIndicatorClass() }}
                  sx={{
                    "& .MuiTab-root": {
                      color: "#89888E",
                      fontFamily: "poppins",
                    },
                    "& .Mui-selected": {
                      color: "#000 !important",
                      fontFamily: "poppins",
                    },
                  }}
                >
                  <Tab
                    className="text-[#89888E]"
                    label="Employee List"
                    value="1"
                  />
                  <Tab label="Exit Procedure" value="2" />
                </TabList>
              </Box>

              {window.location.pathname === "/employee-management" ? (
                <div className="flex space-x-4">
                  <div>
                    <button className="flex items-center font-[600] text-[14px] font-[Poppins] py-2 px-4 buttoncss text-white rounded-lg">
                      <img className="w-4 h-4 mr-2" src={plus} alt="" />
                      Bulk Upload
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={AddEmployee}
                      className="flex items-center py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Employee
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    onClick={handlenavigate}
                    className="flex items-center py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
                  >
                    <img
                      className="w-5 h-5 mr-2"
                      src={maskPlus}
                      alt="plus icon"
                    />
                    Initiate Exit procedure
                  </button>
                </div>
              )}
            </div>
            <TabPanel className="tabpanel" value="1">
              <EmployeeList />
            </TabPanel>
            <TabPanel className="tabpanel" value="2">
              <ExitProcedure />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
