import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback, ChangeEvent } from "react";

import DatePicker from "react-datepicker";
import search from "../../assets/Search.svg";
import TablePaginationDemo from "../common/Pagenation";
import styled, { createGlobalStyle } from "styled-components";

import date from "../../assets/date.svg";
import ascending from "../../assets/ascending.svg";
import descending from "../../assets/descending.svg";
import "react-datepicker/dist/react-datepicker.css";
import edit_new from "../../assets/images/edit_new.png";
import right from "../../image/chevron-right.png";

import {
  Edit_Department_Detail,
  Edit_Process_Detail,
  Fetch_Parent_List,
  GroupViewDetails,
  Patch_Edit_Group_Detail,
} from "../../redux/actions/CompanyStructure/companyStructureActions";

import { debounce } from "lodash";
import toast from "react-hot-toast";
import { inputStyle, labelStyle, menuStyle } from "../../utils/helperData";
import {
  COMPANY_STRUCTURE,
  EDIT_GROUP,
  USER_MANAGEMENT,
} from "../../utils/routeConstant";
//import { Label } from "../../@/components/ui/Common/Elements/Label/Label";

import {
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { fetchUserManagementList } from "../../redux/actions/UserManagement/UserManagementActions";
import { userManagementActions } from "../../redux/reducers/userManagement/userManagementSlice";

const crmType = "Group Detail";

const addType = "Edit";
const tableHeadings = [
  { id: "first_name", label: "First Name", width: "w-[9%]" },
  { id: "last_name", label: "Last Name", width: "w-[9%]" },
  { id: "email", label: "Email", sort: false, width: "w-[9%]" },
  {
    id: "contact_number",
    label: "Contact Number",
    sort: false,
    width: "w-[10%]",
  },
  { id: "createdAt", label: "Added Date", sort: true, width: "w-[10%]" },
  { id: "updatedAt", label: "Last Updated", sort: true, width: "w-[9%]" },
  { id: "groups", label: "Groups", sort: true, width: "w-[9%]" },
  { id: "role", label: "Role", sort: false, width: "w-[9%]" },
  { id: "action", label: "Action", sort: false, width: "w-[7%]" },
];

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    width: "85%",
    height: "100%",
  },
});
// interface Group {
//   id: number;
//   name: string;
//   parent_id: number | null;
//   children?: Group[];
// }

// interface ParentList {
//   id: number;
//   name: string;
//   parent_id: number | null
// }

interface Group {
  id: number;
  name: string;
  parent_id: number | null;
  children?: Group[];
}

interface ParentList {
  id: number;
  name: string;
  parent_id: number | null;
}

// const flattenGroupData = (groupData: Group[]): any => {
//   const flattenedRecords: Group[] = [];

//   function flattenGroup(group: Group[]) {
//     group.forEach(item => {
//       const record = {
//         id: item.id,
//         name: item.name,
//         parent_id: item.parent_id
//       };
//       flattenedRecords.push(record);
//       if (item.children && item.children.length > 0) {
//         flattenGroup(item.children);
//       }
//     });
//   }
//   flattenGroup(groupData);
//   return flattenedRecords;
// }

// const flattenGroupData = (groupData: Group[]): ParentList[] => {
//   const flattenedRecords: ParentList[] = [];

//   const flattenGroup = (group: Group[]) => {
//     group.forEach((item) => {
//       const record: ParentList = {
//         id: item.id,
//         name: item.name,
//         parent_id: item.parent_id,
//       };
//       flattenedRecords.push(record);
//       if (item.children && item.children.length > 0) {
//         flattenGroup(item.children);
//       }
//     });
//   };

//   flattenGroup(groupData);
//   return flattenedRecords;
// };

const ViewGroupDetails = () => {
  //! USE SELECTOR

  const count = useSelector(
    (state: any) => state.CompanyStructure.companyStructureData
  );
  const companyStructureData = useSelector(
    (state: any) => state.CompanyStructure.companyStructureData
  );

  console.log("companyStructureData", companyStructureData);

  //! VARIABLES
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedNodeData = location.state;
  console.log("selectedNodeData", selectedNodeData);
  const counting = count.count;
  let itemId = "";
  let customer_id = 0;
  let access_token = "";

  //! HELPER FUNCTIONS

  const dateFormatter = (isoTimestamp: string) => {
    const dateObject = new Date(isoTimestamp);
    const formattedDate = dateObject.toISOString().substring(0, 10);

    return formattedDate;
  };

  const navigateToEditGroup = (id: any) => {
    navigate(`${USER_MANAGEMENT}`, {
      state: {
        id: id,
        currentPage: "editUser",
        previous: location.pathname,
      },
    });
  };

  //! Redux states
  const tableData = useSelector(
    (state: any) => state.userManagement.userManagementData.rows
  );
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  //! STATES
  const [loading, setLoading] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [id, setId] = useState(selectedNodeData.id);
  const [isEditMode, setIsEditMode] = useState(false);
  const [type, setType] = useState(selectedNodeData?.type);
  const [currentLabel, setCurrentLabel] = useState({
    id: selectedNodeData?.user_data?.id ?? 0,
    name:
      selectedNodeData?.user_data?.firstName ||
      selectedNodeData?.user_data?.lastName
        ? `${selectedNodeData?.user_data?.firstName} ${selectedNodeData?.user_data?.lastName}`
        : "",
  });
  const [groupName, setGroupName] = useState(selectedNodeData.group_name);
  const [parentId, setparentId] = useState(
    selectedNodeData?.type === "Group" ||
      selectedNodeData?.type === "Department" ||
      selectedNodeData?.type === "Processes"
      ? selectedNodeData?.group_id || selectedNodeData?.parent_id
      : selectedNodeData?.group_id || selectedNodeData?.parent_id
  );
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState<any>({
    key: "createdAt",
    order: "DESC",
  });
  const [isInitial, setIsInitial] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [toggleEdit, setToggleEdit] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [parentList, setParentList] = useState<ParentList[]>([]);

  //! CONDITIONS

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  //! HANDLER FUNCTIONS

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  function handleGroupChange(value: string) {
    setGroupName(value);
  }

  function handleParentChange(value: string) {
    setShowSearch(true);
    // setParentName(value);
  }

  const handleBack = () => {
    navigate(`${COMPANY_STRUCTURE}`, {
      state: {
        currentPage: "table",
      },
    });
  };

  const handleParentSelectName = (name: string, id: number) => {
    // setParentName(name);
    setparentId(id);
    setId(id);
    setType(name);
    setShowSearch(false);
  };

  function handleSortType(item: any) {
    setLoading(true);
    itemId = item.id;
    if (item.sort) {
      if (sortType && sortType.key === item.id) {
        const newOrder = sortType.order === "ASC" ? "DESC" : "ASC";
        setSortType({ key: item.id, order: newOrder });
      } else {
        setSortType({ key: item.id, order: "DESC" });
      }
    }
    setLoading(false);
  }

  const handleSubmit = async () => {
    if (!groupName.trim()) {
      return;
    }
    if (selectedNodeData?.type === "Processes") {
      console.log("first");
      const response = await Edit_Process_Detail(
        groupName,
        id,
        currentLabel.id
      );
      console.log("response", response);
      if (response?.status === 200) {
        dispatch<any>(
          GroupViewDetails(
            customer_id,
            page,
            numOfItems,
            sortType,
            id,
            type,
            searchTerm,
            startDate,
            endDate
          )
        );
        // setIsEditMode(false);
        navigate(COMPANY_STRUCTURE);
      }
    }
    if (!parentId) {
      return;
    }

    if (selectedNodeData?.type === "Group") {
      const response = await Patch_Edit_Group_Detail(
        groupName,
        id,
        currentLabel.id
        // parentId
      );
      if (response?.status === 200) {
        dispatch<any>(
          GroupViewDetails(
            customer_id,
            page,
            numOfItems,
            sortType,
            id,
            type,
            searchTerm,
            startDate,
            endDate
          )
        );
        // setIsEditMode(false);
        navigate(COMPANY_STRUCTURE);
      }
    } else if (selectedNodeData?.type === "Department") {
      const response = await Edit_Department_Detail(
        groupName,
        id,
        currentLabel.id
      );
      if (response?.status === 200) {
        dispatch<any>(
          GroupViewDetails(
            customer_id,
            page,
            numOfItems,
            sortType,
            id,
            type,
            searchTerm,
            startDate,
            endDate
          )
        );
        // setIsEditMode(false);
        navigate(COMPANY_STRUCTURE);
      }
    } else {
      return null;
    }
  };

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000),
    [setSearchTerm]
  );

  const handleGroupEdit = () => {
    setIsEditMode(!isEditMode);
    setToggleEdit(!toggleEdit);
  };

  const handleSpocChange = (event: SelectChangeEvent<unknown>) => {
    const currentSpoc = tableData.find(
      (item: any) => item.id == event.target.value
    );
    setCurrentLabel(currentSpoc);
  };

  useEffect(() => {
    const fetchData = async () => {
      let url = `/api/v1/user/list?customer_id=${customer_id}&page=${1}&size=${10000}`;

      try {
        const resData = await dispatch<any>(fetchUserManagementList(url));

        const userManagementListData = resData.result;

        dispatch(
          userManagementActions.setUserManagementData(
            userManagementListData || { count: 0, rows: [] }
          )
        );
      } catch (error) {
        if (error instanceof Error) {
        } else {
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    dispatch<any>(
      GroupViewDetails(
        customer_id,
        page,
        numOfItems,
        sortType,
        id,
        type,
        searchTerm,
        startDate,
        endDate
      )
    );
  }, [
    customer_id,
    page,
    type,
    numOfItems,
    sortType,
    parentId,
    searchTerm,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (parentList.length && isInitial) {
      const tempParentName = parentList.find(
        (item: any) => item.id === parentId
      );
      // const tempParentName = parentList.find((item: any) => item.id === selectedParentId);
      if (tempParentName) {
        // setParentName(tempParentName?.name);
      } else {
        // setParentName("NA");
      }
      setIsInitial(false);
    }
  }, [parentList, parentId]);

  const handleGoBack = () => {
    if(isEditMode){
      setIsEditMode(!isEditMode)
    }else{
      navigate("/company-structure")
    }
  }

  return (
    <>
      {/* <div className="flex flex-col min-h-screen bg-white">
      <div className="flex-grow">
        Your main content goes here
        <div className=""> */}
      <div className="px-5">
        <div className="bg-white px-5 pt-5 h-full rounded-md">
          <div className="flex flex-row items-center h-12 justify-between w-full">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={handleGoBack}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                {
                  isEditMode ? "Edit Group Details" : "Group Details"
                }
              </span>
            </div>
            {/* <div className="flex flex-row gap-[12px] justify-center items-center">
                <button
                  onClick={isEditMode ? () => setIsEditMode(false) : handleBack}
                >
                  <img
                    src={rightArrow}
                    alt="right arrow sign"
                    className="h-6 w-6 shrink-0 bg-black rounded-full"
                  />
                </button>
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  {crmType}
                </span>
              </div> */}
            {isEditMode ? (
              <div className="flex flex-start gap-5">
                <button
                  className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-custom-primary h-12"
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  <div className="text-custom-primary text-center font-[Poppins] text-xs font-medium leading-[19.5px] capitalize">
                    Cancel
                  </div>
                </button>
                <button
                  type="submit"
                  form="form"
                  onClick={handleSubmit}
                  className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg bg-custom-primary h-12"
                >
                  <div className="text-white text-center font-[Poppins] text-xs font-medium leading-[19.5px] capitalize">
                    Save Changes
                  </div>
                </button>
              </div>
            ) : (
              <button
                className="flex w-[100px] py-[11px] px-[15px] justify-center items-center gap-2.5 rounded-lg bg-custom-primary h-12"
                onClick={handleGroupEdit}
              >
                <div className="flex justify-center items-center gap-2">
                  <div className="flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.2573 5.07168C15.7234 4.60559 16.3555 4.34375 17.0147 4.34375C17.3411 4.34375 17.6643 4.40803 17.9658 4.53293C18.2673 4.65783 18.5413 4.8409 18.7721 5.07168C19.0028 5.30247 19.1859 5.57645 19.3108 5.87798C19.4358 6.17952 19.5 6.5027 19.5 6.82907C19.5 7.15545 19.4358 7.47863 19.3108 7.78017C19.1859 8.0817 19.0028 8.35568 18.7721 8.58647L17.8526 9.50597C17.5272 9.83141 16.9995 9.83141 16.6741 9.50597L14.3378 7.1697C14.0123 6.84427 14.0123 6.31663 14.3378 5.99119L15.2573 5.07168ZM13.1593 8.34822C12.8338 8.02277 12.3062 8.02277 11.9808 8.34822L6.17571 14.1533C5.64171 14.6873 5.26289 15.3563 5.07973 16.089L4.5249 18.3083C4.45391 18.5923 4.53711 18.8927 4.7441 19.0997C4.95108 19.3067 5.25149 19.3898 5.53546 19.3188L7.75478 18.764C8.48741 18.5809 9.1565 18.2021 9.69049 17.6681L15.4955 11.863C15.821 11.5376 15.821 11.0099 15.4955 10.6845L13.1593 8.34822Z"
                        fill="white"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 18.5052C12 18.045 12.3731 17.6719 12.8333 17.6719H18.6667C19.1269 17.6719 19.5 18.045 19.5 18.5052C19.5 18.9655 19.1269 19.3385 18.6667 19.3385H12.8333C12.3731 19.3385 12 18.9655 12 18.5052Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <span className="text-white font-[Poppins] text-sm font-medium leading-[29px]">
                    {addType}
                  </span>
                </div>
              </button>
            )}
          </div>

          <section
            style={{
              backgroundColor: "#F6F6F6",
              marginTop: "10px",
              borderRadius: "5px",
            }}
          >
            {!isEditMode ? (
              <div className="bg-gray-100 p-2 rounded-lg">
                <div className="container mx-auto">
                  <div className="flex flex-wrap -mx-4">
                    <div className="w-full md:w-[13%] mb-4 md:mb-0 lg:text-[18px] text-[13px]">
                      <div className="font-bold mb-1">Name</div>
                      <div className="font-bold mb-1">Parent</div>
                      <div className="font-bold mb-1">No. Of Users</div>
                    </div>
                    <div className="w-full md:w-[37%] mb-4 md:mb-0 lg:text-[18px] text-[13px]">
                      <div className="mb-1">
                        :{" "}
                        {companyStructureData?.groups?.name?.length > 16
                          ? `${companyStructureData?.groups?.name?.substring(
                              0,
                              16
                            )}...`
                          : companyStructureData?.groups?.name}
                        {/* : {selectedNodeData.group_name.length > 16 ? `${selectedNodeData.group_name.substring(0, 16)}...` : selectedNodeData.group_name} */}
                      </div>
                      <div className="mb-1">
                        {/* : {selectedNodeData.parent_id || selectedNodeData.group_id} */}
                        :{" "}
                        {companyStructureData?.parents?.name?.length > 16
                          ? `${companyStructureData?.parents?.name?.substring(
                              0,
                              16
                            )}...`
                          : companyStructureData?.parents?.name || "-"}
                        {/* : {parentName.length > 16 ? `${parentName.substring(0, 16)}...` : parentName} */}
                      </div>
                      <div className="mb-1">
                        : {companyStructureData?.count}
                      </div>
                    </div>
                    <div className="w-full md:w-[20%] mb-4 md:mb-0 lg:text-[18px] text-[13px]">
                      <div className="font-bold mb-1">Created on</div>
                      <div className="font-bold mb-1">Updated on</div>
                    </div>
                    <div className="w-full md:w-[30%] lg:text-[18px] text-[13px]">
                      <div className="mb-1">
                        :{" "}
                        {selectedNodeData.createdAt
                          ? dateFormatter(selectedNodeData.createdAt)
                          : "-"}
                      </div>
                      <div className="mb-1">
                        :{" "}
                        {selectedNodeData.updatedAt
                          ? dateFormatter(selectedNodeData.updatedAt)
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="group-detail bg-white  mt-8">
                <div className="container ml-0 pl-0">
                  <div className="flex flex-row flex-wrap justify-between gap-y-8">
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        value={groupName}
                        label="Group name"
                        name="Group_name"
                        variant="outlined"
                        placeholder="Enter Group Name"
                        onChange={(e) => handleGroupChange(e.target.value)}
                        sx={{ width: "35ch" }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            alignItems: "center",
                            flexDirection: "row",
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextField
                        id="outlined-basic"
                        label="Parent name"
                        name="Parent_name"
                        disabled
                        // value={parentName}
                        value={companyStructureData?.parents?.name}
                        onChange={(e) => handleParentChange(e.target.value)}
                        variant="outlined"
                        placeholder="Enter Parent Name"
                        sx={{ width: "35ch" }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            alignItems: "center",
                            flexDirection: "row",
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                      {/* Display search results */}
                      {parentList && showSearch && (
                        <div className="w-[310px] bg-white z-10 absolute shadow-md border rounded-lg mt-1 max-h-[100px] overflow-y-auto ml-4">
                          {parentList.map(
                            (item: { name: string; id: number }) => (
                              <div
                                className="p-2.5 hover:bg-[#efefef] text-xs font-medium font-['Poppins'] leading-[14px]"
                                key={item.id}
                                onClick={() =>
                                  handleParentSelectName(item.name, item.id)
                                }
                              >
                                {item.name}
                              </div>
                            )
                          )}
                          {parentList.length === 0 && (
                            <div className="p-2.5 text-xs font-medium font-['Poppins'] leading-[14px]">
                              {/* {isValid["policy_author"] === true}{" "} */}
                              {/* Set the variable outside JSX */}
                              Parent does not exist
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <FormControl fullWidth>
                        <InputLabel
                          id="select-spoc-label"
                          // shrink
                          // sx={labelStyle}
                        >
                          SPOC
                        </InputLabel>
                        <StyledSelect
                          labelId="select-spoc-label"
                          id="outlined-basic"
                          name="selectSPOC"
                          label="Select SPOC"
                          variant="outlined"
                          placeholder="Select"
                          sx={{
                            ...inputStyle,
                            width: "35ch",
                            alignItems: "center",
                            flexDirection: "row",
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          }}
                          value={currentLabel ? currentLabel.id : "0"}
                          onChange={(e) => handleSpocChange(e)}
                          // helperText={isValid[`role`] ? "This field is required!" : ""}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="SPOC"
                            />
                          }
                          MenuProps={{
                            PaperProps: {
                              style: menuStyle,
                            },
                          }}
                        >
                          <MenuItem disabled value="0" style={menuStyle}>
                            Select
                          </MenuItem>
                          {tableData.map((user: any) => (
                            <MenuItem value={user.id} style={menuStyle}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
          <div className="w-full mt-[17px]">
            <div className="pt-5 pb-5 flex flex-col items-flex-end gap-[17px] self-stretch rounded-md ">
              <div className="flex flex-start self-stretch justify-between w-full h-11 pb-12">
                <div className="container ml-0 pl-0 flex justify-between w-full h-11">
                  <div className="h-full w-[35ch] flex pr-2 pl-[11px] justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA]">
                    <input
                      type="text"
                      placeholder="Search"
                      className=" text-[#B5B5C3] font-[Poppins] text-[13px] font-light leading-normal bg-transparent outline-none"
                      onChange={updateSearchTerm}
                    />
                    <img
                      src={search}
                      alt="search sign"
                      className="h-[19px] w-[19px] shrink-0"
                    />
                  </div>
                  <div className="flex w-[314.08px] h-full p-[3px] justify-between text-[#B5B5C3] font-[Poppins] font-light items-center rounded-md border-[1px] border-solid border-[#CACACA] text-[13px]">
                    <GlobalStyle />
                    <DatePicker
                      selected={startDate}
                      onChange={(dates: any) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);
                      }}
                      className="w-full flex py-2.5 h-[38px] justify-between items-center flex-[1,0,0] bg-transparent border-transparent"
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      customInput={<input />}
                      isClearable
                      dateFormat="MMMM d, yyyy"
                      placeholderText="DD/MM/YYYY - DD/MM/YYYY"
                    />
                    {<img src={date} alt="date icon" className="w-6 h-6"></img>}
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-row w-full flex-wrap justify-between">
                <div className="text-gray-700 text-base font-medium font-['Poppins'] leading-none justify-start">
                  User List
                </div>
                <div className="flex flex-row gap-[31px]">
                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#00CBA0"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Active users
                    </span>
                  </div>

                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#FF9950"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Inactive users
                    </span>
                  </div>

                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#FA1464"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Archived users
                    </span>
                  </div>
                </div>
              </div> */}
            </div>

            <div
              className="px-0 py-0 mt-0 w-full overflow-x-auto table_main_content"
              style={{
                height: `calc(100vh - ${!isEditMode ? "34rem" : "32rem"})`,
              }}
            >
              <table className="mt-0 w-full h-full min-w-[910px] text-left table-auto">
                <thead className="sticky top-0 mt-[-1px]">
                  <tr className="flex flex-row w-full items-center pl-6 pr-2.5 py-2.5 rounded-[3px] gap-8 h-11">
                    {tableHeadings.map((item) => {
                      const cssClass =
                        "flex cursor-pointer transition-colors items-center " +
                        item.width;
                      return (
                        <th
                          className={cssClass}
                          onClick={() => handleSortType(item)}
                        >
                          <span className="shrink-0 text-[#3F4254] font-[Poppins] text-xs font-medium leading-[11.185px] tracking-[0.72px] capitialize">
                            {item.label}
                          </span>
                          {item.sort &&
                            (sortType.key === item.id &&
                            sortType.order === "DESC" ? (
                              <img
                                src={descending}
                                alt="Descending order symbol"
                                className="w-[13px] h-[13px]"
                              />
                            ) : (
                              <img
                                src={ascending}
                                alt="Ascending order symbol"
                                className="w-[13px] h-[13px]"
                              />
                            ))}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {companyStructureData?.rows?.map((user: any) => (
                    <tr
                      key={user.id}
                      className="w-full flex px-2.5 py-1 items-center gap-8 border-[0.6px] border-solid border-[#E0E3E7] h-8"
                    >
                      <td className="w-[9%]">
                        <div className="flex flex-row items-center gap-1 shrink-0 ]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                          >
                            <circle cx="5.5" cy="5.35156" r="5" />
                          </svg>
                          <span className="text-[#262626] shrink-0 font-[Poppins] text-[11px] leading-[13.981px] font-medium">
                            {/* {user.firstName} */}
                            {user.firstName.length > 10
                              ? `${user.firstName.substring(0, 10)}...`
                              : user.firstName}
                          </span>
                        </div>
                      </td>
                      <td className="w-[9%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className="ml-3 text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {/* {user.lastName} */}
                              {user.lastName.length > 10
                                ? `${user.lastName.substring(0, 10)}...`
                                : user.lastName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[9%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className="ml-3 text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {/* {user.email} */}
                              {user.email.length > 14
                                ? `${user.email.substring(0, 14)}...`
                                : user.email}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[12%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className=" text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {user.country_code}
                              {/* {user.phone} */}
                              {user.phone.length > 10
                                ? `${user.phone.substring(0, 10)}...`
                                : user.phone}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[10%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className=" text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {user.createdAt
                                ? dateFormatter(user.createdAt)
                                : ""}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[11%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className=" text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {user.updatedAt
                                ? dateFormatter(user.updatedAt)
                                : ""}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[10%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className=" text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {/* {user.Customer.name} */}
                              {user.Customer.name.length > 10
                                ? `${user.Customer.name.substring(0, 10)}...`
                                : user.Customer.name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[9%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className="ml-1 text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {/* {user.Role.role_name} */}
                              {user.Role.role_name.length > 10
                                ? `${user.Role.role_name.substring(0, 10)}...`
                                : user.Role.role_name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[7%]">
                        <button type="button">
                          <div className="icon-container">
                            <img
                              onClick={() => navigateToEditGroup(user.id)}
                              src={edit_new}
                              alt="Table Action Icon"
                              className="icon h-6 w-6"
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              x="0px"
                              y="0px"
                              width="40"
                              height="20"
                              viewBox="0 0 10 30"
                            >
                              <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                            </svg>
                          </div>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-row justify-end items-center">
              {/* <div className="flex flex-row justify-start gap-[31px] pl-2">
                <div className="flex flex-row justify-between gap-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill="#00CBA0"
                    />
                  </svg>
                  <Label className="text-[#262626] text-[10px] leading-[13.981px]">
                    Active users
                  </Label>
                </div>

                <div className="flex flex-row justify-between gap-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill="#FF9950"
                    />
                  </svg>
                  <Label className="text-[#262626] text-[10px] leading-[13.981px]">
                    Inactive users
                  </Label>
                </div>

                <div className="flex flex-row justify-between gap-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill="#FA1464"
                    />
                  </svg>
                  <Label className="text-[#262626] text-[10px] leading-[13.981px]">
                    Archived users
                  </Label>
                </div>
              </div> */}
              <TablePaginationDemo
                count={counting}
                handleItemsChange={handleNumberOfPages}
                handlePageChange={handlePageChange}
                currentPage={1}
                numOfItems={10}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div>
        <Footer />
      </div>
    </div>  */}
    </>
  );
};
export default ViewGroupDetails;
