import {
  REPORTING_MANAGER,
  SELECT_BUSINESS_UNIT,
  SELECT_DEPARTMENT_UNIT,
  SELECT_DESIGNATION,
  SELECT_EMPLOYEE_LIST,
  SELECT_ROLE,
  TEAM_MANAGER,
} from "../api";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import moment from "moment";
import { Dayjs } from "dayjs";

export const Select_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log(
    "Constructed URL:",
    `${SELECT_BUSINESS_UNIT}?customer_id=${customerId}`
  );
  return fetch(`${SELECT_BUSINESS_UNIT}?customer_id=${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Dept_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${SELECT_DEPARTMENT_UNIT}/${customerId}`);
  return fetch(`${SELECT_DEPARTMENT_UNIT}/${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Role_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${SELECT_ROLE}/${customerId}`);
  return fetch(`${SELECT_ROLE}?customer_id=${customerId}&size=100&page=1`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Reporting_Manager = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${TEAM_MANAGER}/${customerId}`);
  return fetch(`${TEAM_MANAGER}?department_id=${customerId}&size=100&page=1`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Designation_Manager = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${SELECT_DESIGNATION}/${customerId}`);
  return fetch(
    `${SELECT_DESIGNATION}?customer_id=${customerId}&size=100&page=1`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Select_Team_Manager = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${REPORTING_MANAGER}/${customerId}`);
  return fetch(`${REPORTING_MANAGER}?customer_id=${customerId}&size=100&page=1`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Management_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  //sortType: any,
  searchTerm: string
  // startDate: Date | null,
  // endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }
  return axios.get(`${SELECT_EMPLOYEE_LIST}${query}&session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Management_List1 = (
  customer_id: number
  // numOfItems: number,
  // page: number,
  // group_id:number,
  // department_id:number,
  // //sortType: any,
  // searchTerm: string
  // startDate: Date | null,
  // endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }
  return axios.get(`${SELECT_EMPLOYEE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_List = (
  customer_id: number,
  numOfItems: number,
  page: number
  //sortType: any,
  // searchTerm: string
  // startDate: Date | null,
  // endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  // if (searchTerm !== "") {
  //   query += `&search=${searchTerm}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }
  return axios.get(`${SELECT_EMPLOYEE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createEmployee = async (
  // designation: string,
  employee_code: string,
  employee_id: string,
  email: string,
  phone: string,
  prefix: string,
  firstName: string,
  middleName: string,
  lastName: string,
  country_code: string,
  group_id: Number,
  customerId: number,
  role_id: Number,
  department_id: Number,
  designation_id: Number,
  team_manager: Number,
  reporting_manager: Number,
  status: string,
  mode_of_employment: string,
  experience: Number,
  date_of_birth: string | null | Dayjs,
  date_of_joining: string | null | Dayjs
  //resourceList: number[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    //name: designation,
    // employee_code: employee_code,
    // employee_id: employee_id,
    email: email,
    phone: phone,
    prefix: prefix,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    country_code: country_code,
    group_id: group_id,
    customer_id: customerId,
    role_id: role_id,
    department_id: department_id,
    designation_id: designation_id,
    team_manager: team_manager,
    reporting_manager: reporting_manager,
    status: status,
    mode_of_employment: mode_of_employment,
    experience: experience,
    date_of_birth: date_of_birth,
    date_of_joining: date_of_joining,
    //resource_list: resourceList,
  };
  if(employee_code !== "") data.employee_code = employee_code
  if(employee_id !== "") data.employee_id = employee_id

  try {
    const response = await axios.post(`${base_api}/api/v1/user`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditEmployee = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/user/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployee = async (
  id: string,
  employee_code: string,
  employee_id: string,
  email: string,
  phone: string,
  prefix: string,
  firstName: string,
  middleName: string,
  lastName: string,
  country_code: string,
  group_id: Number,
  customerId: number,
  role_id: Number,
  department_id: Number,
  designation_id: Number,
  team_manager: Number,
  reporting_manager: Number,
  status: string,
  mode_of_employment: string,
  experience: Number,
  date_of_birth: string | null | Dayjs,
  date_of_joining: string | null | Dayjs,
  date_of_leaving: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const body: any = {
    // employee_code: employee_code,
    // employee_id: employee_id,
    email: email,
    phone: phone,
    prefix: prefix,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    country_code: country_code,
    group_id: group_id,
    customer_id: customerId,
    role_id: role_id,
    department_id: department_id,
    designation_id: designation_id,
    reporting_manager: reporting_manager,
    team_manager: team_manager || 0,
    status: status,
    mode_of_employment: mode_of_employment,
    experience: experience,
    // date_of_birth: date_of_birth,
    date_of_joining: date_of_joining,
  };

  if(date_of_leaving !== "Invalid date") body.date_of_leaving = date_of_leaving;
  if(date_of_birth !== "Invalid date") body.date_of_birth = date_of_birth;
  if(employee_code !== "") body.employee_code = employee_code;
  if(employee_id !== "") body.employee_id = employee_id;
  // if(team_manager !== null) body.team_manager = team_manager;
  
  try {
    const response = await axios.put(`${base_api}/api/v1/user/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
