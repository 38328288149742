import * as React from "react";
import rightArrow from "../../assets/chevron-right.svg";

import Modal from "../UI/Modal";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { MuiTelInput } from "mui-tel-input";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-tailwind/react";
import CreateUser from "./ConfirmationModal/CreateUser";
import SelectGroupModal from "./SelectGroupModal/SelectGroup";
import {
  getUserRoleAccessList,
  sendCreateUserData,
} from "../../redux/actions/UserManagement/UserManagementActions";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  UserManagementForm,
  flattenGroupData,
  labelStyle,
  validateEmail,
  validatePhoneNumber,
  inputStyle,
  menuStyle,
} from "../../utils/helperData";
import { styled } from "@mui/system";
import RenderResources from "../common/ResourceList/Resources";

interface AddUserProps {
  handleCurrentPageChange: (value: string) => void;
}

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "91.5%",
    height: "100%",
  },
});

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    width: "85%",
    height: "100%",
  },
});

const validationData: any = ["firstName", "email", "phone", "role", "group"];

const AddUser: React.FC<AddUserProps> = ({ handleCurrentPageChange }) => {
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const roleData = useSelector(
    (state: any) => state.userManagement.userRoleData.rows
  );
  const groupTreeData = useSelector(
    (state: any) => state.userManagement.userGroupData.rows
  );

  const [phone, setPhone] = React.useState("");
  const [phoneInfo, setPhoneInfo] = React.useState({
    countryCallingCode: "",
    countryCode: "",
    nationalNumber: "",
  });

  const [formData, setFormData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState<Boolean>(false);
  const [groupModalIsOpen, setGroupModalIsOpen] =
    React.useState<Boolean>(false);

  const [group, setGroup] = React.useState<any>(null);
  const [userAccessList, setUserAccessList] = React.useState([]);
  const [role, setRole] = React.useState<any>({ id: "0", role_name: "" });

  const [isValid, setIsValid] = React.useState<{ [key: string]: any }>({});

  function handleValidation(data: any) {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    updated["firstName"] = !data.get("firstName");
    updated["email"] =
      data.get("email") === ""
        ? 2
        : !validateEmail(data.get("email") ?? "")
        ? 1
        : 0;
    updated["phone"] =
      phoneInfo.nationalNumber === ""
        ? 2
        : !validatePhoneNumber(
            phoneInfo.nationalNumber === "" ? phone : phoneInfo.nationalNumber
          )
        ? 1
        : 0;
    updated["role"] = role.id === "0";
    updated["group"] = group ? false : true;

    setIsValid(updated);

    if (
      !data.get("firstName") ||
      !validateEmail(data.get("email") ?? "") ||
      !validatePhoneNumber(
        phoneInfo.nationalNumber === "" ? phone : phoneInfo.nationalNumber
      ) ||
      role.id === "" ||
      (group ? false : true)
    ) {
      return;
    }

    handleOpen();
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    if (item === "email") {
      updated[`${item}`] = 0;
    } else if (item === "phone") {
      updated[`${item}`] = 0;
    } else {
      updated[`${item}`] = false;
    }

    setIsValid(updated);
  }

  const handleChange = (newValue: any, info: any) => {
    setPhone(newValue);
    setPhoneInfo({
      countryCallingCode: info.countryCallingCode,
      countryCode: info.countryCode,
      nationalNumber: info.nationalNumber,
    });

    handleIsValidItem("phone");
  };

  const groupData = flattenGroupData(groupTreeData);

  const handleRoleChange = (event: SelectChangeEvent<unknown>) => {
    setUserAccessList([]);

    const currentRole = roleData.find(
      (item: any) => item.id === event.target.value
    );
    setRole(currentRole);

    handleIsValidItem("role");
  };

  const handleGroupChange = (value: number) => {
    setGroup(groupData.find((item: any) => item.id === value));
    setGroupModalIsOpen(false);

    handleIsValidItem("group");
  };

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    handleValidation(data);

    setFormData({
      firstName: data.get("firstName") ?? "",
      lastName: data.get("lastName"),
      email: data.get("email") ?? "",
      phone: phoneInfo.nationalNumber ?? "",
      country_code: phoneInfo ? "+" + phoneInfo.countryCallingCode : "",
      role_id: role.id ?? "",
      group_access: group ? [group.id] : [],
      resource_list: data.getAll("resources").map((item) => Number(item)),
    });
  };

  async function handleSubmitForm() {
    setModalIsOpen(false);

    try {
      toast.loading("Processing in...");
      const resData = await dispatch<any>(
        sendCreateUserData(
          formData.firstName,
          formData.lastName,
          formData.email,
          formData.phone,
          formData.country_code,
          formData.role_id,
          formData.group_access,
          formData.resource_list,
          customer_id
        )
      );
      toast.dismiss();
      toast.success(<b>Process completed successfully!</b>);
      handleCurrentPageChange("table");
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
  }

  function dummyParentFunction(event: any, id: any, parent_id: any, temp: any[]){

  }

  function dummyChildrenFunction(event: any, id: any, parent_id: any){

  }

  function handleOpen() {
    setModalIsOpen(true);
  }

  function handleClose() {
    setModalIsOpen(false);
  }

  function handleGroupModalOpen() {
    setGroupModalIsOpen(true);
  }

  function handleGroupModaClose() {
    setGroupModalIsOpen(false);
  }

  React.useEffect(() => {
    async function fetchResourceList() {
      setIsLoading(true);
      try {
        const data = await getUserRoleAccessList(role.id);
        if (data) {
          // setUserAccessList(
          //   data.result.UserRolePrivileges.map((item: any) => item.Resource)
          // );
          setUserAccessList(data?.result?.resources);
        } else {
          // console.error("Failed to fetch customer role list.");
        }
      } catch (error) {
        // console.error("Error fetching customer role list:", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (role.id != "0") {
      fetchResourceList();
    }
  }, [role]);

  let checkRepeat = new Set();

  return (
    <>
      {modalIsOpen && (
        <Modal
          open={modalIsOpen}
          onClose={handleClose}
          cssClass="w-[23.19%] shrink-0 rounded-2xl bg-white shadow-[10px_20px_30px_0px_rgba(0,0,0,0.10)]"
        >
          <CreateUser
            onClose={handleClose}
            onConfirm={() => handleSubmitForm()}
            modalType="Create"
          />
        </Modal>
      )}

      {groupModalIsOpen && (
        <Modal
          open={groupModalIsOpen}
          onClose={handleGroupModaClose}
          cssClass="w-[23.5%] inline-flex p-5 flex-col items-start gap-2 rounded-[3px]"
        >
          <SelectGroupModal
            onClose={handleGroupModaClose}
            selectedGroupId={group ? group.id : null}
            handleGroupChange={handleGroupChange}
          />
        </Modal>
      )}

      <div className="bg-[#FBFBFB] py-10 px-[18.5px] h-full">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[26px] justify-center items-center">
            <button onClick={() => handleCurrentPageChange("table")}>
              <img
                src={rightArrow}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Add User
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              type="submit"
              form="createUserForm"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg bg-custom-primary h-12"
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Save Changes
              </div>
            </button>
            <button
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12"
              onClick={() => handleCurrentPageChange("table")}
            >
              <div className="text-[#2C2C2C] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
          </div>
        </div>

        <div className="w-full mt-5">
          <div className="pt-[25px] pb-[50px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] bg-white">
            <Container component="main">
              <Box>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ mt: "0px" }}
                  id="createUserForm"
                >
                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                    User Details
                  </div>

                  <Grid
                    container
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "self-start",
                      alignSelf: "stretch",
                    }}
                  >
                    {UserManagementForm.map((item) => (
                      <Grid item style={{ width: "30%" }}>
                        <StyledTextField
                          // autoComplete={item.autoComplete}
                          name={item.name}
                          // required={item.required}
                          id={item.id}
                          label={item.label}
                          placeholder={item.placeholder}
                          InputLabelProps={{
                            shrink: true,
                            sx: labelStyle,
                          }}
                          className="w-full"
                          error={isValid[`${item.name}`]}
                          helperText={
                            item.name === "email"
                              ? isValid[`${item.name}`] === 2
                                ? "This field is required!"
                                : isValid[`${item.name}`] === 1
                                ? "Enter Valid Email!"
                                : ""
                              : isValid[`${item.name}`]
                              ? "This field is required!"
                              : ""
                          }
                          onChange={() => {
                            handleIsValidItem(item.name);
                          }}
                          InputProps={{
                            sx: {
                              ...inputStyle,
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                WebkitTextFillColor: "inherit",
                              },
                            },
                          }}
                        />
                      </Grid>
                    ))}

                    <MuiTelInput
                      label="Phone"
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone Number"
                      defaultCountry="IN"
                      focusOnSelectCountry
                      className="w-[30%]"
                      // required
                      error={isValid[`phone`]}
                      helperText={
                        isValid[`phone`] === 2
                          ? "This field is required!"
                          : isValid[`phone`] === 1
                          ? "Length must be of 8-16 characters long!"
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={phone}
                      onChange={handleChange}
                    />
                    <FormControl style={{ width: "30%" }}>
                      <InputLabel
                        id="select-label"
                        shrink
                        sx={{
                          ...labelStyle,
                          color: isValid[`role`]
                            ? "#d32f2f"
                            : "rgba(0, 0, 0, 0.60)",
                        }}
                      >
                        Select Role *
                      </InputLabel>
                      <StyledSelect
                        labelId="demo-simple-select-label"
                        id="selectRole"
                        error={isValid[`role`]}
                        sx={{
                          ...inputStyle,
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        }}
                        value={role ? role.id : "0"}
                        onChange={(e) => handleRoleChange(e)}
                        required
                        // helperText={isValid[`role`] ? "This field is required!" : ""}
                        displayEmpty
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Select Role"
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: menuStyle,
                          },
                        }}
                      >
                        <MenuItem disabled value="0" style={menuStyle}>
                          Select
                        </MenuItem>
                        {roleData.map((role: any) => (
                          <MenuItem value={role.id} style={menuStyle}>
                            {role.role_name}
                          </MenuItem>
                        ))}
                      </StyledSelect>

                      {isValid[`role`] && (
                        <FormHelperText error>
                          This field is required!
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Grid
                      onClick={handleGroupModalOpen}
                      style={{ width: "30%" }}
                    >
                      <StyledTextField
                        autoComplete="group"
                        name="group-name"
                        // required
                        className="w-full"
                        id="groupName"
                        label="Group Assigned"
                        placeholder="Select"
                        error={isValid[`group`]}
                        helperText={
                          isValid[`group`] ? "This field is required!" : ""
                        }
                        value={group ? String(group.group_name) : ""}
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[30px] mb-[41px]">
                    Accesses given to user
                  </div>

                  {isLoading && userAccessList?.length === 0 && (
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  )}

                  <RenderResources
                    renderData={userAccessList}
                    isChecked={true}
                    cssClass="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0"
                    resourceList={[]}
                    handleResourceListChildren={dummyChildrenFunction}
                    handleResourceListParent={dummyParentFunction}
                  />
                </Box>
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
