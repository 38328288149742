import React, { useCallback, useEffect, useState } from "react";
import download from "../../image/Vector (1).png";
import { createGlobalStyle } from "styled-components";
import eye from "../../assets/Crud_Icons/view.svg";
import filter from "../../assets/filter.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  Fetch_Employee_Expense_List,
  Fetch_Employee_Expense_List1,
} from "../common/services/EmployeeExpense";
import { useSelector } from "react-redux";
import del from "../../assets/Crud_Icons/delete.svg";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import { debounce } from "lodash";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { useDispatch } from "react-redux";
import TablePaginationDemo from "../common/Pagenation";
import Search from "../SearchBar/Search";
import { Chip } from "@material-ui/core";
import Filter from "../Filter/Filter";
import moment from "moment";

interface Leave_Type {
  id: string;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  ExpenseCategory: {
    expense_category_name: string;
  };
  expense_date: string;
  expense_amount: string;
  status: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Employee Expense
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={onDelete}
            className="buttoncss text-white px-12 py-2 rounded mr-2"
          >
            Yes Sure
          </button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded px-12 py-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const Project = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectLeaveType, setSelectLeaveType] = useState<Leave_Type[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Leave_Type | null>(null);
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [dateRange, setDateRange] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const dispatch = useDispatch();

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedBusinessUnit(option);
    console.log("Selected Business Unit:", option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedDepartmentUnit(option);
    console.log("Selected Business Unit:", option);
  };

  useEffect(() => {
    console.log("chek print");

    const fetchHistory = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );
        console.log(historyData, "historyData---->>>");

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    console.log("chek print");

    const fetchDepartment = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );
        console.log(historyData, "historyData department---->>>");

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  const navigate = useNavigate();
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const handleDeleteClick = (item: Leave_Type) => {
    console.log(item, "delete value");
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/expenses/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    console.log("cancel");

    setShowModal(false);
    setSelectedItem(null);
  };

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {

      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Employee_Expense_List(
        customer_id,
        searchTerm,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        startDate,
        endDate
      );

      if (response.status === 200) {
        console.log("Data: Leave Tpye", response.data.result);
        setTableData(response.data?.result?.rows);

        setCount(response.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const fetchLeaveType1 = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Employee_Expense_List1(customer_id);

      if (response.status === 200) {
        console.log("Data: Leave Tpye", response.data.result);

        const fetchedData: EmployeeData[] = response.data?.result?.rows;
        setTableData1(fetchedData);

        const columnMapping: { [key: string]: string } = {
          id: "ID",
          name: "Name",
          designation: "Designation",
          department: "Department",
          employee_code: "Employee Code",
          // Add other mappings here
        };

        const cols: Column[] = Object.keys(fetchedData[0]).map((key) => ({
          id: key,
          displayName:
            columnMapping[key] || key.charAt(0)?.toUpperCase() + key?.slice(1),
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType1();
  }, []);

  useEffect(() => {
    fetchLeaveType();
  }, [
    page,
    searchTerm,
    numOfItems,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startDate,
    endDate,
    startSession,
    endSession,
  ]);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchLeaveType();
  };

  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchLeaveType();
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "employee_expense.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  return (
    <>
      <div className="mt-6 flex w-full justify-between space-x-2">
        <div className="h-[40px] w-full">
          <div className="h-full w-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with Expense Type"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label={`${moment(selectedDates[0]).format(
                      "YYYY-MM-DD"
                    )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button
                  onClick={handleDownload}
                  className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                >
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end mt-6 gap-[31px]">
        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Aproved
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Pending
          </span>
        </div>

        <div className="flex flex-row justify-between gap-1 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
          </svg>
          <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
            Rejected
          </span>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="w-full min-w-max text-left table-auto border-[0.6px] bg-white border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Employee Name & ID
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                Expense Type
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                Date
              </th>
              <th className="pl-6 pr-2.5 py-2. text-center text-[14px] text-[#1D1A22]">
                Amount
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-center text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((items) => (
              <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
                <td className="flex items-center space-x-2 pl-6 pr-2.5 py-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill={
                        items.status === "Approved"
                          ? "#00CBA0"
                          : items.status === "Pending"
                          ? "#FF9950"
                          : items.status === "Rejected"
                          ? "#FA1464"
                          : "#D3D3D3" // default color if status is unknown
                      }
                    />
                  </svg>
                  <img className="w-8 h-8 rounded-full" src={items?.User.profile_image || profile} alt="Profile" />
                  <div className="flex flex-col justify-between">
                    <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                      {items?.User?.firstName} {items?.User?.lastName}
                    </span>
                    <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                      {items?.User?.employee_id || ""}
                    </span>
                  </div>
                </td>
                <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {items?.ExpenseCategory?.expense_category_name}
                </td>
                <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {items?.expense_date}
                </td>
                <td className="text-[#3F4254] text-center  font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {items?.expense_amount}
                </td>
                <td className="pl-6 pr-2.5 py-1">
                  <div className="flex items-center justify-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={eye}
                      onClick={() =>
                        navigate("/view-expense", {
                          state: { id: items.id },
                        })
                      }
                      alt="View"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={edit}
                      onClick={() =>
                        navigate("/edit-expense", {
                          state: { id: items.id },
                        })
                      }
                      alt="Edit"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={del}
                      alt="Edit"
                      onClick={() => handleDeleteClick(items)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {showModal && (
          <DeleteConfirmationModal
            onDelete={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
      <Filter
        isDate
        isOther
        heading="Expense Mangement Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </>
  );
};

export default Project;
