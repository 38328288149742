import rightArrow from "../../assets/chevron-right.svg";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-tailwind/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { MuiTelInput } from "mui-tel-input";
import {
  getCustomerResourseList,
  fetchCustomerDetails,
} from "../../redux/actions/CustomerManagement/CustomerManagementActions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import RenderResources from "../common/ResourceList/Resources";

interface EditCustomerProps {
  handleCurrentPageChange: (value: string, event: any) => void;
  id: number;
}

interface FormData {
  customer_name: string;
  email: string;
  address: string;
  admin_name: string;
  status: string;
  phone: string;
  country_code: string;
  resource_list: any[]; // Adjust the type according to your actual data structure
}

const labelStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
};
const inputStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "10px",
  border: "var(--Our_Colors-Grays-400, #C5CDD4)",
  height: "56px",
  width: "325px",
};
const menuStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
};

const ViewCustomer: React.FC<EditCustomerProps> = ({
  handleCurrentPageChange,
  id,
}) => {
  const dispatch = useDispatch();
  const customerData = useSelector(
    (state: any) => state.CustomerManagement.customerData
  );

  const [customerDetails, setCustomerDetails] = useState(customerData);
  const [customerResourceList, setCustomerResourceList] = useState([]);
  const [resourceList, setResource_list] = useState<any[]>([]);
  const [phone, setPhone] = useState(customerDetails.phone);
  const [data, setData] = useState(customerDetails);
  const [phoneInfo, setPhoneInfo] = useState({
    countryCallingCode: "",
    countryCode: "",
    nationalNumber: customerDetails?.phone,
  });
  // const [fetchedResourceList, setFetchedResourceList] = useState(
  //   new Array(25).fill(false)
  // );
  // const [updatedResourceList, setUpdatedResourceList] =
  //   useState(fetchedResourceList);

  function dummyParentFunction(
    event: any,
    id: any,
    parent_id: any,
    temp: any[]
  ) {}

  function dummyChildrenFunction(event: any, id: any, parent_id: any) {}

  // useEffect(() => {
  //   // Update updatedResourceList after fetchedResourceList has been updated
  //   setUpdatedResourceList(fetchedResourceList);
  // }, [fetchedResourceList]);

  const [formData, setFormData] = useState<FormData>({
    customer_name: "",
    email: "",
    address: "",
    admin_name: "",
    status: "",
    phone: "",
    country_code: "",
    resource_list: [],
  });

  useEffect(() => {
    if (customerDetails?.customer_details?.CustomerResources) {
      // setFetchedResourceList((prevState) => {
      //   const updatedList = [...prevState];
      //   customerDetails.customer_details.CustomerResources.forEach(
      //     (resource: any) => {
      //       updatedList[resource.resource_id] = true;
      //     }
      //   );
      //   return updatedList;
      // });
      setResource_list(
        customerDetails.customer_details.CustomerResources.map(
          (resource: any) => resource.resource_id
        )
      );
    }
  }, [customerDetails?.customer_details?.CustomerResources]);

  useEffect(() => {
    if (customerDetails) {
      let tempdata = {
        customer_name: customerDetails?.customer_details?.name || "",
        email: customerDetails?.email || "",
        address: customerDetails?.customer_details?.address || "",
        admin_name: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
        status: customerDetails.status,
        phone: customerDetails?.phone || "",
        country_code: customerDetails?.country_code || "",
        resource_list: [],
      };
      setFormData(tempdata);
    }
  }, [customerDetails, phoneInfo.nationalNumber, phoneInfo.countryCallingCode]);

  useEffect(() => {
    async function fetchResourceList() {
      try {
        const data = await getCustomerResourseList();
        if (data) {
          setCustomerResourceList(data);
        } else {
          console.error("Failed to fetch customer resource list.");
        }
      } catch (error) {
        console.error("Error fetching customer resource list:", error);
      }
    }

    fetchResourceList();
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      dispatch<any>(fetchCustomerDetails(id));
    }
  }, [id, dispatch]);

  console.log("cus", customerData);

  useEffect(() => {
    setCustomerDetails(customerData);
  }, [customerData]);

  useEffect(() => {
    setData(customerDetails);
  }, [customerDetails]);

  let checkRepeat = new Set();

  return (
    <div className="bg-[#FBFBFB] py-10 px-[18.5px] h-full">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[26px] justify-center items-center">
          <button onClick={(e) => handleCurrentPageChange("table", e)}>
            <img
              src={rightArrow}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            View Customer
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            form="form"
            type="submit"
            className="flex w-[166px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg bg-indigo-600 h-12"
            onClick={(e) => handleCurrentPageChange("edit", e)}
          >
            <div className="w-6 h-6 relative mr-2">
              <div className="w-[15px] h-[15px] left-[3px] top-[3px]">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Frame 1000005410">
                    <g id="Group 1000006565">
                      <path
                        id="Vector"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.2573 4.89981C15.7234 4.43372 16.3555 4.17188 17.0147 4.17188C17.3411 4.17188 17.6643 4.23616 17.9658 4.36106C18.2673 4.48596 18.5413 4.66902 18.7721 4.89981C19.0028 5.13059 19.1859 5.40458 19.3108 5.70611C19.4358 6.00764 19.5 6.33082 19.5 6.6572C19.5 6.98357 19.4358 7.30676 19.3108 7.60829C19.1859 7.90983 19.0028 8.18381 18.7721 8.41459L17.8526 9.33409C17.5272 9.65953 16.9995 9.65953 16.6741 9.3341L14.3378 6.99782C14.0123 6.67239 14.0123 6.14475 14.3378 5.81932L15.2573 4.89981ZM13.1593 8.17634C12.8338 7.8509 12.3062 7.8509 11.9808 8.17634L6.17571 13.9814C5.64171 14.5154 5.26289 15.1845 5.07973 15.9171L4.5249 18.1365C4.45391 18.4204 4.53711 18.7208 4.7441 18.9278C4.95108 19.1348 5.25149 19.218 5.53546 19.147L7.75478 18.5921C8.48741 18.409 9.1565 18.0302 9.69049 17.4962L15.4955 11.6911C15.821 11.3657 15.821 10.838 15.4955 10.5126L13.1593 8.17634Z"
                        fill="white"
                      />
                      <path
                        id="Vector_2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 18.3372C12 17.877 12.3731 17.5039 12.8333 17.5039H18.6667C19.1269 17.5039 19.5 17.877 19.5 18.3372C19.5 18.7975 19.1269 19.1706 18.6667 19.1706H12.8333C12.3731 19.1706 12 18.7975 12 18.3372Z"
                        fill="white"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Edit Customer
            </div>
          </button>
        </div>
      </div>

      <div className="w-full mt-5">
        <div className=" py-[25px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] bg-white">
          <Container component="main">
            <Box>
              <Box
                id="form"
                component="form"
                className="flex flex-col gap-8"
                noValidate
                sx={{ mt: "0px" }}
              >
                <div>
                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                    Customer Details
                  </div>

                  <Grid
                    container
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >
                    <Grid item>
                      <TextField
                        autoComplete="given-name"
                        name="customer_name"
                        value={formData?.customer_name}
                        required
                        id="customer_name"
                        label="Customer Name"
                        placeholder="Enter Customer name"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        autoComplete="email"
                        name="email"
                        value={formData?.email}
                        required
                        id="email"
                        label="E-mail"
                        placeholder="Enter Email"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        autoComplete="address"
                        name="address"
                        value={formData?.address}
                        required
                        id="address"
                        label="Address"
                        placeholder="Enter Address"
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                    Admin Details
                  </div>
                  <Grid
                    container
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >
                    <TextField
                      name="admin_name"
                      required
                      label="Admin name"
                      value={formData.admin_name}
                      placeholder="Enter Admin Name"
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                    />
                    <TextField
                      name="email"
                      required
                      value={formData.email}
                      label="E-mail"
                      placeholder="Enter Customer Email"
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                    />
                    <MuiTelInput
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                      defaultCountry="IN"
                      required
                      InputLabelProps={{
                        shrink: true,
                        sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          color: "#464646",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          letterSpacing: "-0.165px",
                          display: "flex",
                          padding: "0px 12px",
                          flexDirection: "row",
                          alignItems: "center",
                          alignSelf: "stretch",
                          borderRadius: "10px",
                          border: "var(--Our_Colors-Grays-400, #C5CDD4)",
                          height: "56px",
                          width: "325px",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={`${formData.country_code}${formData.phone}`}
                    />
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        shrink
                        sx={labelStyle}
                      >
                        Status
                      </InputLabel>
                      <Select
                        label="Status"
                        name="status"
                        id="demo-simple-select"
                        value={formData?.status}
                        sx={inputStyle}
                        displayEmpty
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Status"
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: menuStyle,
                          },
                        }}
                      >
                        <MenuItem value="active" style={menuStyle}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              alignItems: "center",
                            }}
                          >
                            Active
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="#00CBA0"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" />
                            </svg>
                          </div>
                        </MenuItem>
                        <MenuItem value="inactive" style={menuStyle}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              alignItems: "center",
                            }}
                          >
                            In-active
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="#FF9950"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" />
                            </svg>
                          </div>
                        </MenuItem>
                        <MenuItem value="archived" style={menuStyle}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              alignItems: "center",
                            }}
                          >
                            Archive
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="#FA1464"
                            >
                              <circle cx="5.5" cy="5.35156" r="5" />
                            </svg>
                          </div>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
                <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[10px] mb-[10px]">
                  Accesses given to Customer
                </div>
                {/* <Grid
                  container
                  style={{
                    gap: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    marginBottom: "60px",
                  }}
                >
                  {customerResourceList.map((obj: any) => {
                    if (!checkRepeat.has(obj.resource_id)) {
                      const subResources = customerResourceList
                        .filter((idx: any) => idx.parent_id === obj.resource_id)
                        .map((subObj: any) => {
                          checkRepeat.add(subObj.resource_id);
                          return (
                            <li
                              className="w-[366px] flex flex-row items-start"
                              key={subObj.resource_id}
                            >
                              <Checkbox
                                ripple={false}
                                crossOrigin=""
                                value={subObj.resource_id}
                                checked={updatedResourceList[subObj.resource_id]}
                                id={subObj.resource_id}
                                containerProps={{
                                  className: "p-0",
                                }}
                                className="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                              />
                              <label
                                htmlFor={subObj.resource_id}
                                className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal pl-3 mt-[-3px]"
                              >
                                {subObj.resource_name}
                              </label>
                            </li>
                          );
                        });
                      return (
                        <div
                          key={obj.resource_id}
                          className="gap-7 flex flex-row w-full"
                        >
                          <div className="flex flex-row gap-3 w-[28%] items-center h-3">
                            <Checkbox
                              ripple={false}
                              crossOrigin=""
                              value={obj.resource_id}
                              id={obj.resource_id}
                              checked={updatedResourceList[obj.resource_id]}
                              containerProps={{
                                className: "p-0",
                              }}
                              className="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            />
                            <label
                              htmlFor={obj.resource_name}
                              className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal"
                            >
                              {obj.resource_name}
                            </label>
                          </div>
                          <div className="flex flex-row gap-3 items-start">
                            <ul className="grid grid-cols-2 gap-5">
                              {subResources}
                            </ul>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </Grid> */}

                <RenderResources
                  renderData={customerResourceList}
                  isChecked={false}
                  cssClass="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0"
                  resourceList={resourceList}
                  handleResourceListChildren={dummyChildrenFunction}
                  handleResourceListParent={dummyParentFunction}
                />
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ViewCustomer;
