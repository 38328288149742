import axios from "axios";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import { FETCH_EXIT_EMPLOYEE } from "../api";
import { Dayjs } from "dayjs";

export const Fetch_Exit_Employee_Management_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id:number,
  department_id:number,
  //sortType: any,
  searchTerm: string
  // startDate: Date | null,
  // endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }
  
  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }
  return axios.get(`${FETCH_EXIT_EMPLOYEE}${query}&session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Exit_Employee_Management_List1 = (
  customer_id: number,
  // endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }



  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }
  return axios.get(`${FETCH_EXIT_EMPLOYEE}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createExitEmployee = async (
  //customerId: number,
  user_id: string|null,
  reason: string,
  date_of_exit_initiation: string | null | Dayjs,
  date_of_leaving: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    //customerId: customerId,
    user_id: user_id,
    reason: reason,
    date_of_exit_initiation: date_of_exit_initiation,
    date_of_leaving: date_of_leaving,
  };

  try {
    const response = await axios.post(`${base_api}/api/v1/user-exit`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

// export const approveRejectApi = async (id: string) => {
//   const access_token = localStorage.getItem("access_token");
//   console.log(access_token,"access_token----ap----che-->>>");

//   console.log(id, "id-----api----check");
//   try {
//     const response = await axios.post(`${base_api}/api/v1/user-exit/${id}`, {
//       headers: {
//         "x-api-key": api_key,
//         Authorization: "Bearer " + access_token,
//       },
//     });

//     return response.data;
//   } catch (error: any) {
//     throw error;
//   }
// };

export const approveRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/user-exit/${id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditExitEmployee = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/user-exit/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateExitEmployee = async (
  id: string,
  employee_id: string,
  reason: string,
  date_of_exit_initiation: string | Dayjs | null,
  date_of_leaving: string | Dayjs | null,
  department_id: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    employee_id: employee_id,
    reason: reason,
    date_of_exit_initiation: date_of_exit_initiation,
    date_of_leaving: date_of_leaving,
    department_id: department_id,
  };

  try {
    const response = await axios.put(
      `${base_api}/api/v1/user-exit/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
