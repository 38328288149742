import React from 'react';

interface ReceiptProps {
  fileName: string;
  fileSize: string;
  onView: () => void;
}

const Receipt: React.FC<ReceiptProps> = ({ fileName, fileSize, onView }) => {
  return (
    <div className="flex items-center p-4 border rounded-md">
      <div className="flex-grow">
        <div className="font-semibold font-[Poppins]">{String(fileName)?.split("/")?.pop()}</div>
        <div className="text-sm font-[Poppins] text-gray-500">{fileSize}</div>
      </div>
      <button 
        onClick={onView} 
        className="borderButton font-[Poppins] text-[#000000] px-2 py-1 rounded-md"
      >
        View
      </button>
    </div>
  );
};

export default Receipt;
